import api from "@/assets/js/api.js";

// const { REACT_APP_API, REACT_APP_PRODUCT_API } = process.env;

import {
  REACT_APP_PRODUCT_API,
  REACT_APP_API,
  REACT_APP_ID,
  REACT_APP_KEY,
} from "@/assets/js/stationSetting";

export function apiGetDeFiProductInfo(data = {}) {
  return api({
    url: REACT_APP_API + `/Publics/getDeFiProductInfo`,
    data,
  });
}

//获取所有静态表
export function getStaticTableList(data = {}) {
  return api({
    url: `${REACT_APP_PRODUCT_API}/Settings/getStaticTableInfoList`,
    data,
  });
}
