import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "@/assets/css/reset.scss";
import "@/assets/css/base.scss";
import 'antd-mobile/dist/antd-mobile.css';
import * as serviceWorker from './serviceWorker';
import store from "@/store/index"
// import md5 from "js-md5"
import { Provider } from "react-redux"

// React.Component.prototype.$md5 = md5

ReactDOM.render(
    <Provider store={store}><App /></Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
