import React, { useState, useEffect, useLayoutEffect } from "react";
import intl from "react-intl-universal";
import { apiGetLang } from "@/model/setting/index";
import useInitLang from "./useInitLang";
import { useLocation } from "react-router";
window.intl = intl;
const SUPPOER_LOCALES = [
  {
    name: "简体",
    value: "zh_CN",
  },
  {
    name: "繁體",
    value: "zh_HK",
  },
  {
    name: "English",
    value: "en",
  },
  {
    name: "اللغة العربية",
    value: "ar",
  },
  {
    name: "한국어",
    value: "ko",
  },
  {
    name: "日本語",
    value: "ja",
  },
  {
    name: "Français",
    value: "fr",
  },
  {
    name: "Español",
    value: "es",
  },
  {
    name: "Pусский",
    value: "ru",
  },
  {
    name: "Deutsch",
    value: "de",
  },
  {
    name: "Português",
    value: "pt",
  },
  {
    name: "हिंदी",
    value: "hi",
  },
  {
    name: "Tiếng Việt",
    value: "vi",
  },
  {
    name: "Pilipino",
    value: "tl",
  },
  {
    name: "ภาษาไทย",
    value: "th",
  },
  {
    name: "Türk",
    value: "tr",
  },
  {
    name: "Italiano",
    value: "it",
  },
];

export default function () {
  const [initDone, setInitDone] = useState(false);
  const [winWidth, setWinWidth] = useState(() => {
    return window.$(window).width();
  });
  useEffect(() => {
    window.$(window).resize(function () {
      setWinWidth(window.$(window).width());
    });
    loadLocales();
  }, []);

  function loadLocales() {
    let currentLocale = intl.determineLocale({
      cookieLocaleKey: "lang",
    });
    const isContain = SUPPOER_LOCALES.find(item => {
      if (item.value == currentLocale) {
        return true;
      }
    });
    if (!isContain) {
      currentLocale = "zh_CN";
      //根据浏览器语言设置语言
      let lang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
      lang = lang.substr(0, 2); //截取lang前2位字符
      switch (lang) {
        case "en":
          currentLocale = "en";
          break;
        case "zh":
          currentLocale = "zh_CN";
          break;
        default:
          currentLocale = "en";
          break;
      }
    }
    apiGetLang({ currentLocale })
      .then(rs => {
        intl.init({
          currentLocale,
          locales: {
            [currentLocale]: rs,
          },
        });
      })
      .then(() => {
        setInitDone(true);
        // document.title = intl.get("title");
      });
    window.$("body").addClass(currentLocale);
    window.localStorage.setItem("lang", currentLocale);
  }
  return initDone;
}
