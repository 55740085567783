import React, { useState } from "react";
import Rule from "./rule/rule.jsx";
import Money from "./money/money.jsx";
import "./stock-guide.scss";
const Problem = React.lazy(() => import("./problem/problem.jsx"));


export default function () {
  const tabList = [
    {
      value: 0,
      label: window.intl.get('交易规则')
    },
    {
      value: 1,
      label: window.intl.get('快速赚钱')
    },
    {
      value: 2,
      label: window.intl.get('常见问题')
    }
  ];
  const [tabIndex, setTabIndex] = useState(() => {
    const currTab = localStorage.getItem('stock-guide-tab-pos') || 0;
    return currTab;
  });
  const onTabChange = val => {
    setTabIndex(val);
    localStorage.setItem('stock-guide-tab-pos', val)
  }
  return (
    <div className="page-stock-guide">
      <div className="tab-wrap">
        <ul>
          {
            tabList.map(item => {
              return (
                <li
                  key={item.value}
                  className={tabIndex==item.value?'active':''}
                  onClick={() => {onTabChange(item.value)}}
                >
                  {item.label}
                  <i></i>
                </li>
              )
            })
          }
        </ul>
      </div>
      {
        tabIndex==0?<Rule />:''
      }
      {
        tabIndex==1?<Money />:''
      }
      <div style={{display: tabIndex==2?'block': 'none', 'flex': 1}}>
        <React.Suspense fallback={""}>
          <Problem />
        </React.Suspense>
      </div>
    </div>
  )
}