import React from "react";
import "./intro.scss";
import img1 from "@/assets/img/financial-detail/1@3x.png";
import img2 from "@/assets/img/financial-detail/2@3x.png";
import img3 from "@/assets/img/financial-detail/3@3x.png";
export default function () {
  return (
    <div className={`component-intro ${window.intl.options.currentLocale}`}>
      <ul>
        <li>
          <div className="img-box">
            <img src={img1} alt=""/>
          </div>
          <div className="middle-title">{window.intl.get("多样")}</div>
          <p>
            {window.intl.getHTML("支持多币种多样理财产品")}
          </p>
        </li>
        <li>
          <div className="img-box">
            <img src={img2} alt=""/>
          </div>
          <div className="middle-title">{window.intl.get("专业")}</div>
          <p>
            {window.intl.getHTML("采用全球领先资产风控体系")}
          </p>
        </li>
        <li>
          <div className="img-box">
            <img src={img3} alt=""/>
          </div>
          <div className="middle-title">{window.intl.get("安全")}</div>
          <p>
            {window.intl.get("离线存储")}<br />
            {window.intl.get("多重授签")}<br />
            {window.intl.get("安全透明")}
          </p>
        </li>
      </ul>
    </div>
  )
}