import { logDOM } from "@testing-library/react";
import React, { useState } from "react";
import { apiGetJoinRoomInfo } from "@/model/join-group/index";
import { useEffect } from "react";
import "./game-lobby.scss";
import api from "@/assets/js/api.js";

// const { REACT_APP_API } = process.env
import {
  REACT_APP_API,
  REACT_APP_ID,
  REACT_CHANNEL_ID,
} from "@/assets/js/stationSetting";

export default function () {
  const [loading, setLoading] = useState(true);
  const [productInfo, setProductInfo] = useState({});
  //   const url = document.location.toString();
  //   const arrUrl = url.split("?");
  //   const domain = arrUrl[0];
  //   const para = arrUrl[1];
  //   const paramsArr = para.split("&");
  //   const mUrl = paramsArr[0].split("m=");
  //   const mParams = mUrl[1];

  useEffect(() => {
    const data = {
      channel_id: REACT_CHANNEL_ID,
    };
    api({
      url:
        "https://mainland.hxexchge.com" +
        `/api/channel/${REACT_CHANNEL_ID}/lobby/index`,
      data,
    }).then(res => {
      if (res.status == 200) {
        console.log(res);
      }
    });
  }, []);
  const onJumpClick = val => {};
  return (
    <div className="game-lobby">
      <div>123</div>
    </div>
  );
}
