import React from "react";
import "./dual-currency-agreement.scss";
export default function () {
  return (
    <div className="dual-currency-agreement">
      <div className="content V1">
        1.
        双币理财服务，是为用户提供闲置数字资产增值服务。用户在使用双币理财服务时，即无条件授
        权资产的使用权利，按平台的规则进行合理的分配和发放。
        <br />
        2.
        用户使用双币投资服务时，应当遵守国家相关法律，保证资产的来源合法合规。并应当充分认识
        到数字资产投资的⻛险，谨慎操作，量力而行。用户同意在平台所进行的所有理财操作代表其真实
        投资意愿，并无条件接受投资决策所带来的潜在⻛险和收益。
        <br />
        3.
        平台保留暂停、终止双币理财服务的权限，在必要的时候，平台可以随时暂停，终止双币投资服
        务
      </div>
      <div className="content">
        4.
        由于网络延迟，计算机系统故障及其他可能的不可抗拒因素，可能导致双币投资服务的执行迟
        缓，暂停，中止或偏差，平台将尽力保证但不承诺双币投资服务执行系统运行的稳定和有效，但由
        于上述因素导致的双币投资服务的最终执行结果与客户预期不同，平台不承担任何责任。
      </div>
      <div className="content">
        5.
        我已阅读并同意双币理财购买协议，并同意购买双币理财业务。我已知晓此⻛险并确认购买。
      </div>
    </div>
  );
}
