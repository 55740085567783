const appKeys = require('./appkey');
const md5 = require('js-md5');
const { REACT_APP_KEY } = process.env
module.exports = (params, app_id) => {
    var appkey = REACT_APP_KEY; // 后台给的 ，根据渠道替换
    var timestamp = Math.round(new Date().getTime() / 1000) + "";
    params["nonce"] = timestamp.substr(-6);
    params["app_id"] = app_id;
    for (let key in params) {
        params[key] = (params[key] || params[key] === 0 || params[key] === false) ? params[key] : '';
    }
    let keys = Object.keys(params);
    let length = keys.length;
    keys = keys.sort();
    let sign = '';
    for (let i = 0; i < length; i++) {
        if (sign != '') sign = sign + "&";
        sign = sign + keys[i] + "=" + params[keys[i]];
    }
    //add appkey
    sign = sign + appkey;
    sign = md5(sign).toString();
    return sign;
}