import React from "react";
import "./rate.scss";
import Step from "../step/step.jsx";
import Plan from "../plan/plan.jsx";
import PlanV2 from "../plan/planV2.jsx";
import { useEffect } from "react";
export default function (props) {
  const { productInfo } = props;
  const {
    status,
    ico_buy_condition,
    amount,
    item_name,
    product_type,
    total_amount,
    annual_rate,
    to_item_name,
    lock_period,
    to_item_id_price,
    min_amount,
    rate_type,
    remain_end_earning_day,
  } = productInfo;
  let rate = 0;
  if (amount) {
    rate = ((total_amount / amount) * 100).toFixed(2);
  }
  const quantTagMap = {
    1: window.intl.get("谨慎型"),
    2: window.intl.get("稳健型"),
    3: window.intl.get("进取型"),
  };
  const rateTypaMap = {
    1: window.intl.get("固定收益"),
    2: window.intl.get("浮动收益"),
  };

  return (
    <div className="icon-component-rate">
      {product_type == 4 && status == 1 ? (
        <div className="schedule recruit">{window.intl.get("募集预热中")}</div>
      ) : (
        ""
      )}
      {product_type == 4 && status == 2 ? (
        <div className="schedule recruit">{window.intl.get("募集开始")}</div>
      ) : (
        ""
      )}
      {product_type == 4 && status == 3 ? (
        <div className="schedule storage">{window.intl.get("募集中")}</div>
      ) : (
        ""
      )}
      {product_type == 4 && status == 4 ? (
        <div className="schedule storage">{window.intl.get("募集结束")}</div>
      ) : (
        ""
      )}
      {product_type == 4 && status == 5 ? (
        <div className="schedule storage">{window.intl.get("计息中")}</div>
      ) : (
        ""
      )}
      {product_type == 4 && status == 6 ? (
        <div className="schedule storage">{window.intl.get("本息发放中")}</div>
      ) : (
        ""
      )}
      {product_type == 4 && status == 7 ? (
        <div className="schedule storage">{window.intl.get("本息已到账")}</div>
      ) : (
        ""
      )}
      {product_type == 4 && status == 8 ? (
        <div className="schedule recruit">{window.intl.get("募集失败")}</div>
      ) : (
        ""
      )}

      <div className={`${window.intl.options.currentLocale} rate-lock-wrap`}>
        <div className="rate-area">
          <span className="rate-num">
            {to_item_id_price}
            {item_name}
          </span>
          <span className="rate-year-num">{window.intl.get("兑换价格")}</span>
        </div>
        <div className="lock-area">
          <span className="rate-num">
            {lock_period}
            {window.intl.get("天")}
          </span>
          <span className="rate-year-num">{window.intl.get("交付期")}</span>
        </div>
      </div>
      {ico_buy_condition == 2 ? (
        <Plan productInfo={productInfo} />
      ) : total_amount > 0 ? (
        <PlanV2 productInfo={productInfo} />
      ) : (
        ""
      )}
      <div className="tip">
        {window.intl.get("Launchpad: 早一步发现价值，寻找下一个比特币")}
      </div>
      {ico_buy_condition == 2 ? (
        <Step
          pre={window.intl.get("募集开始")}
          middle={window.intl.get("开始交付")}
          next={window.intl.get("交付完成")}
          productInfo={productInfo}
        ></Step>
      ) : (
        <Step
          pre={window.intl.get("募集开始")}
          middle={window.intl.get("代币转换，开始交付")}
          next={window.intl.get("交付完成")}
          productInfo={productInfo}
        ></Step>
      )}
    </div>
  );
}
