import React, { useState } from "react";
import "./stock.scss";



export default function () {

  return (
    <div className="stock-business">
       <div className="title">{window.intl.get('合规经营、安全高效')}</div>
       <div className="sec">
        {/* <div className="common-component-btn"><span className="title-content">{window.intl.get('合规经营、安全高效')}</span></div> */}
        <div className="sub-title">{window.intl.get('汇信数字货币私人银行正式宣布取得美/港股金融牌照。汇信私人银行始终将合规、交易迅速、高隐私作为企业发展的重要方针，美/港股金融牌照的取得成为汇信私人银行规范化运营与全球化布局的重要里程碑。')}</div>
        <div className="sub-title V2">{window.intl.get('汇信私人银行始终坚持客户第一，致力于为全球用户提供最安全、高速率且高隐私的数字资产交易服务。未来，汇信将继续落实全球化战略布局，坚持合规化运营、发展。')}</div>
        <div className="license-photos">
            <span className="placeholder-v4"></span>
        </div>
      </div>
      <div className="bottom">
            <div className="common-text"><span className="title-content">{window.intl.get('股票 1、4、9号金融牌照')}</span></div>
      </div>
    </div>
  )
}