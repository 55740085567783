import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import FinancialDetail from "./view/financial-detail/financial-detail";
// import CoinDetail from "./view/coin-detail/coin-detail.jsx";
import SupportArticle from "./view/support-article/support-article.jsx";
import useLang from "@/hooks/useLang.js";
import querystring from "@/utils/querystring";
import privacyPolicy from "./view/privacy-policy/privacy-policy";
// import MarginExchange from './view/margin-exchange/margin-exchange';
import Kline from "./view/kline/kline";
import Loading from "@/component/loading/loading.jsx";
import TradingGuide from "./view/trading-guide/trading-guide";
import StockGuide from "./view/stock-guide/stock-guide";
import BusinessCompliance from "./view/business-compliance/business-compliance";
import OperationalCompliance from "./view/business-compliance/operational-compliance";
import EverntrustCompliance from "./view/business-compliance/everntrust-compliance";
import UeasyCompliance from "./view/business-compliance/ueasy-compliance";
import DigitalCurrency from "./view/business-compliance/digital-currency";
import FinancialLicense from "./view/financial-license/financial-license";
import FeeDetail from "./view/fee-detail/fee-detail";
import ProblemDetail from "./view/problem-detail/problem-detail";
import IcoIntroduction from "./view/ico/ico-introduction";
import IcoInfo from "./view/ico/icon-info";
import DefiMiningIntroduction from "./view/defiMining/defi-mining-introduction";
import DefiMiningInfo from "./view/defiMining/defiMining-info";
import Chia from "./view/chia/chia";
import PrivacyAgreement from "./view/privacyAgreement/privacyAgreement";
import PrivacyAgreementTiger from "./view/privacyAgreement/privacyAgreementTiger";
import UserAgreement from "./view/user-agreement/user-agreement";
import Joingroup from "./view/join-group/join-group";
import GameLobby from "./view/game-lobby/game-lobby";
import NotSupported from "./view/not-supported/not-supported";
import DualCurrencyInvestment from "./view/dual-currency-investment/dual-currency-investment";
import DualCurrencyAgreement from "./view/dual-currency-agreement/dual-currency-agreement";

function App() {
  const lang = querystring(window.location.hash, "lang");
  // 设置cookie，uselang hooks读取设置多语言
  window.$.cookie("lang", lang);
  const initDone = useLang();
  return (
    <React.Fragment>
      {initDone ? (
        <Router>
          <Switch>
            <Route
              path="/financial-detail/:product_id"
              component={FinancialDetail}
            ></Route>
            {/* <Route path="/coin-detail/:symbol" component={CoinDetail}></Route> */}
            <Route
              path="/support-article/:articleId"
              component={SupportArticle}
            ></Route>
            <Route path="/privacy-policy" component={privacyPolicy}></Route>
            {/* <Route path="/margin-exchange/:symbol" component={MarginExchange}></Route> */}
            <Route path="/kline/:symbol" component={Kline}></Route>
            <Route path="/trading-guide" component={TradingGuide}></Route>
            <Route path="/stock-guide" component={StockGuide}></Route>
            <Route
              path="/financial-license"
              component={FinancialLicense}
            ></Route>
            <Route path="/fee-detail" component={FeeDetail}></Route>
            <Route
              path="/problem-detail/:articleId"
              component={ProblemDetail}
            ></Route>
            <Route
              path="/business-compliance"
              component={BusinessCompliance}
            ></Route>
            <Route path="/digital-currency" component={DigitalCurrency}></Route>
            <Route
              path="/operational-compliance"
              component={OperationalCompliance}
            ></Route>
            <Route
              path="/everntrust-compliance"
              component={EverntrustCompliance}
            ></Route>
            <Route path="/ueasy-compliance" component={UeasyCompliance}></Route>
            <Route path="/ico-introduction" component={IcoIntroduction}></Route>
            <Route path="/ico-info/:product_id" component={IcoInfo}></Route>

            <Route
              path="/defiMining-introduction"
              component={DefiMiningIntroduction}
            ></Route>
            <Route
              path="/defiMining-info/:product_id"
              component={DefiMiningInfo}
            ></Route>
            <Route path="/loading" component={Loading}></Route>
            <Route path="/user-agreement" component={UserAgreement}></Route>
            <Route path="/chia/:product_id" component={Chia}></Route>
            <Route
              path="/privacy-agreement"
              component={PrivacyAgreement}
            ></Route>
            <Route
              path="/supoport-tiger"
              component={PrivacyAgreementTiger}
            ></Route>

            <Route path="/joingroup" component={Joingroup}></Route>
            <Route
              path="/dual-currency-investment"
              component={DualCurrencyInvestment}
            ></Route>
            <Route
              path="/dual-currency-agreement"
              component={DualCurrencyAgreement}
            ></Route>
            <Route path="/game-lobby" component={GameLobby}></Route>
            <Route path="/not-supported" component={NotSupported}></Route>
          </Switch>
        </Router>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}

export default App;
