import React from "react";
import "./defiMining-info.scss";
import Rule from "./component/rule/rule.jsx";
import Rate from "./component/rate/rate";
import Chart from "./component/chart/chart.jsx";
import Pie from "./component/pie/pie.jsx";
import Loading from "@/component/loadingV2/loading.jsx";
import { apiGetDeFiProductInfo } from "@/model/defiMining-info/index";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
export default function () {
  const { product_id } = useParams();
  const [productInfo, setProductInfo] = useState({
    detail: {
      channel_id: 101,
      earnings_rule: "",
      i18n_lang: "",
      inout_rule: "",
      intro_url: "https://help.topcreditbank.com/#/ico-introduction",
      product_id: 1,
      product_intro: "",
      risk_tip: "",
    },
  });
  const [rateList, setRateList] = useState([]);
  const [coinInfo, setCoinInfo] = useState({});
  const [rateList30, setRateList30] = useState([]);
  const [incRateList, setIncRateList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const req = {
      product_id,
    };
    apiGetDeFiProductInfo(req).then(rs => {
      setLoading(false);
      if (rs.status == 200) {
        let to_item_ids_arr = rs.data.productInfo.to_item_ids.split(",");
        if (JSON.stringify(rs.data.productInfo.item_data) != "{}") {
          rs.data.productInfo.item_url =
            rs.data.productInfo.item_data[rs.data.productInfo.item_id].item_url;
          rs.data.productInfo.item_name =
            rs.data.productInfo.item_data[
              rs.data.productInfo.item_id
            ].item_name;
          rs.data.productInfo["to_item_name"] = "";
          to_item_ids_arr.forEach(ele => {
            if (rs.data.productInfo.item_data[ele].item_name != undefined) {
              rs.data.productInfo["to_item_name"] +=
                rs.data.productInfo.item_data[ele].item_name + "+";
            }
          });
          rs.data.productInfo["to_item_name"] = rs.data.productInfo[
            "to_item_name"
          ].substring(0, rs.data.productInfo["to_item_name"].length - 1);
        }
        setProductInfo(rs.data.productInfo);
        setRateList(rs.data.rateList);
      }
    });
  }, []);

  return (
    <div className="defiMining-info">
      {!loading ? (
        <React.Fragment>
          <Rate productInfo={productInfo} />
          {/* <Header productInfo={productInfo}></Header> */}
          <Rule productInfo={productInfo} detail={productInfo.detail} />
          <Chart rateList={rateList} />
          <Pie productInfo={productInfo} />
          <i className="gap"></i>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}
