import React from "react";
import "./tips.scss";
export default function Plan(props) {
  const { productInfo, detail } = props;
  const { risk_tip, tip } = detail;
  let rate = 0;

  return (
    <div className="tips-component">
      {risk_tip ? (
        <div>
          <p className="header">{window.intl.get("风险提示：")}</p>
          <p className="content">{risk_tip}</p>
        </div>
      ) : (
        ""
      )}
      {tip ? (
        <div>
          <p className="header V2">{window.intl.get("注意事项与收益：")}</p>
          <p className="content" dangerouslySetInnerHTML={{ __html: tip }}></p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
