import React from "react";
import "./money.scss";
export default function () {
  const isS8v = window.location.host.toUpperCase().indexOf("8V") != -1;
  return (
    <div className="component-money">
      <div className="sec sec1">
        <div className="common-component-btn">
          {window.intl.get("产品特点")}
        </div>
        <div className="step">
          <ul>
            <li>
              <i>1</i>
              <span>{window.intl.get("开仓")}</span>
            </li>
            <li>
              <i>2</i>
              <span>{window.intl.get("持仓")}</span>
            </li>
            <li>
              <i>3</i>
              <span>{window.intl.get("平仓")}</span>
            </li>
          </ul>
        </div>
        <div className="common-component-btn">
          {window.intl.get("如何开仓？")}
        </div>
        {/* <div className="placeholder placeholder1"></div> */}
        <div
          className={["placeholder placeholder1", isS8v ? "s8v" : null].join(
            " "
          )}
        ></div>
        <div className="sub-title">
          {window.intl.get("1. 根据您的需求，选择杠杆倍数")}
        </div>
        {/* <div className="placeholder placeholder2"></div> */}
        <div
          className={["placeholder placeholder2", isS8v ? "s8v" : null].join(
            " "
          )}
        ></div>
        <div className="sub-title">{window.intl.get("2. 输入本金金额")}</div>
        {/* <div className="placeholder placeholder3"></div> */}
        <div
          className={["placeholder placeholder3", isS8v ? "s8v" : null].join(
            " "
          )}
        ></div>
        <div className="sub-title">
          {window.intl.get("3. 买涨/买跌，完成开仓")}
        </div>
        {/* <div className="placeholder placeholder4"></div> */}
        <div
          className={["placeholder placeholder4", isS8v ? "s8v" : null].join(
            " "
          )}
        ></div>
      </div>
      <div className="sec sec2">
        <div className="common-component-btn">
          {window.intl.get("如何查看持仓？")}
        </div>
        {/* <div className="sub-title">{window.intl.get('如何查看持仓？')}</div> */}
        <div className="placeholder placeholder5"></div>

        <div className="sub-title">
          {window.intl.get("1. 请点击页面底部的“持仓”按钮，切换到持仓页面")}
        </div>
        <div className="placeholder placeholder6"></div>

        <div className="sub-title">
          {window.intl.get(
            "2. 持仓页面展示了您所有的持仓。每一笔持仓都展示了当前的盈亏、盈亏比和强平价格等信息"
          )}
        </div>
        <div className="placeholder placeholder7"></div>

        <div className="sub-title">
          {window.intl.get("3. 点击某笔持仓，可查看持仓详情")}
        </div>
        <div className="placeholder placeholder8"></div>
      </div>
      <div className="sec sec3">
        <div className="common-component-btn">
          {window.intl.get("如何平仓？")}
        </div>
        <div className="placeholder placeholder9"></div>

        <div className="sub-title">
          {window.intl.get(
            "1. “平仓”即立即以市价结算此笔持仓的盈亏。平仓后，此持仓的本金和平仓盈亏将返还至余额中"
          )}
          <br />
          {window.intl.get("2. 如需平仓，请点击对应持仓的“平仓”按钮即可")}
        </div>
        <div className="placeholder placeholder10"></div>

        <div className="sub-title">
          {window.intl.get(
            "3. 您还可以通过设置止盈价和止损价，让系统自动帮您平仓"
          )}
        </div>
        <div className="placeholder placeholder11"></div>

        <div className="sub-title">
          {window.intl.get("4. 平仓后，您可在“已平仓”标签下查看平仓盈亏等信息")}
        </div>
        <div className="placeholder placeholder12"></div>
      </div>
    </div>
  );
}
