import React from "react";
import Step from "../step/step.jsx";
import "./rule.scss";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "antd-mobile";
import { timestampToDate, timestampToYear } from "@/utils/date-format.js";
import { useHistory, useParams } from "react-router-dom";
import { Popover, NavBar, Icon } from "antd-mobile";
export default function (props) {
  const history = useHistory();
  const Item = Popover.Item;
  const [knowVisible, setKnowVisible] = useState(false);
  const { productInfo, detail } = props;
  const lang = window.$.cookie("lang") || "zh_CN";
  const {
    item_name,
    tag,
    to_item_name,
    from_farm,
    float_apr,
    float_apy,
    float_day_rate,
  } = productInfo;
  const quantTagMap = {
    1: window.intl.get("单投单利"),
    2: window.intl.get("单投双利"),
    3: window.intl.get("复利复投"),
  };
  const ruleMap = {
    1: window.intl.get(
      "投入高收益资金池，灵活锁仓，随时可存取，每日取得收益。"
    ),
    2: window.intl.get("投入高收益资金池，灵活锁仓，随时可存取"),
    3: window.intl.get(
      "投入高收益资金池，灵活锁仓，随时可存取，每日收益自动复投，滚动复利。"
    ),
  };
  const getCoinMap = {
    1: window.intl.get("每日收益自动到帐"),
    2: window.intl.get("每日收益自动到帐"),
    3: window.intl.get("每日收益自动复存，享受滚动复利"),
  };
  const { risk_tip, buy_rule, join_rule, ico_eg } = detail;
  const onMessage = () => {
    window.location.href = "/#/defiMining-introduction";
  };
  const onClickRule = () => {
    // window.open(
    //   "https://top6688.zendesk.com/hc/zh-cn/articles/360019970998-%E6%B1%87%E4%BF%A1%E6%B1%A0"
    // );
    window.location.href = "/#/defiMining-introduction";
    // setKnowVisible(true);
  };
  const onCloseVisible = () => {
    setKnowVisible(false);
  };
  useEffect(() => {
    document.body.addEventListener(
      "touchmove",
      function (e) {
        setKnowVisible(false);
      },
      { passive: false }
    );
  }, []);
  return (
    <div className="component-rule">
      <div className="rule-top">
        <div className="left">
          <div className="title">{window.intl.get("DeFi挖矿")} </div>
          <div onClick={onClickRule} className="placeholder-rule"></div>
        </div>{" "}
      </div>
      <div className="line-item">
        <div className="next">
          {window.intl.get("投入")}&nbsp;
          {item_name}&nbsp;
          {window.intl.get("DeFi挖矿汇信将会帮您把")}{item_name}&nbsp;{ruleMap[tag]}
        </div>
      </div>
      <div className="title second-title">{window.intl.get("收益规则")}</div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("收益计算")}</span>
        </div>
        <div className="next">
          {window.intl.get("实时资产*实时年化利率/365")}
        </div>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("发放时间")}</span>
        </div>
        <div className="next">
          {window.intl.get("每日16点(UTC+8)发放前一日收益")}
        </div>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("到账方式")}</span>
        </div>
        {<div className="next">{getCoinMap[tag]}</div>}
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("每日利息")}</span>
        </div>
        {
          <div className="next">
            {window.intl.get(
              "根据市场情况，利息实时变化，日末计算当日收益时按实时利率计算"
            )}
          </div>
        }
      </div>
    </div>
  );
}
