import React from "react";
import "./rate.scss";
import Plan from "../plan/plan.jsx";
import Step from "../step/step.jsx";
import { useEffect } from "react";
export default function (props) {
  const { productInfo } = props;
  const {
    status,
    price,
    amount,
    item_name,
    product_type,
    total_amount,
    annual_rate,
    to_item_name,
    expire,
    to_item_id_price,
    min_amount,
    rate_type,
    remain_end_earning_day,
  } = productInfo;
  let rate = 0;
  if (amount) {
    rate = ((total_amount / amount) * 100).toFixed(2);
  }
  const quantTagMap = {
    1: window.intl.get("谨慎型"),
    2: window.intl.get("稳健型"),
    3: window.intl.get("进取型"),
  };
  const rateTypaMap = {
    1: window.intl.get("固定收益"),
    2: window.intl.get("浮动收益"),
  };

  return (
    <div className="icon-component-rate">
      {status == 1 ? (
        <div className="schedule recruit">{window.intl.get("申购预热中")}</div>
      ) : (
        ""
      )}
      {status == 2 ? (
        <div className="schedule recruit">{window.intl.get("申购中")}</div>
      ) : (
        ""
      )}
      {status == 3 ? (
        <div className="schedule storage">{window.intl.get("申购结束")}</div>
      ) : (
        ""
      )}
      {/* {status == 4 ? (
        <div className="schedule storage">{window.intl.get("申购失败")}</div>
      ) : (
        ""
      )} */}

      <div className={`${window.intl.options.currentLocale} rate-lock-wrap`}>
        <div className="rate-area">
          <span className="rate-num">
            {price}
            {item_name}/T
          </span>
          <span className="rate-year-num">{window.intl.get("价格")}</span>
        </div>
        <div className="lock-area">
          <span className="rate-num">
            {expire}
            {window.intl.get("天")}
          </span>
          <span className="rate-year-num">{window.intl.get("有效期")}</span>
        </div>
      </div>
      <Plan productInfo={productInfo} />
      <div className="tip">{window.intl.get("限时限额，先到先得")}</div>
      <Step productInfo={productInfo} />
    </div>
  );
}
