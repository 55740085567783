
export default function (state, action) {
  switch (action.type) {
    case "SET_DEPTH":
      state.depth = { ...action.data }
      return { ...state };
      case "SET_ORDERBOOK":
        state.orderbook = action.data;
        return { ...state };
      case "UPDATE_ORDERBOOK":
        if (state.orderbook instanceof Array) {
          state.orderbook.unshift(action.data);
          state.orderbook.length = 20;
        }
        return {...state};
      case "UPDATE_DEPTH":
        const asksMap = {};
        const bidsMap = {};
        let asksList = [];
        let bidsList = [];
        const {a: newAsksList, b: newBidsList} = action.data;
        
        if (state.depth) {
          state.depth.a.forEach(([price, number]) => {
            asksMap[price] = number;
          })
          state.depth.b.forEach(([price, number]) => {
            bidsMap[price] = number;
          })
          newAsksList.forEach(([price, number]) => {
            if (number == 0) {
              delete asksMap[price]
              return;
            }
            asksMap[price] = number;
          });
          newBidsList.forEach(([price, number]) => {
            if (number == 0) {
              delete bidsMap[price]
              return;
            }
            bidsMap[price] = number;
          });
          for (const key in asksMap) {
            asksList.push([key, asksMap[key]]);
          }
          for (const key in bidsMap) {
            bidsList.push([key, bidsMap[key]]);
          }
          asksList = asksList.sort((a, b) => {
            if (+a[0]>+b[0]) {
              return 1;
            } else {
              return -1;
            }
          })
          bidsList = bidsList.sort((a, b) => {
            if (+a[0]<+b[0]) {
              return 1;
            } else {
              return -1;
            }
          })
          if (asksList.length > 500) {
            asksList.length = 500;
          }
          if (bidsList.length > 500) {
            bidsList.length = 500;
          }
          const newDepth = {
            a: asksList,
            b: bidsList
          }
          state.depth = newDepth;
          return {...state};
        } else {
          return {...state};
        }
        
        
    default:
      return {...state}
  }
  return state;
}