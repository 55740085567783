import React from 'react';
import './financial-license.scss';
export default function () {
  // const lang = window.intl.options.currentLocale;
  return (
    <div className="page-financial-license">
        {/* <div className="title-icon">
            <div className="placeholder-v1"></div>
            <div className="placeholder-v2"></div>
        </div> */}
        <div className="placeholder-v3"></div>
      <div className="sec">
        <div className="common-component-btn"><span className="title-content">{window.intl.get('合规经营、安全高效')}</span></div>
        <div className="sub-title">{window.intl.get('汇信数字货币私人银行正式宣布取得美/港股金融牌照。汇信私人银行始终将合规、交易迅速、高隐私作为企业发展的重要方针，美/港股金融牌照的取得成为汇信私人银行规范化运营与全球化布局的重要里程碑')}</div>
        <div className="sub-title V2">{window.intl.get('汇信私人银行始终坚持客户第一，致力于为全球用户提供最安全、高速率且高隐私的数字资产交易服务。未来，汇信将继续落实全球化战略布局，坚持合规化运营、发展。')}</div>
        <div className="license-photos">
               {/* <div className="photo-content"> <span className="placeholder-v4"></span><p className="photo-text">{window.intl.get('香港金融1号牌照')}</p></div>
               <div className="photo-content"><span className="placeholder-v5"></span><p className="photo-text">{window.intl.get('香港金融3号牌照')}</p></div> 
               <div className="photo-content V3"> <span className="placeholder-v6"></span><p className="photo-text">{window.intl.get('香港金融9号牌照')}</p></div>
               <div className="photo-content V3"> <span className="placeholder-v7"></span><p className="photo-text">{window.intl.get('美国牌照')}</p></div> */}
        </div>
      </div>
      <div className="bottom">
            <div className="common-text"><span className="title-content">{window.intl.get('让金融更高效，让财富更自由，让隐私更安全')}</span></div>
      </div>
    </div>
  )
}