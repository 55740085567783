import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./muti-chart.scss";
import { timestampToDayToBJ } from "@/utils/date-format.js";
import { useRef } from "react";
import { useCallback } from "react";

export default function (props) {
  const tabList = [
    {
      value: "1",
      label: window.intl.get("7日年化"),
    },
    {
      value: "2",
      label: window.intl.get("30日年化"),
    },
    {
      value: "3",
      label: window.intl.get("历史净值"),
    },
  ];
  let { rateList, rateList30, incRateList } = props;
  const [currTab, setCurrTab] = useState("1");
  const [list, setList] = useState([]);
  const [xList, setXList] = useState([]);
  const [yList, setyList] = useState([]);
  const [chart, setChart] = useState(null);
  const [step, setStep] = useState(1);
  const chartRef = useRef();
  const [type, setType] = useState("linear");

  useEffect(() => {
    setList(rateList);
  }, [rateList]);

  useEffect(() => {
    const xl = list.reduce((accumulate, curr) => {
      return [...accumulate, curr.date];
    }, []);
    const yl = list.reduce((accumulate, curr) => {
      return [...accumulate, +curr.rate];
    }, []);
    setXList(xl);
    setyList(yl);

    if (list.length <= 7) {
      setStep(1);
    } else if (list.length > 7 && list.length <= 21) {
      setStep(3);
    } else {
      setStep(4);
    }
  }, [list]);

  Highcharts.setOptions({
    lang: {
      rangeSelectorZoom: "",
    },
  });
  const options = {
    colors: ["#f04a5a"],
    title: {
      enabled: false,
      align: "left",
      text: "",
    },
    chart: {
      type: "areaspline",
      plotBorderWidth: 0,
      margin: [20, 0, 20, 50],
      height: "200px",
      animation: false,
      events: {
        // load: function() {
        //   setTimeout(() => {
        //     var points = chartRef.current.series[0].points;
        //     chartRef.current.tooltip.refresh(points[points.length- 1]);
        //   })
        // }
      },
    },
    xAxis: {
      categories: xList,
      tickWidth: 0,
      labels: {
        formatter: function (val) {
          return timestampToDayToBJ(val.value);
        },
        style: {
          fontSize: "8px",
        },
        step,
      },
      gridLineWidth: 0,
    },
    yAxis: {
      type,
      title: {
        enabled: false,
      },
      labels: {
        formatter: function (val) {
          if (currTab == 3) {
            return val.value;
          }
          return (val.value * 100).toFixed(2) + "%";
        },
        style: {
          fontSize: "8px",
        },
      },
      gridLineWidth: 1,
      gridLineColor: "#eee",
    },
    series: [
      {
        // data: [0.2, 0.21, 0.23, 0.22, 0.25, 0.27, 0.28, 0.3,0.29, 0.23, 0.4, 0.1, 0.1, 0.2, 0.4, 0.2, 0.2, 0.21, 0.23, 0.22, 0.25, 0.27, 0.28, 0.3,0.29, 0.23, 0.4, 0.1, 0.1, 0.2, 0.4, 0.2 ],
        data: yList,
        fillOpacity: 0.1,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(255, 74, 120, 0.31)"],
            [1, "rgba(255, 134, 165, 0.09)"],
          ],
        },
        marker: {
          enabled: true,
        },
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      borderWidth: 0,
      backgroundColor: "#f04a5a",
      borderRadius: 8,
      padding: 4,
      shadow: false,
      //   crosshairs: {
      //     width: 1,
      // 		color: '#f04a5a',
      // 		dashStyle: 'shortdot'
      //   },
      style: {
        color: "#fff",
        fontSize: "10px",
      },
      formatter: function (val) {
        const temp = timestampToDayToBJ(this.x);
        if (currTab == 3) {
          // return this.y;
          return `
          ${this.y}<br />
          ${temp}
          `;
        }
        const result = `
          ${(this.y * 100).toFixed(2)}%<br />
          ${temp}
        `;
        return result;
        // return (this.y * 100).toFixed(2) + '%';
      },
      //   shadow:false,
      //   positioner: function(val) {
      //     return {x: 0, y: 0}
      //   }
    },
  };
  const onSet = (item) => {
    setCurrTab(item.value);
    if (item.value == 1) {
      setList(rateList);
      setType("linear");
    } else if (item.value == 2) {
      setList(rateList30);
      setType("linear");
    } else if (item.value == 3) {
      setList(incRateList);
      setType("logarithmic");
    }
    // setTimeout(() => {
    //   var points = chartRef.current.series[0].points;
    //   chartRef.current.tooltip.refresh(points[points.length- 1]);
    // }, 100)
  };
  const afterChartCreated = (c) => {
    chartRef.current = c;
  };
  return (
    <div>
      <div className="component-muti-chart">
        <ul className="tab-list">
          {tabList.map((item) => {
            return (
              <li
                key={item.value}
                className={currTab == item.value ? "active" : ""}
                onClick={() => {
                  onSet(item);
                }}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
        <HighchartsReact
          className="highchart-container"
          highcharts={Highcharts}
          options={options}
          callback={afterChartCreated}
        ></HighchartsReact>
      </div>
    </div>
  );
}
