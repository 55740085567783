import moment_tz from "moment-timezone";

export function timestamp(created, formate = "yyyy-MM-dd HH:mm") {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, formate);
}
export function timestampToDate(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "MM.dd HH:mm");
}

export function timestampToDay(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "MM-dd");
}
export function timestampToDayToBJ(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  const dateWithZone1 = moment_tz(date).tz("Asia/Shanghai");
  return dateWithZone1.format("MM-DD");
}

export function timestampToYear(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "yyyy-MM-dd HH:mm");
}

export function timestampToYearToBJ(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  const dateWithZone1 = moment_tz(date).tz("Asia/Shanghai");
  return dateWithZone1.format("YYYY-MM-DD HH:mm");
}
export function timestampToDateToBJ(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  const dateWithZone1 = moment_tz(date).tz("Asia/Shanghai");
  return dateWithZone1.format("MM.DD HH:mm");
}
