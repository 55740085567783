import React from "react";
import "./privacy-policy.scss";
import { useEffect } from "react";
export default function () {
  useEffect(() => {
    let host = window.location.host;
    if (host == "help.8v87.com") {
      window.location.href =
        "https://netlink2084.zendesk.com/hc/zh-tw/articles/4409463866905";
    }
  }, []);

  const lang = window.intl.options.currentLocale;
  return (
    <div className="page-pricacy-policy">
      <div className="article-title">
        {/* {window.intl.get("隐私政策")} */}
        Privacy Policy
      </div>
      <div className="article-content">
        <br />
        This application respects and protects the personal privacy for all
        users. In order to provide you with more accurate and personalized
        services, this application will use and disclose your personal
        information in accordance with this privacy policy. However, this
        application will treat these information with a high degree of diligence
        and prudence. In addition to what this privacy policy mentioned, this
        application will not disclose or provide these information to third
        parties without your permission. This application will update the
        privacy policy from time to time. When you agree to use this application
        service, you are considered to have agreed to the entire content of this
        privacy policy. This privacy policy is an integral part of this
        application user service agreement.
        <br />
        <br />1 Scope of Application
        <br />
        (a) When you sign up for this application, the information you provide
        according to the requirements of this application;
        <br /> (b) When you use this application network service or visit the
        application platform, the information including but not limited to your
        IP address, browser type, the language used, the date and time visited,
        the software and hardware characteristics and the webpage records that
        automatically records on your browser and computer;
        <br /> (c) The information that the application obtains from business
        partners through legal means.
        <br /> You understand and agree that the following information does not
        apply to this privacy policy:
        <br />
        <br /> (a) The keyword information you enter when using the search
        service provided by this application platform;
        <br /> (b) The relevant information and data that you publish and
        collected by this application, including but not limited to
        participation in activities, transaction information and comments;
        <br /> (c) The behavior that is illegal or violate the rules of this
        application and the measures that this application has taken against
        you.
        <br />
        <br /> 2 The Information Usage
        <br /> (a) This application will not provide, sell, rent, share or trade
        your personal information to any unrelated third party, unless we have
        obtained your permission in advance, or the third party and this
        application(including affiliate) provide you with services, and after
        the end of the service, they will be prohibited from accessing all these
        materials that they were able to.
        <br /> (b) This application does not allow any third party to collect,
        edit, sell or disseminate your personal information by any means. If any
        user of this application engages in the above activities, this
        application has the right to immediately terminate the service once
        discovered.
        <br /> (c) For the purpose of providing services, this application may
        use your personal information to provide you with information that you
        are interested in, including but not limited to sending you product and
        service information, sharing these information with partners (With your
        permission advanced).
        <br />
        <br /> 3 Information Disclosure
        <br /> In the following cases, this application will disclose your
        personal information in whole or in part according to your personal
        wishes or legal requirements:
        <br /> (a) Disclosure to third party with your permission;
        <br /> (b) To provide the products and services you request, we must
        share your personal information with third parties;
        <br /> (c) According to the law or the requirements of the
        administrative or judicial organization, we disclosure your information
        to third party or administrative or judicial organization;
        <br /> (d) If you violate laws, regulations or this application service
        agreement or related rules, we need to disclose to the third party;
        <br /> (e) If you are a qualified intellectual property complainant and
        have filed a complaint, we should disclose to the respondent so that
        both parties can deal with disputes;
        <br /> (f) In a transaction created on this application platform, if any
        party fulfills or partially fulfills the transaction obligation and
        makes an information disclosure request, the application has the right
        to provide the contact information to facilitate the completion of the
        transaction or the resolution of disputes.
        <br /> (g) Others which we considered is suitable to disclose according
        to laws, regulations or website policies.
        <br />
        <br /> 4 Information Storage and Exchange
        <br /> The information and data collected by this application will be
        stored on the servers of this application or its affiliated companies.
        These information and data may be sent to your country, region, or
        outside the country where the application collects information and data.
        <br />
        <br /> 5 Use of Cookies
        <br /> (a) If you do not refuse to accept cookies, this application will
        set or access cookies on your computer so that you can log in or use the
        services and functions that rely on cookies. This application uses
        cookies to provide you with more thoughtful and personalized services,
        including promotional services.
        <br /> (b) You have the right to choose to accept or refuse cookies. You
        can refuse to accept cookies by modifying your browser settings. But if
        you choose to refuse to accept cookies, you may not be able to log in or
        use the services or functions of this application that rely on cookies.
        <br /> (c) The relevant information obtained through the cookies set in
        this application will be applicable to this policy.
        <br />
        <br />
        6. Information Security
        <br />
        (a) This application account has security protection function, please
        keep your user name and password information properly. This application
        will use security measures such as encrypting user passwords to ensure
        that your information is not lost, abused and altered. Despite the
        aforementioned security measures, please note that there is no "perfect
        security measure" on the information network.
        <br />
        (b) When using this application network service to conduct online
        transactions, you will inevitably disclose your personal information,
        such as contact information or postal address, to the counterparty or
        potential counterparty. Please properly protect your personal
        information and only provide it to others when necessary. If you find
        that your personal information is leaked, especially the user name and
        password of this application, please contact the customer service of
        this application immediately so that this application can take
        corresponding measures.
        <br />
        <br />
        7. Changes to this privacy policy
        <br />
        (a) If you decide to change the privacy policy, we will post these
        changes in this policy, on our company’s website, and where we deem
        appropriate, so that you can understand how we collect and use your
        personal information and who can Access this information and under what
        circumstances we will disclose it.
        <br />
        (b) The company reserves the right to modify this policy at any time, so
        please check it frequently. If there is a major change to this policy,
        the company will notify it through a website notice.
      </div>
      {/* {lang == "zh_CN" ? (
        <div className="article-content">
          本应用尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。
          您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。
          <br />
          <br />
          1. 适用范围
          <br />
          (a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；
          <br />
          (b)在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
          <br />
          (c) 本应用通过合法途径从商业伙伴处取得的用户个人数据。
          <br />
          <br />
          您了解并同意，以下信息不适用本隐私权政策：
          <br />
          (a) 您在使用本应用平台提供的搜索服务时输入的关键字信息；
          <br />
          (b)
          本应用收集到的您在本应用发布的有关信息数据，包括但不限于参与活动、成交信息及评价详情；
          <br />
          (c) 违反法律规定或违反本应用规则行为及本应用已对您采取的措施。
          <br />
          <br />
          2. 信息使用
          <br />
          (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
          <br />
          (b)
          本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
          <br />
          (c)
          为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
          <br />
          <br />
          3. 信息披露
          <br />
          在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
          <br />
          (a) 经您事先同意，向第三方披露；
          <br />
          (b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
          <br />
          (c)
          根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
          <br />
          (d)
          如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；
          <br />
          (e)
          如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
          <br />
          (f)
          在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
          <br />
          (g) 其它本应用根据法律、法规或者网站政策认为合适的披露。
          <br />
          <br />
          4. 信息存储和交换
          <br />
          本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。
          <br />
          <br />
          5. Cookie的使用
          <br />
          (a)
          在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies
          ，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。
          <br />
          (b)
          您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。
          <br />
          (c) 通过本应用所设cookies所取得的有关信息，将适用本政策。
          <br />
          <br />
          6. 信息安全
          <br />
          (a)
          本应用帐号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
          <br />
          (b)
          在使用本应用网络服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。
          <br />
          <br />
          7.本隐私政策的更改
          <br />
          (a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
          <br />
          (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。
        </div>
      ) : (
        ""
      )}
      {lang == "zh_HK" ? (
        <div className="article-content">
          本應用尊重並保護所有使用服務用戶的個人隱私權。為了給您提供更準確、更有個性化的服務，本應用會​​按照本隱私權政策的規定使用和披露您的個人信息。但本應用將以高度的勤勉、審慎義務對待這些信息。除本隱私權政策另有規定外，在未徵得您事先許可的情況下，本應用不會將這些信息對外披露或向第三方提供。本應用會不時更新本隱私權政策。您在同意本應用服務使用協議之時，即視為您已經同意本隱私權政策全部內容。本隱私權政策屬於本應用服務使用協議不可分割的一部分。
          <br />
          <br />
          1. 適用範圍
          <br />
          (a) 在您註冊本應用帳號時，您根據本應用要求提供的個人註冊信息；
          <br />
          (b)在您使用本應用網絡服務，或訪問本應用平台網頁時，本應用自動接收並記錄的您的瀏覽器和計算機上的信息，包括但不限於您的IP地址、瀏覽器的類型、使用的語言、訪問日期和時間、軟硬件特徵信息及您需求的網頁記錄等數據；
          <br />
          (c) 本應用通過合法途徑從商業夥伴處取得的用戶個人數據。
          <br />
          <br />
          您了解並同意，以下信息不適用本隱私權政策：
          <br />
          (a) 您在使用本應用平台提供的搜索服務時輸入的關鍵字信息；
          <br />
          (b)
          本應用收集到的您在本應用發布的有關信息數據，包括但不限於參與活動、成交信息及評價詳情；
          <br />
          (c) 違反法律規定或違反本應用規則行為及本應用已對您採取的措施。
          <br />
          <br />
          2. 信息使用
          <br />
          (a)本應用不會向任何無關第三方提供、出售、出租、分享或交易您的個人信息，除非事先得到您的許可，或該第三方和本應用（含本應用關聯公司）單獨或共同為您提供服務，且在該服務結束後，其將被禁止訪問包括其以前能夠訪問的所有這些資料。
          <br />
          (b)
          本應用亦不允許任何第三方以任何手段收集、編輯、出售或者無償傳播您的個人信息。任何本應用平台用戶如從事上述活動，一經發現，本應用有權立即終止與該用戶的服務協議。
          <br />
          (c)
          為服務用戶的目的，本應用可能通過使用您的個人信息，向您提供您感興趣的信息，包括但不限於向您發出產品和服務信息，或者與本應用合作夥伴共享信息以便他們向您發送有關其產品和服務的信息（後者需要您的事先同意）。
          <br />
          <br />
          3. 信息披露
          <br />
          在如下情況下，本應用將依據您的個人意願或法律的規定全部或部分的披露您的個人信息：
          <br />
          (a) 經您事先同意，向第三方披露；
          <br />
          (b)為提供您所要求的產品和服務，而必須和第三方分享您的個人信息；
          <br />
          (c)
          根據法律的有關規定，或者行政或司法機構的要求，向第三方或者行政、司法機構披露；
          <br />
          (d)
          如您出現違反中國有關法律、法規或者本應用服務協議或相關規則的情況，需要向第三方披露；
          <br />
          (e)
          如您是適格的知識產權投訴人並已提起投訴，應被投訴人要求，向被投訴人披露，以便雙方處理可能的權利糾紛；
          <br />
          (f)
          在本應用平台上創建的某一交易中，如交易任何一方履行或部分履行了交易義務並提出信息披露請求的，本應用有權決定向該用戶提供其交易對方的聯絡方式等必要信息，以促成交易的完成或糾紛的解決。
          <br />
          (g) 其它本應用根據法律、法規或者網站政策認為合適的披露。
          <br />
          <br />
          4. 信息存儲和交換
          <br />
          本應用收集的有關您的信息和資料將保存在本應用及（或）其關聯公司的服務器上，這些信息和資料可能傳送至您所在國家、地區或本應用收集信息和資料所在地的境外並在境外被訪問、存儲和展示。
          <br />
          <br />
          5. Cookie的使用
          <br />
          (a)
          在您未拒絕接受cookies的情況下，本應用會​​在您的計算機上設定或取用cookies
          ，以便您能登錄或使用依賴於cookies的本應用平台服務或功能。本應用使用cookies可為您提供更加周到的個性化服務，包括推廣服務。
          <br />
          (b)
          您有權選擇接受或拒絕接受cookies。您可以通過修改瀏覽器設置的方式拒絕接受cookies。但如果您選擇拒絕接受cookies，則您可能無法登錄或使用依賴於cookies的本應用網絡服務或功能。
          <br />
          (c) 通過本應用所設cookies所取得的有關信息，將適用本政策。
          <br />
          <br />
          6. 信息安全
          <br />
          (a)
          本應用帳號均有安全保護功能，請妥善保管您的用戶名及密碼信息。本應用將通過對用戶密碼進行加密等安全措施確保您的信息不丟失，不被濫用和變造。儘管有前述安全措施，但同時也請您注意在信息網絡上不存在“完善的安全措施”。
          <br />
          6. 信息安全
          <br />
          (a)
          本應用帳號均有安全保護功能，請妥善保管您的用戶名及密碼信息。本應用將通過對用戶密碼進行加密等安全措施確保您的信息不丟失，不被濫用和變造。儘管有前述安全措施，但同時也請您注意在信息網絡上不存在“完善的安全措施”。
          <br />
          (b)
          在使用本應用網絡服務進行網上交易時，您不可避免的要向交易對方或潛在的交易對方披露自己的個人信息，如聯絡方式或者郵政地址。請您妥善保護自己的個人信息，僅在必要的情形下向他人提供。如您發現自己的個人信息洩密，尤其是本應用用戶名及密碼發生洩露，請您立即聯絡本應用客服，以便本應用採取相應措施。
          <br />
          <br />
          7.本隱私政策的更改
          <br />
          (a)如果決定更改隱私政策，我們會在本政策中、本公司網站中以及我們認為適當的位置發布這些更改，以便您了解我們如何收集、使用您的個人信息，哪些人可以訪問這些信息，以及在什麼情況下我們會透露這些信息。
          <br />
          (b)本公司保留隨時修改本政策的權利，因此請經常查看。如對本政策作出重大更改，本公司會通過網站通知的形式告知。
        </div>
      ) : (
        ""
      )}
      {lang == "en" ? (
        <div className="article-content">
          <br />
          This application respects and protects the personal privacy for all
          users. In order to provide you with more accurate and personalized
          services, this application will use and disclose your personal
          information in accordance with this privacy policy. However, this
          application will treat these information with a high degree of
          diligence and prudence. In addition to what this privacy policy
          mentioned, this application will not disclose or provide these
          information to third parties without your permission. This application
          will update the privacy policy from time to time. When you agree to
          use this application service, you are considered to have agreed to the
          entire content of this privacy policy. This privacy policy is an
          integral part of this application user service agreement.
          <br />
          <br />1 Scope of Application
          <br />
          (a) When you sign up for this application, the information you provide
          according to the requirements of this application;
          <br /> (b) When you use this application network service or visit the
          application platform, the information including but not limited to
          your IP address, browser type, the language used, the date and time
          visited, the software and hardware characteristics and the webpage
          records that automatically records on your browser and computer;
          <br /> (c) The information that the application obtains from business
          partners through legal means.
          <br /> You understand and agree that the following information does
          not apply to this privacy policy:
          <br />
          <br /> (a) The keyword information you enter when using the search
          service provided by this application platform;
          <br /> (b) The relevant information and data that you publish and
          collected by this application, including but not limited to
          participation in activities, transaction information and comments;
          <br /> (c) The behavior that is illegal or violate the rules of this
          application and the measures that this application has taken against
          you.
          <br />
          <br /> 2 The Information Usage
          <br /> (a) This application will not provide, sell, rent, share or
          trade your personal information to any unrelated third party, unless
          we have obtained your permission in advance, or the third party and
          this application(including affiliate) provide you with services, and
          after the end of the service, they will be prohibited from accessing
          all these materials that they were able to.
          <br /> (b) This application does not allow any third party to collect,
          edit, sell or disseminate your personal information by any means. If
          any user of this application engages in the above activities, this
          application has the right to immediately terminate the service once
          discovered.
          <br /> (c) For the purpose of providing services, this application may
          use your personal information to provide you with information that you
          are interested in, including but not limited to sending you product
          and service information, sharing these information with partners (With
          your permission advanced).
          <br />
          <br /> 3 Information Disclosure
          <br /> In the following cases, this application will disclose your
          personal information in whole or in part according to your personal
          wishes or legal requirements:
          <br /> (a) Disclosure to third party with your permission;
          <br /> (b) To provide the products and services you request, we must
          share your personal information with third parties;
          <br /> (c) According to the law or the requirements of the
          administrative or judicial organization, we disclosure your
          information to third party or administrative or judicial organization;
          <br /> (d) If you violate laws, regulations or this application
          service agreement or related rules, we need to disclose to the third
          party;
          <br /> (e) If you are a qualified intellectual property complainant
          and have filed a complaint, we should disclose to the respondent so
          that both parties can deal with disputes;
          <br /> (f) In a transaction created on this application platform, if
          any party fulfills or partially fulfills the transaction obligation
          and makes an information disclosure request, the application has the
          right to provide the contact information to facilitate the completion
          of the transaction or the resolution of disputes.
          <br /> (g) Others which we considered is suitable to disclose
          according to laws, regulations or website policies.
          <br />
          <br /> 4 Information Storage and Exchange
          <br /> The information and data collected by this application will be
          stored on the servers of this application or its affiliated companies.
          These information and data may be sent to your country, region, or
          outside the country where the application collects information and
          data.
          <br />
          <br /> 5 Use of Cookies
          <br /> (a) If you do not refuse to accept cookies, this application
          will set or access cookies on your computer so that you can log in or
          use the services and functions that rely on cookies. This application
          uses cookies to provide you with more thoughtful and personalized
          services, including promotional services.
          <br /> (b) You have the right to choose to accept or refuse cookies.
          You can refuse to accept cookies by modifying your browser settings.
          But if you choose to refuse to accept cookies, you may not be able to
          log in or use the services or functions of this application that rely
          on cookies.
          <br /> (c) The relevant information obtained through the cookies set
          in this application will be applicable to this policy.
          <br />
          <br />
          6. Information Security
          <br />
          (a) This application account has security protection function, please
          keep your user name and password information properly. This
          application will use security measures such as encrypting user
          passwords to ensure that your information is not lost, abused and
          altered. Despite the aforementioned security measures, please note
          that there is no "perfect security measure" on the information
          network.
          <br />
          (b) When using this application network service to conduct online
          transactions, you will inevitably disclose your personal information,
          such as contact information or postal address, to the counterparty or
          potential counterparty. Please properly protect your personal
          information and only provide it to others when necessary. If you find
          that your personal information is leaked, especially the user name and
          password of this application, please contact the customer service of
          this application immediately so that this application can take
          corresponding measures.
          <br />
          <br />
          7. Changes to this privacy policy
          <br />
          (a) If you decide to change the privacy policy, we will post these
          changes in this policy, on our company’s website, and where we deem
          appropriate, so that you can understand how we collect and use your
          personal information and who can Access this information and under
          what circumstances we will disclose it.
          <br />
          (b) The company reserves the right to modify this policy at any time,
          so please check it frequently. If there is a major change to this
          policy, the company will notify it through a website notice.
        </div>
      ) : (
        ""
      )}
      {lang == "ar" ? (
        <div className="article-content">
          حترم هذا التطبيق ويحمي الخصوصية الشخصية لجميع المستخدمين. من أجل
          تزويدك بخدمات أكثر دقة وتخصص لك، سيستخدم هذا التطبيق معلوماتك الشخصية
          ويكشف عنها وفقًا لسياسة الخصوصية هذه. ومع ذلك، سيعالج هذا التطبيق هذه
          المعلومات بدرجة عالية من الاجتهاد والحصافة. بالإضافة إلى ما ذكرته
          سياسة الخصوصية هذه، فإن هذا التطبيق لن يكشف أو يقدم هذه المعلومات
          لأطراف ثالثة دون إذنك. سيقوم هذا التطبيق بتحديث سياسة الخصوصية من وقت
          إلى آخر. عندما توافق على استخدام خدمة التطبيق هذه، فأنت تعتبر موافقًا
          على المحتوى الكامل لسياسة الخصوصية هذه. تعتبر سياسة الخصوصية هذه جزءًا
          لا يتجزأ من اتفاقية خدمة مستخدم التطبيق.
          <br />
          ١) نطاق التطبيق:
          <br />
          <br />
          (أ) عند التسجيل للحصول على هذا التطبيق ،المعلومات المقدمة وفقًا
          لمتطلبات هذا التطبيق؛ (ب) عند استخدام خدمة شبكة التطبيق هذه أو زيارة
          النظام الأساسي للتطبيق ، تتضمن المعلومات على سبيل المثال عنوان IP
          الخاص بك ونوع المتصفح واللغة المستخدمة وتاريخ ووقت الزيارة وخصائص
          البرامج والأجهزة وسجلات صفحة الويب التي يسجل تلقائيًا على المتصفح
          والكمبيوتر ؛ (ج) المعلومات التي يحصل عليها التطبيق من شركاء الأعمال من
          خلال الوسائل القانونية.
          <br />
          أنت تدرك وتوافق على أن المعلومات التالية لا تنطبق على سياسة الخصوصية
          هذه:
          <br />
          (أ) معلومات الكلمات الرئيسية التي تدخلها عند استخدام خدمة البحث التي
          توفرها منصة التطبيق هذه؛ (ب) المعلومات والبيانات ذات الصلة التي تنشرها
          وتجمعها بواسطة هذا التطبيق، بما في ذلك على سبيل المثال المشاركة في
          الأنشطة ومعلومات المعاملات والتعليقات؛ (ج) السلوك غير القانوني أو
          المخالف لقواعد هذا التطبيق والتدابير التي اتخذها هذا التطبيق ضدك.
          <br />
          ٢) استخدام المعلومات:
          <br />
          <br />
          (أ) لن يوفر هذا التطبيق معلوماتك الشخصية أو يبيعها أو يؤجرها أو
          يشاركها أو يتاجر بها مع أي طرف ثالث غير ذي صلة، إلا إذا حصلنا على إذن
          منك مسبقًا، أو إذا كان الطرف الثالث وهذا التطبيق (بما في ذلك الشركة
          التابعة) يوفر لك الخدمات، وبعد انتهاء الخدمة، سيتم منع الأطراف الثالثة
          من الوصول إلى كل هذه المواد التي تمكنت من الوصول إليها سابقاً. (ب) لا
          يسمح هذا التطبيق لأي طرف ثالث بجمع أو تعديل أو بيع أو نشر معلوماتك
          الشخصية بأي وسيلة. إذا شارك أي مستخدم لهذا التطبيق في الأنشطة المذكورة
          أعلاه، فإن هذا التطبيق له الحق في إنهاء الخدمة فور اكتشاف المخالفة.
          (ج) لأجل تقديم الخدمات بشكل أفضل، قد يستخدم هذا التطبيق معلوماتك
          الشخصية لتزويدك بالمعلومات التي تهمك، بما في ذلك على سبيل المثال إرسال
          معلومات عن منتج أو خدمة إليك، ومشاركة هذه المعلومات مع الشركاء (بإذن
          منك فقط).
          <br />
          ٣) إفشاء المعلومات:
          <br />
          <br />
          في الحالات التالية، سيكشف التطبيق عن معلوماتك الشخصية كليًا أو جزئيًا
          وفقًا لرغباتك الشخصية أو المتطلبات القانونية:
          <br />
          (أ) الإفصاح لطرف ثالث بإذن منك؛ (ب) لتوفير المنتجات والخدمات التي
          تطلبها، يجب أن نشارك معلوماتك الشخصية مع أطراف ثالثة؛ (ج) وفقًا
          للقانون أو متطلبات المنظمة الإدارية أو القضائية، نقوم بالكشف عن
          معلوماتك لطرف ثالث أو منظمة إدارية أو قضائية؛ (د) إذا انتهكت القوانين
          أو اللوائح أو اتفاقية خدمة التطبيق هذه أو القواعد ذات الصلة، فنحن
          بحاجة إلى الكشف عنها للطرف الثالث؛ (هـ) إذا كنت مؤهل لتقديم شكوى بشأن
          الملكية الفكرية وقدمت هذه الشكوى، فيجب أن نكشف للمدعى عليه حتى يتمكن
          كلا الطرفين من التعامل مع النزاعات؛ (و) في المعاملة التي تم إنشاؤها
          على منصة التطبيق هذه، إذا قام أي طرف بإنجاز أو إنجاز المعاملة جزئياً
          وتقديم طلب الإفصاح عن المعلومات، يحق للتطبيق تقديم معلومات الاتصال
          لتسهيل إكمال المعاملة أو حل النزاعات. (ز) معلومات أخرى نعتبرها مناسبة
          للإفصاح عنها وفقًا للقوانين أو اللوائح أو سياسات مواقع الويب.
          <br />
          ٤) تخزين المعلومات وتبادلها
          <br />
          <br />
          سيتم تخزين المعلومات والبيانات التي تم جمعها بواسطة هذا التطبيق على
          خوادم هذا التطبيق أو الشركات التابعة له. قد يتم إرسال هذه المعلومات
          والبيانات إلى بلدك أو منطقتك أو خارج البلد الذي يجمع فيه التطبيق
          المعلومات والبيانات.
          <br />
          ٥) استخدام ملفات تعريف الارتباط:
          <br />
          <br />
          (أ) إذا لم ترفض قبول ملفات تعريف الارتباط ، فسيقوم هذا التطبيق بتعيين
          أو الوصول إلى ملفات تعريف الارتباط على جهاز الكمبيوتر الخاص بك حتى
          تتمكن من تسجيل الدخول أو استخدام الخدمات والوظائف التي تعتمد على ملفات
          تعريف الارتباط. يستخدم هذا التطبيق ملفات تعريف الارتباط لتزويدك بخدمات
          أكثر تفكيرًا وتخصصاً لك، بما في ذلك الخدمات الترويجية. (ب) لديك الحق
          في اختيار قبول أو رفض ملفات تعريف الارتباط. يمكنك رفض قبول ملفات تعريف
          الارتباط عن طريق تعديل إعدادات متصفحك. ولكن إذا اخترت رفض قبول ملفات
          تعريف الارتباط، فقد لا تتمكن من تسجيل الدخول أو استخدام خدمات أو وظائف
          هذا التطبيق التي تعتمد على ملفات تعريف الارتباط. (ج) ستكون المعلومات
          المناسبة التي تم الحصول عليها من خلال ملفات تعريف الارتباط المحددة في
          هذا التطبيق قابلة للتطبيق على هذه السياسة.
          <br />
          ٦) أمن المعلومات:
          <br />
          <br />
          (أ) يحتوي حساب التطبيق هذا على وظيفة حماية أمنية، يرجى الاحتفاظ
          بمعلومات اسم المستخدم وكلمة المرور بشكل صحيح. سيضمن هذا التطبيق عدم
          فقد معلوماتك أو إساءة استخدامها أو تغييرها عن طريق تشفير كلمات مرور
          المستخدم وإجراءات الأمان الأخرى. على الرغم من الإجراءات الأمنية، يرجى
          ملاحظة أنه لا يوجد "إجراء أمني مثالي" على شبكة المعلومات. (ب) عند
          استخدام هذا التطبيق للتداول عبر الإنترنت، سيتعين عليك حتماً الاتصال
          بالأطراف المقابلة وبالتالي الكشف عن معلوماتك الشخصية. يجب أن تعلم أن
          هذا نوع من المخاطر لا يمكن الحماية منه من خلال هذا التطبيق.
          <br />
          ٧) تغييرات على سياسة الخصوصية هذه:
          <br />
          (أ) إذا قررنا تغيير سياسة الخصوصية ، فسننشر التغييرات في هذه السياسة
          على موقع شركتنا على الويب وفي الأماكن التي نعتقد أنها مناسبة حتى تتمكن
          من فهم كيفية جمعنا لمعلوماتك الشخصية واستخدامها ومن يمكنه الوصول إلى
          هذه المعلومات و تحت أي ظروف قد نكشف عن هذه المعلومات. (ب) تحتفظ الشركة
          بالحق في تعديل هذه السياسة في أي وقت، لذا يرجى التحقق منها بشكل متكرر.
          إذا كان هناك تغيير كبير في هذه السياسة، فستقوم الشركة بإبلاغها من خلال
          إشعار على موقع الويب.
          <br />
        </div>
      ) : (
        ""
      )}
      {lang == "ko" ? (
        <div className="article-content">
          <br />본 애플리케이션은 모든 이용자의 개인정보를 중시하고 보호하고
          있습니다. 이용자에게 더욱 정확하고 개성화된 서비스를 제공하기 위하여
          개인정보 보호 정책 규정에 따라 회원의 개인정보를 사용하고 공개할
          것입니다. 본 애플리케이션은 이용자의 정보를 대해 고도의 근면과 신중한
          의무로 처리됩니다. 본 개인정보 보호 정책에 별도의 규정이 있는 경우를
          제외하고 TopCreditBank(이하 '회사')는 이용자의 사전 동의 없이 이러한
          정보를 외부에 공개하거나 제3자에게 제공하지 않습니다. 본
          애플리케이션은 회사 정책의 변화에 따라 수시로 변경될 수 있습니다.
          귀하가 본 애플리케이션 서비스 이용 약관에 동의할 때, 귀하는 본
          개인정보 보호 정책의 모든 내용에 동의한 것으로 간주합니다. 본 개인정보
          보호 정책은 본 애플리케이션 서비스 사용 약관의 필수적인 부분입니다.
          <br />
          <br />
          1. 적용 범위 :
          <br />
          (a) 본 애플리케이션에 회원 가입할 때, 회사에서 수집하는 회원가입
          필요한 개인정보 항복;
          <br />
          (b) 본 애플리케이션의 인터넷 서비스를 이용하거나 본 플랫폼 웹 페이지를
          방문할 때, 이 애플리케이션은 자동으로 웹 브라우저와 컴퓨터의 정보를
          받아 기록합니다. IP 주소, 브라우저의 종류, 사용 언어, 접근 날짜와
          시간, 소프트웨어와 하드웨어의 특징 정보 및 회원은 원하는 웹 페이지
          기록 등이 예에 한하지 않은 데이터를 포함 합니다.
          <br />
          (c) 본 애플리케이션이 비즈니스 파트너로부터 합법적으로 취득한 이용자
          개인 정보.
          <br />
          귀하가 아래 정보를 본 개인정보 보호 정책은 적용되지 않은 것을 알고
          있으며 동의합니다.
          <br />
          (a) 귀하가 이 애플리케이션 플랫폼에서 제공하는 검색 서비스를 사용할 때
          입력한 키워드 정보;
          <br />
          (b) 이 애플리케이션은 수집한 정보는 해당 앱에 게시된 관련 정보 데이터,
          참여 활동, 거래 정보 및 평가명세 등이 예에 한하지 않은 포함합니다..
          <br />
          (c) 법률 규정을 위반하거나 본 애플리케이션의 규칙을 위반하는 행위 및
          본 애플리케이션이 이미 귀하에게 취한 조치.
          <br />
          2. 정보 이용
          <br />
          (a) 본 애플리케이션은 귀하의 개인 정보를 제3자에게 제공, 판매, 대여,
          공유 또는 거래하지 않습니다. 사전에 귀하의 허가를 받은 경우만
          제공됩니다. 또는 해당 제3자가 본 애플리케이션(앱 관계회사 포함)과
          단독으로 또는 공동으로 서비스를 제공하며, 이 서비스가 종료된 후에는
          이전에 정보를 포함하여 접근이 금지됩니다.
          <br />
          (b) 본 애플리케이션은 그 어떤 제3자가 귀하의 개인정보를 수집, 편집,
          판매 또는 무상으로 제공하는 행위도 허용하지 않습니다. 모든
          애플리케이션에 이용자는 이러한 활동에 종사하고 플랫폼은 발견하면 해당
          이용자와의 서비스 계약을 즉시 해지할 권리가 있습니다.
          <br />
          (c) 이용자들의 서비스 제공 목적으로 본 애플리케이션을 통해 귀하의
          개인정보를 이용함으로써 귀하에게 관심 있는 정보를 제공할 수 있습니다.
          제품 및 서비스에 대한 정보를 보내거나 회사의 제휴 파트너와 정보를
          공유하여 귀하에게 관련 제품 및 서비스에 대한 정보를 보낼 수 있습니다.
          (후자는 귀하의 사전 동의 필요).
          <br />
          3. 정보공유
          <br />
          다음과 같은 경우, 본 애플리케이션은 귀하의 개인적인 의지 또는 법률에
          따라 귀하의 개인 정보의 전부 또는 일부를 공개합니다.
          <br />
          (a) 귀하의 사전 동의받고 제3자에게 공유;
          <br />
          (b) 귀하가 요청한 제품 및 서비스를 제공하기 위해 귀하의 개인 정보를
          제3자와 공유해야 합니다.
          <br />
          (c) 법률의 관련 규정 또는 행정 또는 사법 기관의 요구에 따라 제3자 또는
          행정사법 기관에 공유합니다.
          <br />
          (d) 중국 관련 법률, 법규 또는 본 애플리케이션 서비스 약관 또는 관련
          규칙을 위반할 경우 제3자에게 공개해야 합니다.
          <br />
          (e) 만일 귀하가 지식재산권에 적합한 고소인이며 고소를 제기하였을 경우,
          피고소인의 요구에 따라 피고소인에게 공유하여 쌍방이 가능한 권리 분쟁을
          처리할 수 있도록 해야 합니다.
          <br />
          (f) 본 애플리케이션에 개설된 한 거래 중 거래상대방이 거래의 의무를
          이행하거나 부분적으로 의무를 이행하며 정보공유청구를 한 경우에는
          거래상대방의 연락처 등 필요한 정보를 해당 사용자에게 제공하여 거래의
          원활 진행 분쟁의 해결을 도모할 수 있는 권리를 가지고 있습니다.
          <br />
          (g) 기타 본 애플리케이션은 법률, 규정 또는 웹사이트 정책에 따라
          적합하다고 판단되는 공유이다.
          <br />
          4. 정보 저장 및 교환
          <br />본 애플리케이션에서 수집한 귀하의 정보와 자료는 해당
          애플리케이션 및 (또는) 해당 회사의 서버에 저장되며, 이런 정보와 자료는
          귀하가 소속 국가, 지역 또는 본 애플리케이션에서 수집한 정보 및 자료의
          소재지 국외에 전송되어 국외에서 방문, 저장, 전시될 수 있습니다.
          <br />
          5. 쿠키의 사용
          <br />
          (a) 귀하가 쿠키를 거부되지 않은 상태에서 해당 애플리케이션이 쿠키에
          연결된 응용 플랫폼 서비스 또는 기능 이용 또는 로그인하거나 사용할 수
          있도록 컴퓨터에서 쿠키를 설정 또는 사용합니다. 본 애플리케이션은
          쿠키를 이용하면 더욱 상세한 개성화 서비스를 제공할 수 있습니다.
          프로모션 서비스를 포함합니다.
          <br />
          (b) 귀하는 쿠키를 이용 동의 하거 거부할 권리가 있습니다. 브라우저 설정
          통하여 쿠키 거부를 설정할 수 있습니다. 그러나 쿠키를 거부하기로 선택한
          경우 쿠키에 의존하는 본 애플리케이션이나 웹 서비스나 해당 기능 이용
          또는 로그인하거나 사용하지 못할 수 있습니다.
          <br />
          (c) 본 애플리케이션의 쿠키를 통하여 취득한 관련 정보는 본 정책이
          적용됩니다.
          <br />
          6. 정보 보안
          <br />
          (a) 본 애플리케이션 계정에는 보안 기능이 있으며 이용자 ID과 비밀번호
          정보를 안전하게 보관하십시오. 본 애플리케이션은 이용자의 비밀번호를
          암호화하는 등의 보안 조치를 통해 귀하의 정보가 남용, 변조되지 않도록
          할 것입니다. 비록 전술한 안전장치가 있지만, 정보 네트워크상에 '완전한
          보안장치'가 존재하지 않는다는 점에 유의해 주십시오.
          <br />
          (b) 본 어플리케이션을 이용하여 인터넷 거래를 할 때, 귀하가 거래
          상대방이나 잠재적인 거래 상대방에게 연락처나 우편 주소 등 자신의 개인
          정보를 공개하는 것을 피할 수 없습니다. 귀하의 개인 정보를 꼭 필요한
          경우에만 타인에게 제공하십시오. 고객정보 누설, 특히 애플리케이션 계정
          및 비밀번호가 누출한 것을 발견한 경우, 본 애플리케이션에 고객 서비스를
          즉시 연락하여 관련 조처를 하시기 바랍니다.
          <br />
          7. 본 개인정보 보호 정책의 변경
          <br />
          (a) 만약 개인정보 보호 정책을 변경하기로 하신다면, 귀하의 개인 정보를
          수집, 사용 및 사용하는 방법, 해당 정보에 방문할 수 있는 대상 및 해당
          정보를 어떤 상황에 공유하는지 등 정보를 본 정책, 본사 웹사이트 및
          회사가 적절하다고 생각하는 장소에 이러한 변경 사항을 게시할 것입니다.
          <br />
          (b) 회사는 언제든지 본 정책을 수정할 수 있는 권리를 보유하므로 자주
          확인하시기 바랍니다. 본 정책에 중대한 변경이 있는 경우, 당사는
          웹사이트 공지를 통해 공고합니다.
        </div>
      ) : (
        ""
      )}
      {lang == "ja" ? (
        <div className="article-content">
          <br />
          本アプリは、本サービスを利用するすべてのユーザーの個人のプライバシーを尊重し、保護します。
          本アプリは、より正確でパーソナライズされたサービスを提供するために、本プライバシーポリシーに定められているように、お客様の個人情報を使用し、開示します。
          ただし、このような情報を高度な注意義務をもって取り扱います。
          本プライバシーポリシーに別段の定めがある場合を除き、本アプリは、お客様の事前の許可なく、そのような情報を外部に開示したり、第三者に提供したりすることはありません。
          本アプリケーションは、本プライバシーポリシーを随時更新することがあります。アプリケーションサービス利用契約に同意することにより、お客様は本プライバシーポリシーの全体に同意したものとみなされます。
          本プライバシーポリシーは、本アプリケーションサービス利用契約の重要な一部です。
          <br />
          <br />
          1.適用範囲
          <br />
          (a)
          お客様が本アプリ上でアカウント登録を行う際に、本アプリの要求に応じて提供する個人登録情報。
          <br />
          (b)
          お客様が本アプリのウェブサービスを利用する際、または本アプリのプラットフォームのウェブページを閲覧する際に、本アプリが自動的に受信し記録するお客様のブラウザおよびコンピュータ上の情報で、お客様のIPアドレス、ブラウザの種類、使用言語、アクセスの日時、ハードウェアおよびソフトウェアの特性に関する情報、お客様がリクエストしたウェブページの記録などが含まれますが、これらに限定されません。
          <br />
          (c) 合法的な手段により本アプリが提携先から取得した個人ユーザーデータ
          <br />
          <br />
          お客様は、以下の情報が本プライバシーポリシーの対象外であることを理解し、同意するものとします。
          <br />
          (a)
          アプリケーションプラットフォームが提供する検索サービスを利用する際に入力するキーワード情報。
          <br />
          (b)
          イベントへの参加、取引情報、レーティングの詳細など、本アプリに投稿した情報について本アプリが収集したデータ。
          <br />
          (c)
          法令に違反した場合、または本アプリの規約に違反した場合、および本アプリがお客様に対して行った措置。
          <br />
          <br />
          2.情報の利用
          <br />
          (a)
          本アプリは、お客様の事前の許可を得た場合を除き、お客様の個人情報を無関係の第三者に提供、販売、貸与、共有または取引することはありません。また、当該第三者と本アプリ（本アプリの関連会社を含む）が、個別または共同でお客様にサービスを提供している場合、当該サービス終了後は、それまでアクセスしていた全ての情報を含む、全ての情報へのアクセスを拒否します。
          <br />
          (b)
          また、本アプリは、第三者がお客様の個人情報を無償で収集、集計、販売、配布することを許可していません。
          このような活動を行った本アプリケーションのプラットフォームのユーザーは、発見次第、当該ユーザーとのサービス契約を直ちに終了する権利を有するものとします。
          <br />
          (c)
          本アプリは、ユーザーにサービスを提供する目的で、ユーザーに興味のある情報を提供するために、ユーザーの個人情報を使用することがあります。これには、ユーザーに製品やサービスに関する情報を送信することや、本アプリのパートナーと情報を共有して、パートナーの製品やサービスに関する情報を送信することが含まれますが、これに限定されません（後者はユーザーの事前の同意が必要です）。
          <br />
          3.情報公開
          <br />
          本アプリは、ご本人の希望に応じて、または法令に基づく場合には、個人情報の全部または一部を開示します。
          <br />
          (a) お客様の事前の同意を得た第三者への提供
          <br />
          (b)
          お客様が希望された商品やサービスを提供するために、お客様の個人情報を第三者と共有する必要があります。
          (ウ)
          法令の関連規定に基づき、または行政機関若しくは司法機関から求められた場合に、第三者もしくは行政機関若しくは司法機関に提供すること。
          <br />
          (d)
          中国の関連法規または本アプリケーションサービス契約または関連規則に違反した場合に第三者への開示を求められた場合。
          <br />
          (e)
          知的財産権の申立人として適格であり、苦情を申し立てた場合には、当事者が権利をめぐる紛争の可能性に対処するために、被申立人の要請により、被申立人への開示が必要となります。
          <br />
          (f)
          本アプリケーションプラットフォーム上で作成された取引において、取引の当事者が取引上の義務を履行または一部履行し、情報開示の請求を行った場合、本アプリケーションは、取引の完了または紛争の解決を容易にするために、取引の相手方の連絡先等の必要な情報を当該利用者に提供することを決定する権利を留保します。
          <br />
          (g)
          その他、法令またはウェブサイトのポリシーに基づき、本アプリが適切と判断した開示。
          <br />
          4.情報の保存と交換
          <br />
          本アプリがお客様について収集した情報およびデータは、本アプリおよび/またはその関連会社のサーバーに保存され、本アプリが情報およびデータを収集するお客様の国、地域または場所以外の場所に転送され、アクセスされ、保存され、表示される場合があります。
          <br />
          <br />
          5.クッキーの使用
          <br />
          (a)
          本アプリケーションは、お客様がクッキーの受け入れを拒否しない限り、お客様がログインしたり、クッキーに依存するアプリケーションプラットフォームのサービスや機能を利用できるようにするために、お客様のコンピュータにクッキーを設定または取得することがあります。
          本アプリケーションでは、プロモーションサービスを含む、よりパーソナライズされたサービスを提供するためにクッキーを使用しています。
          <br />
          (b)
          お客様は、クッキーの受け入れまたは拒否を選択する権利を有しており、ブラウザの設定を変更することでクッキーを拒否することができますが、クッキーを拒否することを選択した場合、本アプリのウェブサービスまたはクッキーに依存する機能にログインしたり、使用することができなくなる場合があります。
          <br />
          (c)
          本ポリシーは、本アプリが設定したクッキーを介して取得した情報に適用されます。
          <br />
          <br />
          6.情報セキュリティ
          <br />
          (a)
          本アプリ上のアカウントはセキュリティ機能により保護されていますので、ユーザー名とパスワードの情報は安全に管理してください。
          本アプリケーションは、パスワードの暗号化などのセキュリティ対策により、お客様の情報が紛失、誤用、改ざんされないようにします。
          前述のセキュリティ対策とは関係なく、情報ネットワーク上には「完璧なセキュリティ対策」は存在しないので注意が必要です。
          <br />
          (b)
          アプリケーションWebサービスを利用してオンライン取引を行う場合、必然的に取引相手または取引相手となる可能性のある相手に情報を提供しなければなりません。
          <br />
          <br />
          7.本プライバシーポリシーの変更
          <br />
          (a)
          当社がプライバシーポリシーを変更することを決定した場合、当社が個人情報をどのように収集し、使用するか、誰がその情報にアクセスできるか、どのような状況下で当社がその情報を開示する可能性があるかをお客様に知っていただくために、本ポリシー、当社のウェブサイト、および当社が適切と判断した場所に変更内容を掲載します。
          イ）当社は、本ポリシーを随時変更することがありますので、こまめにご確認ください。
          本ポリシーを大幅に変更する場合は、ウェブサイト上で告知する方法でお知らせします。
          <br />
          (c) 連絡先や住所などの個人情報を開示します。
          お客様の個人情報を保護し、必要な場合にのみ他者に提供してください。
          お客様の個人情報、特にアプリケーションのユーザー名とパスワードが漏洩していることが判明した場合は、直ちに当社のカスタマーサービスまでご連絡ください。適切な対応をさせていただきます。
        </div>
      ) : (
        ""
      )}*/}
    </div>
  );
}
