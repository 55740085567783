import React from "react";
import "./text-panel.scss";
import bg from "@/assets/img/financial-detail/tro-bg.png"
export default function (props) {
  const { productInfo } = props;
  return (
    <div className="component-text-panel">
      <img className="right-bg" src={bg} alt=""/>
      {
        productInfo.product_type == 1 ?
        <React.Fragment>
          <div className="title">{window.intl.get("汇信定期存款")}</div>
          <div className="text">{window.intl.get("定期存款是汇信为用户提供的数字货币和法币的资产增值理财计划，用户存入后，即可享受理财期限固定、到期自动回款的理财便利服务。汇信承诺本金和收益安全。")}</div>
        </React.Fragment>
        : ""
      }
      {
        productInfo.product_type == 2 ?
        <React.Fragment>
          <div className="title">{window.intl.get("汇信活期存款")}</div>
          <div className="text">{window.intl.get("活期存款是汇信为用户提供的数字货币和法币的资产增值理财计划，用户存入后，即可享受资金快进快出、存取灵活的便利服务。汇信承诺在满足本金安全和流动性需求的基础上力求实现更高的收益。")}</div>
        </React.Fragment>
        : ""
      }
    </div>
  )
}