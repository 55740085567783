import React, { useState } from "react";
import Stock from "./stock/stock-ueasy.jsx";
import Digital from "./digital/digita-ueasy";
import "./ueasy-compliance.scss";

export default function () {
  const tabList = [
    {
      value: 1,
      label: window.intl.get("公司简介"),
    },
    {
      value: 2,
      label: window.intl.get("平台优势"),
    },
  ];
  const [tabIndex, setTabIndex] = useState(() => {
    const currTab = localStorage.getItem("operational-tab-ueasy") || 1;
    return currTab;
  });
  const onTabChange = val => {
    setTabIndex(val);
    localStorage.setItem("operational-tab-ueasy", val);
  };

  return (
    <div className="ueasy-compliance">
      <div className="business-header">
        <div className="herder-title">
          <div className="title">{window.intl.get("优易银行经营合规")}</div>
        </div>
        <div className="tab-wrap">
          <ul>
            {tabList.map(item => {
              return (
                <li
                  key={item.value}
                  className={tabIndex == item.value ? "active" : ""}
                  onClick={() => {
                    onTabChange(item.value);
                  }}
                >
                  {item.label}
                  <i></i>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {tabIndex == 1 ? <Stock /> : ""}
      {tabIndex == 2 ? <Digital /> : ""}
    </div>
  );
}
