// const { VUE_APP_ENV } = process.env;
const Base64 = require("js-base64").Base64;

let REACT_APP_PRODUCT_API = "";
let REACT_APP_API = "";
let REACT_APP_ID = "";
let REACT_APP_KEY = "";
let REACT_CHANNEL_ID = "";
// let VUE_APP_ENV = "";

let web_app_info = [];
let web_appuc = "";
let web_app = "";
let channel_id = "";

let configHost = "";

function apiGetEnv() {
  let tempConfigHost = window.location.host;

  if (tempConfigHost.includes("localhost")) {
    // configHost = "https://exchange.hxexchge.com"; //开发环境
    configHost = "https://exchange.hxexchge.com"; // 测试环境
  } else {
    configHost = window.location.origin;
  }

  let data = {};
  let status = 0;
  window.$.ajax({
    type: "POST", //请求方式
    async: false, // fasle表示同步请求，true表示异步请求
    contentType: "application/json;charset=UTF-8", //请求的媒体类型
    url: `${configHost}/Site/config`, //请求地址
    data: {}, //数据，json字符串
    success: function (result) {
      //请求成功
      data = result.data;
      status = result.status;
    },
    error: function (e) {
      //请求失败，包含具体的错误信息
    },
  });
  return { status, data };
}
getEnv();

function getEnv() {
  // apiGetEnv();
  const { status, data } = apiGetEnv();
  if (status == 200) {
    localStorage.setItem("web-app-inner-info", JSON.stringify(data.app_info));
    localStorage.setItem("web-inner-env", data.environment);
    localStorage.setItem("web-inner-app", data.app);
    localStorage.setItem("web-inner-appuc", data.appuc);
    localStorage.setItem("web-inner-downUrl", data.qrcode_download.ori_url);

    web_app_info = data.app_info;
    web_appuc = data.appuc;
    web_app = data.app;
    channel_id = data.channel_id;
  }
}

let tempInfo = web_app_info[0].slice(2) + web_app_info[1];

let webAppIdKeyInfo = JSON.parse(Base64.decode(tempInfo));

localStorage.setItem("web-id", webAppIdKeyInfo.app_id);

REACT_APP_PRODUCT_API = web_appuc;
REACT_APP_API = web_app;
REACT_CHANNEL_ID = channel_id;
REACT_APP_ID = webAppIdKeyInfo.app_id;
REACT_APP_KEY = webAppIdKeyInfo.app_key;

export {
  REACT_APP_PRODUCT_API,
  REACT_APP_API,
  REACT_APP_ID,
  REACT_APP_KEY,
  REACT_CHANNEL_ID,
};
