import React, { useState } from "react";
import Stock from "./stock/stock.jsx";
import Digital from "./digital/digital.jsx";
import "./operational-compliance.scss";
// const Problem = React.lazy(() => import("./problem/problem.jsx"));


export default function () {
  const tabList = [
    {
      value: 1,
      label: window.intl.get('股票')
    },
    {
      value: 2,
      label: window.intl.get('数字货币')
    }
  ];
  const [tabIndex, setTabIndex] = useState(() => {
    const currTab = localStorage.getItem('operational-compliance-tab-pos') || 1;
    return currTab;
  });
  const onTabChange = val => {
    setTabIndex(val);
    localStorage.setItem('operational-compliance-tab-pos', val)
  }

  return (
    <div className="operational-compliance">
      <div className="business-header">
          <div className="herder-title">
            <div className="title">
                汇信银行经营合规 
            </div>
          </div>
        <div className="tab-wrap">
        <ul>
          {
            tabList.map(item => {
              return (
                <li
                  key={item.value}
                  className={tabIndex==item.value?'active':''}
                  onClick={() => {onTabChange(item.value)}}
                >
                  {item.label}
                  <i></i>
                </li>
              )
            })
          }
        </ul>
      </div>
      </div>
  
    {
      tabIndex==1?<Stock />:''
    }
    {
      tabIndex==2?<Digital />:''
    }
    
  </div>

  )
}