import { REACT_APP_ID } from "./stationSetting";
const getSign = require("./getSign");

// const { REACT_APP_ID } = process.env;
// import { REACT_APP_ID } from "@/assets/js/stationSetting";
const app_id = REACT_APP_ID;
// const lang = window.APP_CONFIG.LANG;
export default async option => {
  console.log(option);
  // option.data.lang = lang;
  option.data.app_id = app_id;
  const token = window.$.cookie("u_token") || "";
  const lang = window.$.cookie("lang") || "";
  option.data.lang = lang;
  let req = {
    token,
    ...option.data,
  };
  req._CDCODE = getSign(req, app_id);
  let result = "";
  const headers = {};
  // 上传图片，添加upload属性
  if (option.upload) {
    const formData = new FormData();
    for (const field in req) {
      formData.append(field, req[field]);
    }
    result = formData;
  } else {
    headers["content-type"] = "application/x-www-form-urlencoded";
    const arr = [];
    for (const field in req) {
      arr.push(field + "=" + req[field]);
    }
    result = arr.join("&");
  }
  return (function p() {
    return new Promise(function (resolve, reject) {
      window.$.ajax({
        dataType: option.dataType || "text",
        type: option.type || "POST",
        url: option.url,
        data: result || "",
        headers,
        processData: false,
        contentType: false,
        success: function (res) {
          if (typeof res == "string") {
            res = JSON.parse(res);
            if (res.status == -105 || res.status == -108) {
              window.location.href = "/login";
            }
          }
          resolve(res);
        },
        error: function (res) {
          if (typeof res == "string") {
            res = JSON.parse(res);
          }
          reject(res);
          if (option.retry) {
            setTimeout(() => {
              p();
            }, 5000);
          }
        },
      });
    });
  })();
};
