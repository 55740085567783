import React from "react";
import "./ico-introduction.scss";
export default function () {
  // const lang = window.intl.options.currentLocale;
  return (
    <div className="ico-introduction">
      <div className="placeholder-v3"></div>
      <div className="sec">
        <div className="common-component-btn">
          <div className="title-content">
            <span className="ico-text">一、 {window.intl.get("项目简介")}</span>
          </div>
        </div>
        <div className="sub-title">1、{window.intl.get("简介")}</div>
        <div className="sub-title V2">
          {window.intl.get(
            "O3 Swap是由O3 Labs团队构建的专有跨链聚合协议。O3 Swap的使命是为消费者提供基于加密货币的金融服务，使他们能够在O3钱包内交易或兑换各种数字资产。这种设计的好处可以归因于分散的资产存储和高水平的安全性。该平台还提供了“跨链”互换来进行交易结算，而无需考虑典型的隔离式区块链网络的局限性。"
          )}
        </div>
        <div className="sub-title V3">
          {window.intl.get(
            "术语“跨链”本身源于以下事实:交换是在两个或多个单独的区块链网络之间执 行的。通过跨链交换，初始资产和目标资产被部署在两个隔离的区块链上，否 则它们是不可通信的。鉴于去中心化金融协议(DeFi)的先进发展以及借贷， 交换，衍生产品等市场的日趋成熟，O3 Swap协议与其相关的去中心化钱包软 件合作，提供了一站式的汇总和交换平台，并为开发人员提供:访问开放、分 布式、无限、安全的交易环境权限。"
          )}
        </div>
        <div className="sub-title V3">
          {window.intl.get(
            "在最近的公告中，O3 Labs还宣布从数个著名基金获得200万美元的战略投 资。其中包括:NGC Ventures，OKEx Blockdream Ventures等"
          )}
        </div>

        <div className="sub-title">2、{window.intl.get("简介")}</div>
        <div className="sub-title V2">
          {window.intl.get(
            "O3 Swap是由O3 Labs团队构建的专有跨链聚合协议。O3 Swap的使命是为消费者提供基于加密货币的金融服务，使他们能够在O3钱包内交易或兑换各种数字资产。这种设计的好处可以归因于分散的资产存储和高水平的安全性。该平台还提供了“跨链”互换来进行交易结算，而无需考虑典型的隔离式区块链网络的局限性。"
          )}
        </div>
        <div className="sub-title">3、{window.intl.get("特点")}</div>
        <div className="info-content">
          <p className="first-item">
            1)
            {window.intl.get(
              "更隐私，更便捷：在任何环境中，只要有智能手机和互联网连接，都可以访问O3Swap获得多样的金融服务，无需其他繁琐的流程。"
            )}
          </p>
          <p>
            2)
            {window.intl.get(
              "流动性汇总：用户可以以最低的费率和最有效的交易路线交换资产--通过连接自己的去中心化钱包来实现。"
            )}
          </p>
          <p>
            3)
            {window.intl.get(
              "跨链交换：O3使用聚合协议将所有成熟且可能的跨链解决方案实施到市场，以实现跨链交易。用户一键即可自由交换多链资产。"
            )}
          </p>
          <p>
            4)
            {window.intl.get(
              "社区驱动：基于O3交换令牌的发行和经济模型，未来将实现分散治理和社区驱动的发展。"
            )}
          </p>
          <p>
            5) {window.intl.get("Layer2网络协议，降低了交易手续，加速交易。")}
          </p>
        </div>
        <div className="title-content V2">
          <span className="ico-text">二、 {window.intl.get("功能模块")}</span>
        </div>
        <div className="sub-title">1、{window.intl.get("层结构")}</div>
        <div className="info-content">
          <p className="first-item">
            1)
            {window.intl.get(
              "网络层:作为O3Swap跨链协议的基础，O3将在市场上整合高质量的公共 链和生态链系统。这包括以太坊、币安智能链BSC、Neo、火币生态链HECO 和信誉良好的Layer2项目，以提供跨链交易。"
            )}
          </p>
          <p>
            2)
            {window.intl.get(
              "流动性汇总：用户可以以最低的费率和最有效的交易路线交换资产--通过连接自己的去中心化钱包来实现。"
            )}
          </p>
          <p>
            3)
            {window.intl.get(
              "跨链交换：O3使用聚合协议将所有成熟且可能的跨链解决方案实施到市场，以实现跨链交易。用户一键即可自由交换多链资产。"
            )}
          </p>
          <p>
            4)
            {window.intl.get(
              "社区驱动：基于O3交换令牌的发行和经济模型，未来将实现分散治理和社区驱动的发展。"
            )}
          </p>
          <p>
            5) {window.intl.get("Layer2网络协议，降低了交易手续，加速交易。")}
          </p>
        </div>
        <div className="sub-title">2、{window.intl.get("O3跨链交易路线")}</div>
        <div className="license-photos">
          <div className="photo-content">
            <span className="placeholder-v1"></span>
          </div>
        </div>
      </div>
      <div className="secTwo sec">
        <div className="common-text">
          <span className="title-content">
            {window.intl.get(
              "例如，在集中式路线中，Metamask上的BNB到MDEX上的HUSD的交易路线是 一个复杂而繁琐的过程。交易者需要将Token存入中心化交易所，并通过KYC 和二步验证执行两次交易，最后将其提取到去中心化钱包和DEX。在O3去中心化路线中，我们通过聚合器和跨链池提供一站式交易体验，使交易者可以在其钱包内简单地交换多链资产。整个过程没有帐户限制，也没有权限。"
            )}
          </span>
        </div>
        <div className="sub-title">3、{window.intl.get("系统设计")}</div>
        <div className="license-photos">
          <div className="photo-content">
            <span className="placeholder-v2"></span>
          </div>
        </div>
        <div className="common-text">
          <span className="title-content">
            {window.intl.get(
              "O3交换的主要功能模块包括交换聚合器(O3聚合器)和跨链池(O3集线 器)。O3聚合器部署在主流区块链上，可帮助用户找到相应网络中最有效的交 易率和路线。O3 Hub为用户提供基于PolyNetwork的跨链交易服务，并支持用 户通过来自不同链的单个令牌增加流动性，以从跨链交易费用和O3奖励中赚取 收入。"
            )}
          </span>
        </div>
        <div className="sub-title">4、{window.intl.get("O3 Hub工作原理")}</div>
        <div className="license-photos V3">
          <div className="photo-content">
            <span className="placeholder-v3"></span>
          </div>
        </div>
        <div className="title-content V4">
          <span className="ico-text">三、 {window.intl.get("经济模型")}</span>
        </div>

        <div className="license-photos V4">
          <div className="photo-content">
            <span className="placeholder-v4"></span>
          </div>
        </div>
        <div className="title-content V4">
          <span className="ico-text">四、 {window.intl.get("O3官方信息")}</span>
        </div>
        <div className="footer-content">
          <p>
            <span className="placeholder-v3"></span>
            {window.intl.get("O3发行量：100,000,000")}
          </p>
          <span className="url"></span>
          <p>
            <span className="placeholder-v3"></span>
            {window.intl.get("O3 Swap白皮书：")}
          </p>
          <a
            className="url"
            target="_blank"
            href="https://docs.o3swap.com/o3-swap-litepaper"
          >
            https://docs.o3swap.com/o3-swap-litepaper
          </a>
          <p>
            <span className="placeholder-v3"></span>
            {window.intl.get("O3 Swap官网: ")}
          </p>
          <a className="url" target="_blank" href="https://o3swap.com/">
            https://o3swap.com/
          </a>
        </div>

        <div className="footer-contentV2"></div>
      </div>
    </div>
  );
}
