import React from "react";
import "./rate.scss";
import Step from "../step/step.jsx";
import Plan from "../plan/plan.jsx";
import { useEffect } from "react";
import { formatThousandth } from "@/utils/digital";
export default function (props) {
  const { productInfo } = props;
  const {
    status,
    period,
    amount,
    item_name,
    product_type,
    total_amount,
    annual_rate,
    lock_period,
    min_amount,
    rate_type,
    remain_end_earning_day,
  } = productInfo;
  let rate = 0;
  if (amount) {
    rate = ((total_amount / amount) * 100).toFixed(2);
  }
  const quantTagMap = {
    1: window.intl.get("谨慎型"),
    2: window.intl.get("稳健型"),
    3: window.intl.get("进取型"),
  };
  const rateTypaMap = {
    1: window.intl.get("固定收益"),
    2: window.intl.get("浮动收益"),
  };

  return (
    <div className="component-rate">
      {product_type == 1 && period == 1 && status == 1 ? (
        <div className="schedule warming">{window.intl.get("募集预热中")}</div>
      ) : (
        ""
      )}
      {product_type == 1 && status == 3 ? (
        <div className="schedule recruit">{window.intl.get("募集中")}</div>
      ) : (
        ""
      )}
      {product_type == 1 && period == 2 && status == 3 ? (
        <div className="schedule storage">{window.intl.get("随时可存")}</div>
      ) : (
        ""
      )}
      {product_type == 1 && status == 5 ? (
        <div className="schedule accruing">{window.intl.get("计息中")}</div>
      ) : (
        ""
      )}
      {product_type == 1 && status == 6 ? (
        <div className="schedule issuing">{window.intl.get("本息发放中")}</div>
      ) : (
        ""
      )}
      {product_type == 1 && status == 7 ? (
        <div className="schedule accruing">{window.intl.get("募集结束")}</div>
      ) : (
        ""
      )}

      {product_type == 2 || product_type == 3 ? (
        <div className="rate-wrap">
          <span className="rate-num">{annual_rate}%</span>
          {rate_type == 1 ? (
            <span className="rate-year-num">
              {window.intl.get("当前年化利率")}
            </span>
          ) : (
            ""
          )}
          {rate_type == 2 ? (
            <span className="rate-year-num">
              {window.intl.get("历史最高年化")}
            </span>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {product_type == 1 ? (
        <div className={`${window.intl.options.currentLocale} rate-lock-wrap`}>
          <div className="rate-area">
            <span className="rate-num">{annual_rate}%</span>
            <span className="rate-year-num">{window.intl.get("年化利率")}</span>
          </div>
          <div className="lock-area">
            <span className="rate-num">
              {lock_period}
              {window.intl.get("天")}
            </span>
            <span className="rate-year-num">{window.intl.get("锁定期")}</span>
          </div>
        </div>
      ) : (
        ""
      )}
      {product_type == 3 ? (
        <div className="expire-time-wrap">
          <span className="expire-des">{window.intl.get("产品到期")}</span>
          <span className="expire-num">
            {remain_end_earning_day}
            {window.intl.get("天")}
          </span>
        </div>
      ) : (
        ""
      )}
      {/* {
        product_type == 1 && amount != 0 ?
        <div className="total-rate">
          <div className="total-num-box">
            <span className="total-num">{window.intl.get("总额度")}：{amount} {item_name}</span>
            <span className="already-num">{window.intl.get("已抢")} 
            {
              window.intl.options.currentLocale == 'ar' ?
              ('%' + rate)
              : (rate + '%')
            }
            </span>
          </div>
          <div className="total-line-box">
            <i style={{'width': `${rate}%`}}></i>
          </div>
        </div>
        :""
      } */}
      {product_type == 2 && rate_type == 1 ? (
        <div className="tip">
          {window.intl.get("当日存入，次日起息，滚动复利，灵活存取")}
        </div>
      ) : (
        ""
      )}
      {
        // 活期，量化 并且浮动收益
        (product_type == 2 && rate_type == 2) || product_type == 3 ? (
          <div className="tip-box">
            <div className="line-item">
              <div className="pre">{window.intl.get("项目名称")}</div>
              <div className="next">{productInfo.product_name}</div>
            </div>
            {productInfo.detail.product_code ? (
              <div className="line-item">
                <div className="pre">{window.intl.get("项目代码")}</div>
                <div className="next">{productInfo.detail.product_code}</div>
              </div>
            ) : (
              ""
            )}
            {product_type != 2 ? (
              <div className="line-item">
                <div className="pre">{window.intl.get("项目类型")}</div>
                <div className="next">{quantTagMap[productInfo.quant_tag]}</div>
              </div>
            ) : (
              ""
            )}
            {
              <div className="line-item">
                <div className="pre">{window.intl.get("收益类型")}</div>
                <div className="next">{rateTypaMap[productInfo.rate_type]}</div>
              </div>
            }
            {productInfo.detail.strategy ? (
              <div className="line-item">
                <div className="pre">{window.intl.get("项目策略")}</div>
                <div className="next">{productInfo.detail.strategy}</div>
              </div>
            ) : (
              ""
            )}
            <div className="line-item">
              <div className="pre">{window.intl.get("计价单位")}</div>
              <div className="next">{productInfo.item_name}</div>
            </div>
            <div className="line-item">
              <div className="pre">{window.intl.get("最低认购金额")}</div>
              <div className="next">
                {formatThousandth(productInfo.min_amount)}{" "}
                {productInfo.item_name}
              </div>
            </div>
            {product_type == 2 ? (
              <div className="line-item">
                <div className="pre">{window.intl.get("最高认购金额")}</div>
                <div className="next">
                  {formatThousandth(productInfo.max_amount)}{" "}
                  {productInfo.item_name}
                </div>
              </div>
            ) : (
              ""
            )}
            {product_type != 2 ? (
              <React.Fragment>
                <div className="line-item">
                  <div className="pre">{window.intl.get("最小募集金额")}</div>
                  <div className="next">
                    {productInfo.amount == 0
                      ? window.intl.get("不限额")
                      : `${formatThousandth(productInfo.lowest_amount)} ${
                          productInfo.item_name
                        }`}
                  </div>
                </div>
                <div className="line-item">
                  <div className="pre">{window.intl.get("最大募集金额")}</div>
                  <div className="next">
                    {productInfo.amount == 0
                      ? window.intl.get("不限额")
                      : `${formatThousandth(productInfo.amount)} ${
                          productInfo.item_name
                        }`}
                  </div>
                </div>
                <div className="line-item">
                  <div className="pre">{window.intl.get("项目周期")}</div>
                  <div className="next">
                    {productInfo.lock_period} {window.intl.get("天")}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )
      }
      {product_type == 1 ? (
        <div className="tip">
          {window.intl.get("高收益，无风险，到期自动回款")}
        </div>
      ) : (
        ""
      )}
      {/* {
        (product_type == 1 || product_type == 3) && period == 1  ?
        <Step productInfo={productInfo} />
        :""
      } */}
      {(product_type == 1 || product_type == 3) && productInfo.amount != 0 ? (
        <Plan productInfo={productInfo} />
      ) : (
        ""
      )}
      {/* {
        product_type == 3 && period == 1  ?
        <Step pre={window.intl.get("存入")} middle={window.intl.get("开始计息")} next={window.intl.get("产品到期")} productInfo={productInfo}></Step>
        :""
      } */}
    </div>
  );
}
