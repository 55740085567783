import React from "react";
import Step from "../step/step.jsx";
import "./rule.scss";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "antd-mobile";
import { timestampToDate, timestampToYear } from "@/utils/date-format.js";
import { formatThousandth } from "@/utils/digital";
// import IcoIntroduction from './ico-introduction'
import { useHistory, useParams } from "react-router-dom";
import { Popover, NavBar, Icon } from "antd-mobile";
export default function (props) {
  const history = useHistory();
  const Item = Popover.Item;
  const [knowVisible, setKnowVisible] = useState(false);
  const { productInfo, detail } = props;
  const lang = window.$.cookie("lang") || "zh_CN";
  const {
    min_amount,
    max_amount,
    item_name,
    to_item_name,
    amount,
    ico_user_register_time,
    product_type,
    public_raise_amount,
    to_item_id_price,
    ico_buy_condition,
    start_time,
    stop_time,
    quant_end_earning_time,
    lock_period,
    end_time,
  } = productInfo;
  const {
    risk_tip,
    buy_rule,
    join_rule,
    ico_eg,
    ico_url,
    product_code,
  } = detail;
  const onMessage = () => {
    // window.location.href = "/#/ico-introduction";
    window.location = ico_url;
  };
  const onClickRule = () => {
    window.open(
      "https://top6688.zendesk.com/hc/zh-cn/articles/360019970998-%E6%B1%87%E4%BF%A1%E6%B1%A0"
    );
    // setKnowVisible(true);
  };
  const onCloseVisible = () => {
    setKnowVisible(false);
  };
  useEffect(() => {
    document.body.addEventListener(
      "touchmove",
      function (e) {
        setKnowVisible(false);
      },
      { passive: false }
    );
  }, []);
  return (
    <div className="component-rule">
      <div className="rule-top">
        <div className="left">
          <div className="title">{window.intl.get("Launchpad规则")} </div>
          {/* <div onClick={onClickRule} className="placeholder-rule">
             <Popover
              overlayClassName="fortest"
              overlayStyle={{ color: "currentColor" }}
              visible={knowVisible}
              align={{
                overflow: { adjustY: 0, adjustX: 0 },
                offset: [-10, 0],
              }}
              overlay={[
                <Item key="4" value="scan" data-seed="logId">
                  {window.intl.get(
                    "汇信精选优质币种，为广大用户寻找下一个比特币财富密码，让汇信用户都能在早期以优惠的价格持有新币种。"
                  )}
                </Item>,
              ]}
            >
              <div
                style={{
                  height: "100%",
                  padding: "0 15px",
                  marginRight: "-15px",
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
            </Popover> 
          </div>*/}
        </div>{" "}
        <span className="go-to-intrduction" onClick={onMessage}>
          {window.intl.get("认识")}
          {to_item_name} <span className="placeholder-v3"></span>
        </span>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("抢购总额度")}</span>
        </div>
        <div className="next">
          {public_raise_amount} {to_item_name}
        </div>
        {/* {ico_buy_condition == 2 ? (
          <div className="next">
            {amount} {item_name}
          </div>
        ) : (
        
        )} */}
      </div>

      {join_rule ? (
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("参与条件")}</span>
          </div>
          <div className="next">
            {/* {timestampToYear(ico_user_register_time)}
            {window.intl.get("前注册老用户")} */}
            {join_rule}
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("抢购时间")}</span>
        </div>
        <div className="next">{window.intl.get("募集期内均可存入")}</div>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("交付时间")}</span>
        </div>
        <div className="next">
          {lock_period} {window.intl.get("天")}
        </div>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("参与类型")}</span>
        </div>
        <div className="next">{product_code}</div>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("兑换价格")}</span>
        </div>
        <div className="next">
          1 {to_item_name} = {to_item_id_price} {item_name}
        </div>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("单笔限额")}</span>
        </div>
        {
          <div className="next">
            {min_amount} - {max_amount} {item_name}
          </div>
        }
      </div>
      <div className="title second-title">{window.intl.get("兑换规则")}</div>

      <div className="line-item">
        {ico_buy_condition == 2 ? (
          <div className="next">{window.intl.get("购买后禁止取出")}</div>
        ) : (
          <div>
            {<div className="next">{window.intl.get("计息后不可取出:")}</div>}
            {
              <div className="next V2">
                {window.intl.get("您可按照以下公式来计算应分配代币数量：")}
              </div>
            }
            {
              <div className="next">
                {/* {window.intl.get(
                  "某用户应分配代币数量 = 该用户投入的USDT数量 / 所有用户投入的USDT数量 * 本期【超募池】售卖的代币总量"
                )} */}
                {window.intl.get(
                  "某用户应分配代币数量 = 该用户投入的USDT数量 / 所有用户投入的USDT数量 * 本期"
                )}
                【{product_code}】{window.intl.get("售卖的代币总量")}
              </div>
            }
          </div>
        )}
        {
          <div className="next V2">
            {window.intl.get("募集结束：")}
            {timestampToDate(stop_time)}
            {/* {window.intl.get("(若额满则提前关闭募集)")} */}
          </div>
        }
        {ico_buy_condition == 1 ? (
          <div className="next">
            {to_item_name} {window.intl.get("代币转换，开始交付时间：")}
            {timestampToDate(stop_time)}
          </div>
        ) : (
          <div className="next">
            {window.intl.get("开始交付时间：")}
            {timestampToDate(stop_time)}
          </div>
        )}
        <div className="next">
          {window.intl.get("交付完成时间：")}
          {timestampToDate(end_time)}
          {<span>{window.intl.get("-1")}</span>}
        </div>
      </div>
      {ico_buy_condition == 1 && ico_eg ? (
        <div>
          <div className="title second-title">{window.intl.get("举例")}</div>
          <div className="line-item">
            <div className="next">
              {/* {window.intl.get("本兑换池额度35万")}
              {item_name}，{window.intl.get("假如您投了2万")}
              {item_name}，{window.intl.get("所有用户一共投了200万")}
              {item_name}，
              {window.intl.get(
                "您的金额占总额度的1% 那么您最后可以分配到 35万USDT额度的1%份额，可购买份额为3500"
              )}
              {item_name}，{window.intl.get("剩下的16500")}
              {item_name}，{window.intl.get("将归还到您的余额账户中")}
              {item_name}，{window.intl.get("您本次计息的额度为972.22")}
              {to_item_name} */}
              {ico_eg}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {/* <div className="title second-title">{window.intl.get("收益规则")}</div>
      {
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("收益计算")}</span>
          </div>
          <div className="next">
            {window.intl.get("到期收益=存入金额*年化利率*交付期/365")}
          </div>
        </div>
      }
      {
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("收益计算时间")}</span>
          </div>

          {
            <div className="next">
              {timestampToYear(stop_time)}
            </div>
          }
        </div>
      }
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("收益到账时间")}</span>
        </div>
        {
          <div className="next">
            {timestampToYear(end_time)}
          </div>
        }
      </div> */}
      {buy_rule ? (
        <div className="footer">
          {/* <div className="pre">
            <i></i>
            <span>{window.intl.get("购买说明：")}</span>
          </div> */}
          <div
            className="next"
            dangerouslySetInnerHTML={{ __html: buy_rule }}
          ></div>
        </div>
      ) : (
        ""
      )}
      {risk_tip ? (
        <div className="footer">
          {/* <div className="pre">
            <i></i>
            <span>{window.intl.get("风险提示：")}</span>
          </div> */}
          <div
            className="next"
            dangerouslySetInnerHTML={{ __html: risk_tip }}
          ></div>
        </div>
      ) : (
        ""
      )}
      {/* <Modal
        visible={knowVisible}
        maskClosable={false}
        className="know-modal"
        transparent
      >
        <div className="common-title">{window.intl.get("汇信池")}</div>
        <div className="common-text">
          {window.intl.get(
            "汇信精选优质币种，为广大用户寻找下一个比特币财富密码，让汇信用户都能在早期以优惠的价格持有新币种。"
          )}
        </div>
        <div onClick={onCloseVisible} className="know-buttons">
          {window.intl.get("我知道了")}
        </div>
      </Modal> */}
    </div>
  );
}
