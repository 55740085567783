import React from 'react'
import './plan.scss'
export default function Plan (props) {
  const { productInfo } = props;
  const { amount, total_amount } = productInfo;
  let rate = 0;
  if (amount) {
    rate = (total_amount / amount * 100).toFixed(2);
  }
  return (
    <div className="plan-component">
      <div className="pre-text-box">
        <span>{window.intl.get('募集进度')}</span>
        <span>{window.intl.get('已募集')}
          {
            window.intl.options.currentLocale == 'ar' ?
            ('%' + rate)
            : (rate + '%')
          }
        </span>
        <span className="last-text">100%</span>
      </div>
      <div className="plan-chart">
        <i className="plan-line" style={{'width': `${rate}%`}}></i>
      </div>
    </div>
  )
}