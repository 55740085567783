import React, { useState } from "react";
import "./digita-evertrust.scss";
import Viewer from "react-viewer";

export default function () {
  const [visible, setVisible] = React.useState(false);
  const [visibleV2, setVisibleV2] = React.useState(false);
  return (
    <div className="digita-evertrust">
      <div className="sec sec1">
        <div className="common-btn">{window.intl.get("恒信优势")}</div>
        <div className="sub-title">{window.intl.get("安全加倍")}</div>
        <div className="sub-content">
          {window.intl.get(
            "独创超级密码，更好的安全守护。坚持自主掌控资产，重视用户隐私。资产更安全，交易更透明。"
          )}
        </div>

        <div className="sub-title">{window.intl.get("简单易用")}</div>
        <div className="sub-content">
          {window.intl.get(
            "多快好省的用户体验，追求易用和高效。一键管理数字资产，轻松管理多组地址。方便省心、安全快速。"
          )}
        </div>

        <div className="sub-title">{window.intl.get("高性能、高稳定性")}</div>
        <div className="sub-content">
          {window.intl.get(
            "独创进程内存撮合技术，千万级用户高并发极速处理，超高效撮合引擎，闪电下单。"
          )}
        </div>
      </div>
      <div className="sec sec2">
        <div className="common-btn ">{window.intl.get("经营合规")}</div>
        <div className="license-photos">
          <div>
            <div
              className="photo-content"
              onClick={() => {
                setVisible(true);
              }}
            >
              {" "}
              <span className="placeholder-v11"></span>
            </div>
            <p className="photo-text">{window.intl.get("美国MSB牌照")}</p>
          </div>
          <div>
            <div
              className="photo-content V3"
              onClick={() => {
                setVisibleV2(true);
              }}
            >
              {" "}
              <span className="placeholder-v22"></span>
            </div>
            <p className="photo-text">{window.intl.get("加拿大MSB牌照")}</p>
          </div>
        </div>
      </div>

      <div className="sec sec4">
        <div className="common-btn">{window.intl.get("恒信超级密码")}</div>
        <div>
          <ul className="inline-content">
            <li>
              <span className="icon6"></span>
              <p>{window.intl.get("权限分离设置")}</p>
            </li>
            <li>
              <span className="icon7"></span>
              <p>{window.intl.get("延迟恢复机制")}</p>
            </li>
            <li>
              <span className="icon8"></span>
              <p>{window.intl.get("分层密钥管理")}</p>
            </li>
            <li>
              <span className="icon9"></span>
              <p>{window.intl.get("风险应对体系")}</p>
            </li>
          </ul>
        </div>
        <div className="common-content">
          {window.intl.get(
            "四大措施，用「超级密码」取代私钥，通过提供易用、安全、可容错的方案来解决用户的私钥管理问题，不需要用户记录私钥的同时，兼顾安全和效率。"
          )}
        </div>
      </div>
      <div className="sec sec5">
        <div className="common-btn">{window.intl.get("恒信技术优势")}</div>
        <div className="sub-title">{window.intl.get("自动风控")}</div>
        <div className="sub-content">
          {window.intl.get(
            "自主研发的自动风控技术，严格校验合约地址，自动避免假币等诈骗问题"
          )}
        </div>

        <div className="sub-title">{window.intl.get("秒级对账")}</div>
        <div className="sub-content">
          {window.intl.get(
            "多级校验对账机制，多重等级防护，智能监控，实时警报主动干预"
          )}
        </div>

        <div className="sub-title">{window.intl.get("高性能、高稳定性")}</div>
        <div className="sub-content">
          {window.intl.get(
            "千万级用户高并发极速处理，超高效撮合引擎，闪电下单"
          )}
        </div>

        <div className="sub-title">{window.intl.get("独立的资产保险库")}</div>
        <div className="sub-content">
          {window.intl.get(
            "恒信的每个用户都在恒信资金池中拥有独立的资产保险库，确保资产的独立性和安全性"
          )}
        </div>

        <div className="sub-title">{window.intl.get("冷热钱包双机制")}</div>
        <div className="sub-content">
          {window.intl.get(
            "主要资金离线存储，资产和私钥全球分布式保存，充提币人工与智能结合"
          )}
        </div>

        <div className="sub-title">{window.intl.get("混币技术")}</div>
        <div className="sub-content">
          {window.intl.get(
            "自主研发的地址隐私设计，永无风控，被追踪的后顾之忧"
          )}
        </div>
      </div>
      <Viewer
        visible={visible}
        noFooter={true}
        onClose={() => {
          setVisible(false);
        }}
        images={[
          {
            src: require("../../../assets/img/financial-license/licence1-ever.png"),
            alt: "",
          },
        ]}
      />
      <Viewer
        visible={visibleV2}
        noFooter={true}
        onClose={() => {
          setVisibleV2(false);
        }}
        images={[
          {
            src: require("../../../assets/img/financial-license/licence2-ever.png"),
            alt: "",
          },
        ]}
      />
      {/* <div className="big-img">
        <span className="placeholder"></span>
        <img src="../../../assets/img/financial-license/licence1.png" alt=""/>
      </div> */}
    </div>
  );
}
