import { logDOM } from "@testing-library/react";
import React, { useState } from "react";
import { apiGetJoinRoomInfo } from "@/model/join-group/index";
import { useEffect } from "react";
import { REACT_APP_ID } from "@/assets/js/stationSetting";
import "./join-group.scss";
import img1 from "@/assets/img/join-group/topcredit-logo.png";
import img2 from "@/assets/img/join-group/b9-logo.png";
import img3 from "@/assets/img/join-group/logo-8V.png";
import imgueasy from "@/assets/img/join-group/logo-ueasy.png";
import imgmask from "@/assets/img/join-group/logo-mask.png";
import imgbitda from "@/assets/img/join-group/logo-bitda.png";
import rightIco from "@/assets/img/join-group/right-ico.png";
import imgS6 from "@/assets/img/join-group/logo-S6.png";
import imgEzex from "@/assets/img/join-group/logo-ezex.png";
import img5a from "@/assets/img/join-group/logo-5a.png";
import img8coin from "@/assets/img/join-group/logo-8coin.png";

export default function () {
  const [loading, setLoading] = useState(true);
  const [productInfo, setProductInfo] = useState({});
  const url = document.location.toString();
  const arrUrl = url.split("?");
  const domain = arrUrl[0];
  const para = arrUrl[1];
  const paramsArr = para.split("&");
  const mUrl = paramsArr[0].split("m=");
  const mParams = mUrl[1];
  let logo = img1;
  let invite = "";
  let inviteCode = "";
  if (paramsArr.length > 1) {
    invite = paramsArr[1].split("invite=");
    inviteCode = invite[1];
  }
  let downloadUrl = "";
  let scheme = "topcredit";
  let schemeClass = "topcredit";
  let NAME = "topcredit";
  let currentLocale = "zh_CN";
  let lang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
  lang = lang.substr(0, 2); //截取lang前2位字符
  switch (lang) {
    case "en":
      currentLocale = "en";
      break;
    case "zh":
      currentLocale = "zh_CN";
      break;
    default:
      currentLocale = "en";
      break;
  }
  if (
    REACT_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" ||
    REACT_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T" ||
    REACT_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w"
    // 汇信
  ) {
    logo = img1;
    scheme = "topcredit";
    NAME = "汇信";
    downloadUrl = "https://www.topcreditbank.com/app_download";
  } else if (REACT_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
    scheme = "b9";
    schemeClass = "b9";
    logo = img2;
    NAME = "币久";
    downloadUrl = "https://download.b9.com/";
  } else if (REACT_APP_ID == "q34hy0KrQWqLiVzcQEuEwAtM") {
    scheme = "eightv";
    schemeClass = "v8";
    logo = img3;
    NAME = "8V";
    downloadUrl = "https://download.8v.com/";
  } else if (REACT_APP_ID == "an38PVWu2b6UgiRa94c68Cmy") {
    scheme = "Ueasy";
    schemeClass = "Ueasy";
    logo = imgueasy;
    NAME = "UEEx";
    downloadUrl = "https://download.ueex.com/";
  } else if (
    REACT_APP_ID == "lgthGRzu7fKncY8xVe0FtcAj" ||
    REACT_APP_ID == "TVLpjN0I6rI8uT3Hr26TOAgA"
  ) {
    scheme = "mask";
    schemeClass = "maskex";
    logo = imgmask;
    NAME = "Maskex";
    downloadUrl = "https://download.maskex.com/";
  } else if (REACT_APP_ID == "NOVg0fRvvubBiaURjmQBWUu0") {
    scheme = "bitda";
    schemeClass = "bitda";
    logo = imgbitda;
    NAME = "bitda";
    downloadUrl = "https://download.bitda.com/";
  } else if (REACT_APP_ID == "udsF6Fej8aXrBUkXuwloBmGh") {
    scheme = "S6";
    schemeClass = "S6";
    logo = imgS6;
    NAME = "S6";
    downloadUrl = "https://download.S6nn.com/";
  } else if (REACT_APP_ID == "b7VsDpeUEV2sr6mFieYg0AYx") {
    scheme = "ezex";
    schemeClass = "ezex";
    logo = imgEzex;
    NAME = "ezex";
    downloadUrl = "https://download.easyexex.com/";
  } else if (REACT_APP_ID == "z2eUsrHz5aj7bWtOgRWC8x9n") {
    scheme = "fivea";
    schemeClass = "a5";
    logo = img5a;
    NAME = "5a";
    downloadUrl = "https://download.5a.com/";
  } else if (REACT_APP_ID == "NAzwyfBkqbeEcEAtqjw5bAuJ") {
    scheme = "eightcoin";
    schemeClass = "coin8";
    logo = img8coin;
    NAME = "8Coin";
    downloadUrl = "https://download.8coinex.com/";
  }

  useEffect(() => {
    const req = {
      m: mParams,
    };
    apiGetJoinRoomInfo(req).then(res => {
      setLoading(false);
      if (res.status == 200) {
        setProductInfo(res.data);
      }
    });
  }, []);
  const onJumpClick = val => {
    window.location = ` ${scheme}://joingroup/?m=${mParams}`;
    setTimeout(() => {
      window.location = downloadUrl;
    }, 2000);
  };
  return (
    <div className="join-group">
      {/* <a href={`${scheme}://joingroup/?m=${mParams}`}>加入群聊</a>
       */}
      <p>
        <img className="logo" src={logo} alt="" />
      </p>
      <div
        className={"download-text " + schemeClass}
        // className={
        //   REACT_APP_ID == "9wZvazddcsWrnWtnB7Zhy399"
        //     ? "download-text b9"
        //     : "download-text"
        // }
      >
        {REACT_APP_ID == "q34hy0KrQWqLiVzcQEuEwAtM" ? (
          currentLocale == "en" ? (
            <a href={downloadUrl}>
              {" "}
              {"Click to download"} {NAME} App？
            </a>
          ) : (
            <a href={downloadUrl}>
              {" "}
              {window.intl.get("还没有")} {NAME}App？
              {window.intl.get("现在就试试！")}
            </a>
          )
        ) : (
          <a href={downloadUrl}>
            {" "}
            {window.intl.get("还没有")} {NAME}App？
            {window.intl.get("现在就试试！")}
          </a>
        )}

        <img src={rightIco} alt="" />
      </div>
      <div className="group-info">
        <img className="group-img" src={productInfo.chat_avatar} alt="" />
        <p className="group-name">{productInfo.chat_name}</p>
        <p className="group-people">
          {window.intl.get("共")} &nbsp;
          {productInfo.people_num} &nbsp;
          {window.intl.get("人")}
        </p>
      </div>

      <div className="remark-content">
        <p className="remark-title">{window.intl.get("群简介")}</p>
        <p className="remark">{productInfo.remark}</p>
      </div>
      {REACT_APP_ID == "q34hy0KrQWqLiVzcQEuEwAtM" ? (
        currentLocale == "en" ? (
          <div
            onClick={() => {
              onJumpClick();
            }}
            className={"bottom-btn " + schemeClass}
            href={`${scheme}://joingroup/?m=${mParams}`}
          >
            {"Open"} {NAME} App
          </div>
        ) : (
          <div
            onClick={() => {
              onJumpClick();
            }}
            className={"bottom-btn " + schemeClass}
            href={`${scheme}://joingroup/?m=${mParams}`}
          >
            {window.intl.get("在")}
            {NAME}App
            {window.intl.get("查看")}
          </div>
        )
      ) : (
        <div
          onClick={() => {
            onJumpClick();
          }}
          className={"bottom-btn " + schemeClass}
          href={`${scheme}://joingroup/?m=${mParams}`}
        >
          {window.intl.get("在")}
          {NAME}App
          {window.intl.get("查看")}
        </div>
      )}
      {/* <div
        onClick={() => {
          onJumpClick();
        }}
        className={"bottom-btn " + schemeClass}
        // className={
        //   REACT_APP_ID == "9wZvazddcsWrnWtnB7Zhy399"
        //     ? "bottom-btn b9"
        //     : "bottom-btn"
        // }
        href={`${scheme}://joingroup/?m=${mParams}`}
      >
        
        {window.intl.get("在")}
        {NAME}App
        {window.intl.get("查看")}
      </div> */}
    </div>
  );
}
