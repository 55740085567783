export default function (search, currKey) {
  const index = search.indexOf("?");
  if (index == -1) {
    return "";
  }
  const paramsStr = search.slice(index + 1, search.length);
  
  if (paramsStr) {
    const itemStr = paramsStr.split("&");
    let result = "";
    itemStr.forEach(item => {
      const i = item.indexOf("=");
      if (i != -1) {
        const pre = item.slice(0, i);
        const next = item.slice(i+1, item.length);
        if (pre == currKey) {
          result = next;
        }
      }
    });
    return result;
  } else {
    return "";
  }
}