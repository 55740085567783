import React from "react";
import "./ico-info.scss";
import TextPanel from "./component/text-panel/text-panel.jsx";
import Intro from "./component/intro/intro.jsx";
import Rule from "./component/rule/rule.jsx";
import Rate from "./component/rate/rate.jsx";
import Chart from "./component/chart/chart.jsx";
import MutiChart from "./component/muti-chart/muti-chart.jsx";
import Pie from "./component/pie/pie.jsx";
import Loading from "@/component/loading/loading.jsx";
import { apiGetProductInfo } from "@/model/ico/index";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
export default function () {
  
  const { product_id } = useParams();
  const [productInfo, setProductInfo] = useState({});
  const [rateList, setRateList] = useState({});
  const [rateList30, setRateList30] = useState([]);
  const [incRateList, setIncRateList] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const req = {
      product_id,
    };
    apiGetProductInfo(req).then((rs) => {
      setLoading(false);
      if (rs.status == 200) {
        setProductInfo(rs.data);
        setRateList(rs.data.detail);
        // setRateList30(rs.data.rateList30);
        // setIncRateList(rs.data.incRateList);
      }
    });
  }, []);
  return (
    <div className="icon-info">
      {!loading ? (
        <React.Fragment>
          <Rate productInfo={productInfo}  />
    
          <Rule productInfo={productInfo} detail={rateList}/>

          <i className="gap"></i>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}
