import React, { useEffect, useRef } from "react";
import "./loading.scss";
import Lottie from "lottie-react-web";
import loadingJson from "@/assets/img/common/loading_white.json";
import grey from "@/assets/img/common/grey.json";
import { REACT_APP_ID } from "../../assets/js/stationSetting";
import { logDOM } from "@testing-library/react";
export default function () {
  let loading = "";
  if (
    REACT_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" ||
    REACT_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w"
  ) {
    loading = loadingJson;
  } else {
    loading = grey;
  }
  return (
    <div id="global-loading" className="lds-ring">
      <div className="circle-loading-v2">
        <Lottie
          options={{
            animationData: loading,
          }}
        ></Lottie>
      </div>
    </div>
  );
}
