import api from "@/assets/js/api.js";

// const { REACT_APP_API } = process.env
import {
  REACT_APP_PRODUCT_API,
  REACT_APP_API,
  REACT_APP_ID,
  REACT_APP_KEY,
} from "@/assets/js/stationSetting";
export function apiGetProductInfo(data = {}) {
  return api({
    url: REACT_APP_API + `/Publics/getICOProductInfo`,
    data,
  });
}
