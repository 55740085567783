import React from "react";
import "./support-article.scss";
// import { apiArticleDetail } from "@/model/support-article";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
export default function () {
  const [articleInfo, setArticelInfo] = useState({});
  const { articleId } = useParams();
  useEffect(() => {
    const req = {
      articleId,
      type: 3,
    };
    // apiArticleDetail(req).then((rs) => {
    //   setArticelInfo(rs);
    // });
  }, []);
  return (
    <div className="page-support-article">
      <div className="article-title">{articleInfo.title}</div>
      <div className="article-date">{articleInfo.update_time}</div>
      <div
        className="article-content"
        dangerouslySetInnerHTML={{ __html: articleInfo.content }}
      ></div>
    </div>
  );
}
