import React from "react";
import "./rate.scss";
import Step from "../step/step.jsx";
import Plan from "../plan/plan.jsx";
import { useEffect } from "react";

export default function (props) {
  const { productInfo } = props;
  const {
    item_name,
    tag,
    to_item_name,
    from_farm,
    float_apr,
    float_apy,
    float_day_rate,
    product_name,
    item_url,
    apr,
  } = productInfo;

  const quantTagMap = {
    1: window.intl.get("单投单利"),
    2: window.intl.get("单投双利"),
    3: window.intl.get("复利复投"),
  };
  const ruleMap = {
    1: window.intl.get(
      "投入高收益资金池，灵活锁仓，随时可存取，每日取得收益。"
    ),
    2: window.intl.get("投入高收益资金池，灵活锁仓，随时可存取"),
    3: window.intl.get(
      "投入高收益资金池，灵活锁仓，随时可存取，每日收益自动复投，滚动复利。"
    ),
  };
  const getCoinMap = {
    1: window.intl.get("每日收益自动到帐"),
    2: window.intl.get("每日收益自动复存，享受滚动复利"),
    3: window.intl.get("每日收益自动复存，享受滚动复利"),
  };
  return (
    <div className="component-rate-v2">
      <div className="component-header-v2">
        <div className="left-item">
          <img className="coin-img" src={item_url} alt="" />
          <div className="coin-text">
            <span className="text-one">{product_name}</span>
            <span className="text-two">
              {window.intl.get("投")}&nbsp;
              {item_name}&nbsp;
              {window.intl.get("挖")}
              &nbsp;
              {to_item_name}
            </span>
          </div>
        </div>
        <div className="right-item">
          <span className="right-num">{float_apr}</span>
          <span className="right-text">{window.intl.get("参考年化")}</span>
        </div>
      </div>
      <div className="tip-box">
        <div className="line-item">
          <div className="pre">{window.intl.get("挖矿类型")}</div>
          <div className="next">{quantTagMap[tag]}</div>
        </div>
        {tag == 3 ? (
          <div className="line-item">
            <div className="pre">{window.intl.get("复投年化")}</div>
            <div className="next">{float_apy}</div>
          </div>
        ) : (
          ""
        )}
        {tag != 3 ? (
          <div className="line-item">
            <div className="pre">{window.intl.get("收益币种")}</div>
            <div className="next">{to_item_name}</div>
          </div>
        ) : (
          ""
        )}
        <div className="line-item">
          <div className="pre">{window.intl.get("Daily日化")}</div>
          <div className="next">{float_day_rate}</div>
        </div>
        {/* <div className="line-item">
          <div className="pre">{window.intl.get("三方")}</div>
          <div className="next">{from_farm}</div>
        </div> */}
        <div className="line-item">
          <div className="pre">{window.intl.get("计息日")}</div>
          <div className="next">T+1</div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("取出日")}</div>
          <div className="next">T+1</div>
        </div>
      </div>
    </div>
  );
}
