import React from "react";
import "./not-supported.scss";
export default function () {
  return (
    <div className="not-supported">
      <span className="no-data"></span>
      {window.intl.get("暂不支持")}
    </div>
  );
}
