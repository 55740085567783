import React from "react";
import "./rule.scss";
import { useState } from "react";
import { useEffect } from "react";
import { timestampToDate, timestampToYear } from "@/utils/date-format.js";
import { useHistory, useParams } from "react-router-dom";
import { Popover, NavBar, Icon } from "antd-mobile";
export default function (props) {
  const history = useHistory();
  const Item = Popover.Item;
  const [knowVisible, setKnowVisible] = useState(false);
  const { productInfo, detail } = props;
  const lang = window.$.cookie("lang") || "zh_CN";
  const {
    max_amount,
    item_name,
    to_item_name,
    amount,
    price,
    earning,
    start_period,
    min_amount,
    start_time,
    stop_time,
    quant_end_earning_time,
    lock_period,
    end_time,
  } = productInfo;
  const {
    fee_rule,
    earnings_rule,
    intro_url,
    ico_eg,
    ico_url,
    product_code,
  } = detail;
  const onMessage = () => {
    window.location = intro_url;
  };
  const onClickRule = () => {
    window.open(
      "https://top6688.zendesk.com/hc/zh-cn/articles/360019970998-%E6%B1%87%E4%BF%A1%E6%B1%A0"
    );
  };
  const onCloseVisible = () => {
    setKnowVisible(false);
  };
  useEffect(() => {
    document.body.addEventListener(
      "touchmove",
      function (e) {
        setKnowVisible(false);
      },
      { passive: false }
    );
  }, []);
  return (
    <div className="component-rule-chia">
      <div className="rule-top">
        <div className="left">
          <div className="title">{window.intl.get("抢购规则")} </div>
        </div>
        <div onClick={onClickRule} className="placeholder-rule"></div>
        <span className="go-to-intrduction" onClick={onMessage}>
          {window.intl.get("云算力")}
          <span className="placeholder-v3"></span>
        </span>
      </div>

      <div className="tip-box">
        <div className="line-item">
          <div className="pre">{window.intl.get("第一期总额度")}</div>
          <div className="next">{amount} T</div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("第一期认购价格")}</div>
          <div className="next">
            {price} {item_name}/T
          </div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("认购币种")}</div>
          <div className="next">{item_name}</div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("最小限额")}</div>
          <div className="next">{min_amount} T</div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("结算币种")}</div>
          <div className="next">{to_item_name}</div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("每T算力预估")}</div>
          <div className="next">
            {window.intl.get("预计每日产出")}
            {earning} {to_item_name}/T
          </div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("预计启动挖矿时间")}</div>
          <div className="next">
            {window.intl.get("认购结束后")}
            {start_period}
            {window.intl.get("天")}
          </div>
        </div>
        <div className="line-item">
          <div className="pre">{window.intl.get("收益发放")}</div>
          <div className="next">
            {window.intl.get("日结，以矿池收益结算时间为准")}
          </div>
        </div>
      </div>
      {earnings_rule ? (
        <div>
          <div className="title second-title">
            {window.intl.get("运营服务费")}
          </div>
          <div className="content">
            <p>{fee_rule}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      {earnings_rule ? (
        <div>
          <div className="title second-title">
            {window.intl.get("收益规则")}
          </div>
          <div className="content">
            <p
              className="article-content"
              dangerouslySetInnerHTML={{ __html: earnings_rule }}
            ></p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
