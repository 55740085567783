import React from 'react';
import './rule.scss';
export default function () {
  // const lang = window.intl.options.currentLocale;
  return (
    <div className="component-stock-rule">
      <div className="sec sec1">
        <div className="common-component-btn">{window.intl.get('产品特点')}</div>
        <div className="sub-title">{window.intl.get('低门槛')}</div>
        <div className="sub-content">{window.intl.get('无需繁锁的开户程序，只需要有USDT即可交易美/港股')}</div>
        
        <div className="sub-title">{window.intl.get('收费低')}</div>
        <div className="sub-content">{window.intl.get('仅收成交额1.5%，无帐管费、无税金，无额外信托费用')}</div>

        <div className="sub-title">{window.intl.get('无限日内交易')}</div>
        <div className="sub-content">{window.intl.get('汇信银行给您最佳交易体验，笔笔交易都可以为T+0')}</div>

        <div className="sub-title">{window.intl.get('公平公正')}</div>
        <div className="sub-content">{window.intl.get('交易标的行情价格来自实际美/港交易所，委托单完成后，将由汇信送委托单至美港股交易所进行搓合')}</div>
        <div className="sub-title">{window.intl.get('优质美港股')}</div>
        <div className="sub-content">{window.intl.get('目前汇信首波提供优质20支股票，美股港股各10支股票 我们也将陆陆续续新增更多的股票，让用户购买，敬请期待')}</div>
        <div className="sub-title">{window.intl.get('美港股最小下单数量')}</div>
        <div className="sub-content">
        {window.intl.get('美股：1股')}
        <br/>
        {window.intl.get('港股：1手')}
        <br/>
        {window.intl.get('买卖港股的最小单位是一手。和A股不同，港股中每手所含的股票数量根据股票价格来决定，1手可能是100股、500股、1000股和2000股不等，如目前的港股中腾讯控股为每手100股，金山软件则是每手1000股。')}
        </div>
        <div className="sub-title">{window.intl.get('清算交割(平仓)')}</div>
        <div className="sub-content">{window.intl.get('美港股平仓的清算交割皆为T+2，需要2个工作日，才能完成清算交割，在清算交割完成前，款项会先入冻结金额。')}</div>
      </div>
    </div>
  )
}