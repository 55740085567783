import React from "react";
import "./dual-currency-investment.scss";
export default function () {
  return (
    <div className="dual-currency-investment">
      <div className="second-title">1、简介</div>
      <div className="content">
        双币投资是一种高额收益结构型理财产品，以两种币种中的某一种进行结算。用户在申购时可选择
        投资币种、认购额度及到期时间。投资回报将会根据以下情况结算为所投资币种或替代币种。
        双币投资有两种类型:上升行权 和 下跌行权。
        <br />
        ■
        上升行权产品:如果结算价格高于(上升)行权价格，那么产品将会被行权。若结算价格
        ≥ 行权 价格则产品被行权;若结算价格 ＜ 行权价格则不被行权。
        <br />■
        下跌行权产品:如果结算价格低于(下跌)行权价格，那么产品将会被行权。若结算价格≤行权
        价格则产品被执行;若结算价格 > 行权价格则不被行权。
        无论投资的双币理财基础资产，若产品并未行权，您将会以投资币种的形式收到投资回报。若产品
        到期行权，您将会以替代币种的形式收到投资回报。每个被申购产品都有指定到期日，我们将以交
        割日08:00(UTC)的现货价格作为结算价。
      </div>
      <div className="second-title">2. 收益是如何计算?</div>
      <div className="content">
        当所申购的产品被行权时，您所申购的金额与利息收益将会以行权价格被交易为替代币种。以下公
        式为您将收到的总金额。
      </div>
      <div className="content">
        ■ 上升行权:(申购金额*行权价)*[1+(APY%*理财天数/365)]
      </div>
      <div className="content">
        ■ 下跌行权:(申购金额/行权价)*[1+(APY%*理财天数/365)]
        当所申购的产品不被行权时，您所申购的金额与利息收益将不会被交易为替代币种，您将最终收到
        投资币种。以下公式为您将收到的总金额:
      </div>
      <div className="content">
        ■ 未行权:申购金额*[1+(APY%*理财天数/365)]
        注意:实际交割日年化收益率，与APY息息相关，行权成功最高年化可达100%。APY会及时刷新，
        我们将在您完成申购时使用最新APY。
      </div>

      <div className="second-title">3. 如何查看我的订单?</div>
      <div className="content">
        用户可以在理财板块(持有)以及资产板块(理财)中，查看您当前的申购和赎回项目订单。
      </div>

      <div className="second-title">4. 何时拿到收益?</div>
      <div className="content">
        每个被申购产品都有指定到期日。我们将使用交割日08:00(UTC)最后30分钟的现货平均价格作为结
        算价格。您的收益将会于结算日08:00(UTC)后48小时内自动结算进入您的账户中。双币理财不能提
        前赎回。您将只能在到期日之后获得投资回报，不可提前。
      </div>

      <div className="second-title">5. 能否取消我的申购?</div>
      <div className="content">
        很抱歉，一旦申购成功，您将无法取消您的申购。请在申购之前仔细阅读产品条款。
      </div>
      <div className="second-title">
        6. 什么是“行权价格”、“投资币种”、“替代币种”、“周期(天)”、“结算价格”?
      </div>
      <div className="content">
        ■ 行权价格:产品被行权时投资币种转化为另一币种使用的交易价格。
        <br />
        ■ 投资币种:在申购双币投资产品时所使用的币种即为投资币种。
        <br />
        ■ 替代币种:当产品被行权后所收到的币种即为另一币种。
        <br />■ 周期(天):距离结算日的天数
        <br />■
        结算价格:交割日08:00(UTC)最后30分钟现货价格的平均值。结算价和行权价决定了产品是否
        被行权。
      </div>
    </div>
  );
}
