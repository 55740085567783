import React from 'react';
import './money.scss';
export default function () {
  return (
    <div className="component-stock-money">
      <div className="sec sec1">
        <div className="common-component-btn">{window.intl.get('操作步骤')}</div>
        <div className="step">
          <ul>
            <li>
              <i>1</i>
              <span>{window.intl.get('买入')}</span>
            </li>
            <li>
              <i>2</i>
              <span>{window.intl.get('持仓')}</span>
            </li>
            <li>
              <i>3</i>
              <span>{window.intl.get('卖出')}</span>
            </li>
          </ul>
        </div>
        <div className="common-component-btn">{window.intl.get('如何买入？')}</div>
        <div className="placeholder placeholder1"></div>
        <div className="sub-title">{window.intl.get('1. 根据您的需求，选择美/港股')}</div>
        <div className="placeholder placeholder2"></div>
        <div className="sub-title">{window.intl.get('2. 选择市价/限价')}</div>
        <div className="placeholder placeholder3"></div>
        <div className="sub-title">{window.intl.get('3. 输入购买股数与价格，确认订单')}</div>
        <div className="placeholder placeholder4"></div>
      </div>
      <div className="sec sec2">
        <div className="common-component-btn">{window.intl.get('如何查看持仓？')}</div>
        {/* <div className="sub-title">{window.intl.get('如何查看持仓？')}</div> */}
        <div className="placeholder placeholder5"></div>

        <div className="sub-title">{window.intl.get('1. 点击持仓订单，查看持仓详情')}</div>
        <div className="placeholder placeholder6"></div>

        <div className="sub-title">{window.intl.get('2. 股票持仓页面展示了您所有的持仓。每一笔持仓都展示了当前的盈亏、盈亏比和持仓成本等信息')}</div>
        <div className="placeholder placeholder7"></div>

        <div className="common-component-btn">{window.intl.get('如何卖出？')}</div>

        
        <div className="placeholder placeholder8"></div>
        
      </div>
      <div className="sec sec3">
      <div className="sub-title">{window.intl.get('1. “卖出”可以选择市价或是限价卖出。卖出后，此持仓的本金和平仓盈亏将返还至冻结中，待T+2清算交割完成后，款项会返还至资产中')}</div>
        <div className="placeholder placeholder9"></div>
        

        <div className="sub-title">
          {window.intl.get('2. 您还可以通过设置市價和限价，让系统自动帮您將賣出單送出搓合')} <br />
          {window.intl.get('3. 平仓后，可以在「成交记录」标签下查看进度')}</div>
        <div className="placeholder placeholder10"></div>
      </div>
    </div>
  )
}