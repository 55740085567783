import api from "@/assets/js/api.js";

// const { REACT_APP_PRODUCT_API, REACT_APP_COIN_API } = process.env
import {
  REACT_APP_PRODUCT_API,
  REACT_APP_API,
  REACT_APP_ID,
  REACT_APP_KEY,
} from "@/assets/js/stationSetting";
export function apiGetCoinInfo(data = {}) {
  return api({
    url: REACT_APP_PRODUCT_API + `/App/Product/getCoinInfo`,
    data,
  });
}

// export function initDepthData(data = {}) {
//   return api({
//     url: REACT_APP_COIN_API + `/Web/Kline/depth`,
//     data,
//   });
// }

// export function apiGetOrderbook(data = {}) {
//   return api({
//     url: REACT_APP_COIN_API + `/Web/Kline/orderbook`,
//     data,
//   });
// }

export function apiHistoryData(data = {}) {
  return api({
    url: REACT_APP_PRODUCT_API + `/Web/Kline/history`,
    data,
    type: "GET",
  });
}

// // 获取kline精度
export function apiSymbolInfo(data = {}) {
  return api({
    url: REACT_APP_PRODUCT_API + `/Web/Kline/symbols`,
    data,
    type: "GET",
  });
}
