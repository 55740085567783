import React from "react";
import Step from "../step/step.jsx";
import "./rule.scss";
import { timestampToYearToBJ } from "@/utils/date-format.js";
import { formatThousandth } from "@/utils/digital";
export default function (props) {
  const { productInfo } = props;
  const {
    min_amount,
    max_amount,
    item_name,
    product_type,
    period,
    start_time,
    stop_time,
    quant_end_earning_time,
    lock_period,
    end_time,
  } = productInfo;
  return (
    <div className="component-rule">
      <div className="title">{window.intl.get("存取规则")}</div>
      {(product_type == 1 || product_type == 3) && period == 2 ? (
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("存入时间")}</span>
          </div>
          <div className="next">{window.intl.get("每日00:00-23:59:59")}</div>
        </div>
      ) : (
        ""
      )}
      {(product_type == 1 || product_type == 3) && period == 1 ? (
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("存入时间")}</span>
          </div>
          <div className="next">{window.intl.get("募集期内均可存入")}</div>
        </div>
      ) : (
        ""
      )}
      {product_type == 1 || product_type == 3 ? (
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("存入期限")}</span>
          </div>
          <div className="next">
            {lock_period} {window.intl.get("天")}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("单笔限额")}</span>
        </div>
        <div className="next">
          {formatThousandth(min_amount)}-{formatThousandth(max_amount)}{" "}
          {item_name}
        </div>
      </div>
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("取出规则")}</span>
        </div>
        {productInfo.product_type == 1 || productInfo.product_type == 3 ? (
          <div className="next">
            {window.intl.get("存入后不可撤销，到期前不可取出")}
            <br />
            {window.intl.get("募集开始时间：")}
            {timestampToYearToBJ(start_time)} <br />
            {window.intl.get("募集结束与起息时间：")}
            {timestampToYearToBJ(stop_time)} <br />
            {productInfo.product_type == 3 ? (
              <div>
                {window.intl.get("利息发放时间：")}
                {timestampToYearToBJ(quant_end_earning_time)}
              </div>
            ) : (
              <div>
                {window.intl.get("利息发放时间：")}
                {timestampToYearToBJ(end_time)}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {productInfo.product_type == 2 ? (
          <div className="next">{window.intl.get("随时取出，实时到账")}</div>
        ) : (
          ""
        )}
      </div>
      <div className="title second-title">{window.intl.get("收益规则")}</div>
      {(productInfo.product_type == 1 || productInfo.product_type == 3) &&
      productInfo.period == 2 ? (
        <Step
          pre={window.intl.get("存入")}
          middle={window.intl.get("开始计息")}
          next={window.intl.get("产品到期")}
          productInfo={productInfo}
        ></Step>
      ) : (
        ""
      )}
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("收益计算")}</span>
        </div>
        {productInfo.product_type == 1 ? (
          <div className="next">
            {window.intl.get("到期收益=存入金额*年化利率*锁定期/365")}
            {productInfo.show_profit == 1 ? (
              <span>{window.intl.get("-项目运作人分润")}</span>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {productInfo.product_type == 2 ? (
          <div className="next">
            {window.intl.get("当日收益=日末资产*日末年化利率/365")}
            {productInfo.show_profit == 1 ? (
              <span>{window.intl.get("-项目运作人分润")}</span>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {productInfo.product_type == 3 ? (
          <div className="next">
            {window.intl.get("实际到账收益=当期持仓盈亏")}
            {productInfo.show_profit == 1 ? (
              <span>{window.intl.get("-项目运作人分润")}</span>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {productInfo.product_type == 2 ? (
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("发放时间")}</span>
          </div>
          <div className="next">
            {window.intl.get("每日16点(UTC+8)发放前一日收益")}
          </div>
        </div>
      ) : (
        ""
      )}
      {productInfo.product_type == 1 || productInfo.product_type == 3 ? (
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("收益规则")}</span>
          </div>

          {productInfo.period == 1 ? (
            <div className="next">{window.intl.get("募集结束即开始计息")}</div>
          ) : (
            ""
          )}
          {productInfo.period == 2 ? (
            <div className="next">
              {window.intl.get("当日存入，次日起息(UTC+8)")}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <div className="line-item">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("到账方式")}</span>
        </div>
        {productInfo.product_type == 2 ? (
          <div className="next">
            {window.intl.get("每日收益自动复存，享受滚动复利")}
          </div>
        ) : (
          ""
        )}
        {productInfo.product_type == 1 || productInfo.product_type == 3 ? (
          <div className="next">
            {window.intl.get("到期自动发放本息至余额")}
          </div>
        ) : (
          ""
        )}
      </div>
      {productInfo.product_type == 2 ? (
        <div className="line-item">
          <div className="pre">
            <i></i>
            <span>{window.intl.get("每日利息")}</span>
          </div>
          {productInfo.rate_type == 2 ? (
            <div className="next">
              {window.intl.get(
                "根据市场情况，利率实时变化，日末计算当日收益时按实时利率计算"
              )}
            </div>
          ) : (
            <div className="next">
              {window.intl.get("根据市场情况，按实时利率计算")}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
