import React, { useEffect, useRef } from "react";
import "./loading.scss";

export default function () {
  // return <div id="global-loading" className="lds-ring"><div></div><div></div><div></div><div></div></div>
  return (
    <div id="global-loading" className="lds-ring-v2">
    
    </div>
  );
}
