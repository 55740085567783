import React from "react";
import "./plan.scss";
export default function Plan(props) {
  const { productInfo } = props;
  const { amount, total_amount, item_name } = productInfo;
  let rate = 0;
  if (amount) {
    rate = ((total_amount / amount) * 100).toFixed(2);
  }
  return (
    <div className="total-rate">
      <div className="total-num-box">
        <span className="already-num">
          {window.intl.get("已抢")}&nbsp;
          {window.intl.options.currentLocale == "ar" ? "%" + rate : rate + "%"}
        </span>
        <span className="total-num">
          {window.intl.get("总额度")}：{amount} T
        </span>
      </div>
      <div className="total-line-box">
        <i style={{ width: `${rate}%` }}></i>
      </div>
    </div>
  );
}
