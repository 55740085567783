import React, { useState } from "react";
import "./stock-ueasy.scss";
import Viewer from "react-viewer";

export default function () {
  const [visible, setVisible] = React.useState(false);
  const [visibleV2, setVisibleV2] = React.useState(false);
  return (
    <div className="stock-ueasy">
      <div className="sec sec1">
        <div className="sub-content">
          {window.intl.get(
            "UEEx优易是一家面向全球的数字货币交易平台，坚持“以数字资产交易的高效和安全为核心”的长期经营理念，向用户提供BTC，ETH和LTC等数字资产的币币交易以及金融衍生品交易服务。"
          )}
        </div>

        <div className="sub-content v2">
          {window.intl.get(
            "公司创业团队具有长期的互联网和金融的研发和运营经验，团队全体人员未来将全力以赴投入区块链世界革命发展浪潮中，我们致力于成为“全球领先的数字资产高效安全运营商”，目前已暂获美国，加拿大等合规金融牌照，未来将落实全球战略布局，为全球用户提供专业极致的数字货币服务。"
          )}
        </div>
      </div>
      {/* <div className="sec sec5">
        <div className="common-btn">{window.intl.get("我们的愿景")}</div>
        <div className="sub-title">
          {window.intl.get("Uesay优易时刻倾听、关心和思进")}
        </div>
        <div className="sub-content">
          {window.intl.get(
            "我们希望可以融合加密货币和传统金融业的优势不断地创新。通过专业的团队、成熟的技术以及对安全、市场诚信和性能的坚守：我们相信，随着行业的不断成熟和监管落地的真正开始，以上要素将是业务持续发展和大规模应用的关键。"
          )}
        </div>

        <div className="sub-content">
          {window.intl.get(
            "Ueasy优易以卓越的平台能力及专业的设计理念，为我们的用户带来行业内最安全、高效、公平和透明的交易体验。"
          )}
        </div>
      </div>
      <div className="sec sec5 sec6">
        <div className="common-btn">{window.intl.get("为什么选择Ueasy？")}</div>
        <div className="sub-title">{window.intl.get("智能交易系统")}</div>
        <div className="sub-content">
          {window.intl.get("一键止盈止损并支持自定义策略设置，不错失交易良机")}
        </div>

        <div className="sub-title">{window.intl.get("全球市场深度")}</div>
        <div className="sub-content">
          {window.intl.get("全球 BTC 永续合约市场领跑者")}
        </div>

        <div className="sub-title">{window.intl.get("真实标记价格")}</div>
        <div className="sub-content">
          {window.intl.get("杜绝恶意插针，保障交易公平公正")}
        </div>
        <div className="sub-content">
          {window.intl.get("每20ms极速数据更新")}
        </div>
        <div className="sub-title">{window.intl.get("优质的客服体系")}</div>
        <div className="sub-content">
          {window.intl.get(
            "7*24小时在线沟通，VIP一对一支持 用户，无时不刻响应您的建议及疑问"
          )}
        </div>
      </div> */}
      <Viewer
        visible={visible}
        noFooter={true}
        onClose={() => {
          setVisible(false);
        }}
        images={[
          {
            src: require("../../../assets/img/financial-license/licence1-ueasy.png"),
            alt: "",
          },
        ]}
      />
      <Viewer
        visible={visibleV2}
        noFooter={true}
        onClose={() => {
          setVisibleV2(false);
        }}
        images={[
          {
            src: require("../../../assets/img/financial-license/licence1-ueasy.png"),
            alt: "",
          },
        ]}
      />
      {/* <div className="big-img">
        <span className="placeholder"></span>
        <img src="../../../assets/img/financial-license/licence1.png" alt=""/>
      </div> */}
    </div>
  );
}
