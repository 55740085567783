import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./pie.scss";
export default function Pie(prop) {
  const { productInfo } = prop;
  const options = {
    chart: {
      type: "pie",
      plotBorderWidth: 0,
      margin: [20, 0, 20, 0],
      height: 160,
      width: 160,
    },
    title: {
      enabled: false,
      text: "",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
      layout: "vertical",
      x: 100,
      y: -50,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        innerSize: "50%",
        enableMouseTracking: false,
        data: [
          {
            name: "项目运作人",
            y: productInfo.trader_profit,
            color: "#f04a5a",
          },
          {
            name: "份额持有人",
            y: productInfo.user_profit,
            color: "#3a7dff",
          },
        ],
      },
    ],
  };
  return (
    <div className="pie-component">
      <div className="title">{window.intl.get("利润分配")}</div>
      <div className="sub-title">
        {window.intl.get("项目回报在份额持有人及项目运作人之间以一定比例分配")}
      </div>
      <div className="chart-container">
        <HighchartsReact
          className="highchart-container"
          highcharts={Highcharts}
          options={options}
        ></HighchartsReact>
        <div className="legend">
          <div className="line-item no">
            <i className="fir"></i>
            <span>
              {window.intl.get("项目运作人")}{" "}
              {(productInfo.trader_profit * 100).toFixed()}%
            </span>
          </div>
          <div className="line-item">
            <i className="sec"></i>
            <span>
              {window.intl.get("份额持有人")}{" "}
              {(productInfo.user_profit * 100).toFixed()}%
            </span>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="pre">
          <i></i>
          <span>{window.intl.get("风险提示：")}</span>
        </div>
        <div className="next">
          {window.intl.get(
            "平台会尽力为用户筛选优质Defi挖矿项目，仅作为平台方进行项目展示，为用户提供代理资产接入Defi项目以及收益分发等相关服务，不承担任何因项目链上合约安全带来的损失。如果DeFi项目遭受不可预见的智能合约攻击，或是极端市场波动或系统性的流动性危机，您授权平台在可行的最早时间终止您的投资以减少您的损失"
          )}
        </div>
      </div>
    </div>
  );
}
