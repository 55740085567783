import React from "react";
import "./step.scss";
import { timestampToDate } from "@/utils/date-format.js"
export default function (props) {
  const { productInfo, pre, middle, next } = props;
  const {
    start_time,
    stop_time,
    end_time,
    status,
    quant_end_earning_time,
  } = productInfo;
  // 募集开始  status == 2
  // 募集结束  status >= 4  status <= 7
  // 产品到期  status == 7
  let active = 1;
  if (status == 2) {
    active = 1;
  } else if (status >4 && status < 7) {
    active = 2;
  } else if (status == 7) {
    active = 3;
  }
  const now_time = (new Date().getTime() / 1000).toFixed()
  let rate1 = (now_time - start_time) / (stop_time - start_time);
  rate1 = rate1 > 1 ? 1 : rate1;
  let rate2 = (now_time - stop_time) / (end_time - stop_time);
  rate2 = rate2 > 1 ? 1 : rate2;

  return (
    <div className="component-step">
      <div className="step-graph">
        <i className={`step-circle step-circle1 ${active==1||active==2||active==3?'active':''}`}></i>
        <i className={`step-circle step-circle2 ${active==2||active==3?'active':''}`}></i>
        <i className={`step-circle step-circle3 ${active==3?'active':''}`}></i>
        <div className={`step-line step-line1 ${active==2||active==3?'active':''}`}>
          <i style={{'width': `${rate1 * 100}%`}}></i>
        </div>
        <div className={`step-line step-line2 ${active==3?'active':''}`}>
        <i style={{'width': `${rate2 * 100}%`}}></i>
        </div>
      </div>
      <div className="step-text-box">
        <div className="step-block step-block1">
          <span className="step-text">{pre || window.intl.get('募集开始')}</span>
          {
            (productInfo.product_type==1||productInfo.product_type==4)?
            <span className="step-time">{timestampToDate(start_time)}</span>
            :""
          }
        </div>
        <div className="step-block step-block2">
          <span className="step-text">{middle||window.intl.get("募集结束")}</span>
          <span className="step-time">{timestampToDate(stop_time)}</span>
        </div>
        <div className="step-block step-block3">
          <span className="step-text">{next||window.intl.get("计息结束")}</span>
          {
            productInfo.product_type == 3 ?
            <span className="step-time">{timestampToDate(quant_end_earning_time)}</span>
            :
            <span className="step-time">{timestampToDate(end_time)}</span>
          }
        </div>
      </div>
    </div>
  )
}