import React from "react";
import "./chia.scss";
import Rule from "./component/rule/rule.jsx";
import Rate from "./component/rate/rate";
import Tips from "./component/tips/tips";
import Loading from "@/component/loading/loading.jsx";
import { apiGetCloudMiningProductInfo } from "@/model/chia/index";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
export default function () {
  const { product_id } = useParams();
  const [productInfo, setProductInfo] = useState({
    detail: {
      channel_id: 101,
      earnings_rule: "",
      i18n_lang: "",
      inout_rule: "",
      intro_url: "https://help.topcreditbank.com/#/ico-introduction",
      product_id: 1,
      product_intro: "",
      risk_tip: "",
    },
  });
  const [rateList, setRateList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const req = {
      product_id,
    };
    apiGetCloudMiningProductInfo(req).then((rs) => {
      setLoading(false);
      if (rs.status == 200) {
        if (JSON.stringify(rs.data) != "{}") {
          rs.data.item_url = rs.data.item_data[rs.data.item_id].item_url;
          rs.data.item_name = rs.data.item_data[rs.data.item_id].item_name;
          rs.data.to_item_name =
            rs.data.item_data[rs.data.to_item_id].item_name;
        }

        // JsCallFlutter.postMessage(`{
        // "msgType" "coinExchange",
        //   "symbolId": "22",
        //   "coinPair": "BTC/USDT"
        // }`);

        setProductInfo(rs.data);
      }
    });
  }, []);

  return (
    <div className="chia">
      {!loading ? (
        <React.Fragment>
          <Rate productInfo={productInfo} />
          <Rule productInfo={productInfo} detail={productInfo.detail} />
          <Tips detail={productInfo.detail}></Tips>
          <i className="gap"></i>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
}
