import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "./pie.scss"
export default function Pie (prop) {
  const { productInfo } = prop;
  const options = {
    chart: {
      type: "pie",
      plotBorderWidth: 0,
      margin: [20, 0, 20 , 0],
      height: 160,
      width: 160,
    },
    title: {
      enabled: false,
      text: '',
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      x: 100,
      y: -50,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      }
    },
    series: [
      {
        innerSize: '50%',
        enableMouseTracking: false,
        data: [
          {
            name: '项目运作人',
            y: productInfo.trader_profit,
            color: '#f04a5a',
          },
          {
            name: '份额持有人',
            y: productInfo.user_profit,
            color: '#3a7dff',
          },
        ]
      }
    ]
  }
  return (
    <div className="pie-component">
      <div className="title">{window.intl.get("利润分配")}</div>
      <div className="sub-title">{window.intl.get('项目回报在份额持有人及项目运作人之间以一定比例分配')}</div>
      <div className="chart-container">
        <HighchartsReact
          className="highchart-container"
          highcharts={Highcharts}
          options={options}
        ></HighchartsReact>
        <div className="legend">
          <div className="line-item no">
              <i className="fir"></i>
              <span>{window.intl.get('项目运作人')} {(productInfo.trader_profit * 100).toFixed()}%</span>
            </div>
            <div className="line-item">
              <i className="sec"></i>
              <span>{window.intl.get('份额持有人')} {(productInfo.user_profit * 100).toFixed()}%</span>
            </div>
        </div>
      </div>
    </div>
  )
}