import React from "react";
import "./defi-mining-introduction.scss";
import { useState, useEffect } from "react";
export default function () {
  // const lang = window.intl.options.currentLocale;
  useEffect(() => {
    document.title = window.intl.get("DeFi挖矿");
    // JsCallFlutter.postMessage({
    //   "msgType": "coinExchange",
    //   "symbolId": "22",
    //   "coinPair": "BTC/USDT"
    // })
  }, []);
  return (
    <div className="defi-mining-introduction">
      <div className="placeholder-v3"></div>
      <div className="sec">
        <div className="common-component-btn">
          <div className="title-content">
            <span className="ico-text">
              {window.intl.get("DeFi 挖矿全名为流动性挖矿 ")}
            </span>
          </div>
        </div>
        <div className="sub-title">{window.intl.get("流动性挖矿是什么?")}</div>
        <div className="info-content">
          <p className="first-item">
            {window.intl.get(
              "流动性挖矿，也称为收益农场，是一种通过持有加密货币产生收益的方式。简单来说，锁定加密货币即可获得奖励。"
            )}
          </p>
          <p>
            {window.intl.get(
              "从某种意义上讲，流动性挖矿可以与质押同时进行。但是，从两者的背景上看，仍然存在许多复杂性。在许多情况下，流动性挖矿通常和称为流动性提供者（LP）的使用者合作，这些使用者向流动性资金池中注资。"
            )}
          </p>
          <p>
            {window.intl.get(
              "什么又是流动性资金池？基本上可以将其视为一个包含资金的智慧合约。通过向资金池中注资，获取流动性的回报，流动性提供者可以获得奖励。奖励可能来自底层DeFi平台或其他来源所产生的收益。"
            )}
          </p>
          <p>
            {window.intl.get(
              "某些流动资金池提供了多种代币支付奖励。之后，也可以将这些奖励代币存入其他流动性资金池中，同样可以在那里赚取奖励，并依此类推。现在您应该可以理解，为什么会迅速出现很多如此非常复杂的策略。但其本质方式，都是流动性提供者将资金存入流动性资金池中并获得收益回报。"
            )}
          </p>
        </div>
        <div className="title-content V2">
          <span className="ico-text">
            {window.intl.get("APY或APR是什么意思?")}
          </span>
        </div>
        <div className="info-content">
          <p className="first-item">
            {window.intl.get(
              "通常，动性挖矿的收益是按年计算的。这样可以估算您一年内可以获得的预期收益。"
            )}
          </p>
          <p>
            {window.intl.get(
              "常用的度量标准是年化利率（APR）和年化收益比率（APY）。它们之间的区别在于，APR不考虑复利的影响，而APY则需要考虑。在这种情况下，复利意味着直接将利润再投资以产生更多的回报，还应该注意，这两个值只是估计和预测的指标。即使是短期收益也很难准确估计。为什么呢？因为流动性挖矿是一个竞争激烈且节奏很快的市场，其收益会迅速波动。如果流动性挖矿策略行之有效，许多矿工都会去抓住机会，大量的矿工涌入可能会导致高收益率迅速下降"
            )}
          </p>
        </div>
        <div className="title-content V3">
          <span className="ico-text">{window.intl.get("风险提示")}</span>
        </div>
        <div className="sub-title V2">
          {window.intl.get(
            "收益率会根据农场收益每日调整，具体收益数值以当天页面展示为准"
          )}
        </div>
        <div className="sub-title V2">
          {window.intl.get(
            "数字货币交易存在巨大风险，请您谨慎购买，并注意交易风险。平台会遴选优质币种，但不对您的任何交易行为承担担保、赔偿等责任。"
          )}
        </div>
        <div className="sub-title V3"></div>
      </div>
    </div>
  );
}
