import "./fee-detail.scss";
import React from "react";
export default function () {
  // const lang = window.$.cookie('lang')
  // if (lang == 'zh_CN') {
  //   window.location.href = 'https://top6688.zendesk.com/hc/zh-cn'
  // } else {
  //   window.location.href = 'https://top6688.zendesk.com/hc/en-us'
  // }
  // return;
  return (
    <div className="page-fee-detail">
      <table>
        <thead>
          <tr>
            <th>{window.intl.get("类目")}</th>
            <th>{window.intl.get("交易币种/交易方向")}</th>
            <th>{window.intl.get("手续费")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="first">{window.intl.get("法币充值")}</td>
            <td>-</td>
            <td>0</td>
          </tr>
          <tr>
            <td className="first">{window.intl.get("法币提现")}</td>
            <td>-</td>
            <td>2.5%</td>
          </tr>
          <tr>
            <td className="first">{window.intl.get("数字货币收款")}</td>
            <td>-</td>
            <td>0</td>
          </tr>
          <tr>
            <td className="first">{window.intl.get("数字货币划转(站内)")}</td>
            <td>-</td>
            <td>0</td>
          </tr>
          <tr>
            <td rowSpan={7} className="first">
              {window.intl.get("数字货币划转(站外)")}
            </td>
            <td>BTC</td>
            <td>0.001 BTC</td>
          </tr>
          <tr>
            <td>ETH</td>
            <td>0.008 ETH</td>
          </tr>
          <tr>
            <td>USDT-ERC20</td>
            <td>60 USDT</td>
          </tr>
          <tr>
            <td>USDT-TRC20</td>
            <td>1 USDT</td>
          </tr>
          <tr>
            <td>USDT-OMNI</td>
            <td>20 USDT</td>
          </tr>
          <tr>
            <td>USDT-HECO</td>
            <td>1 USDT</td>
          </tr>
          <tr>
            <td>USDT-BSC</td>
            <td>1 USDT</td>
          </tr>
          <tr>
            <td className="first">{window.intl.get("币币交易")}</td>
            <td>{window.intl.get("买/卖")}</td>
            <td>0.09%</td>
          </tr>
          <tr>
            <td className="first">{window.intl.get("U本位合约")}</td>
            <td>{window.intl.get("开仓/平仓")}</td>
            <td>0.05%</td>
          </tr>
          <tr>
            <td className="first">{window.intl.get("币本位合约")}</td>
            <td>{window.intl.get("开仓/平仓")}</td>
            <td>0.1%</td>
          </tr>
          <tr>
            <td rowSpan={5} className="first">
              {window.intl.get("超级杠杆交易")}
            </td>
            <td>{window.intl.get("开仓/平仓：杠杆倍数 >0 <100")}</td>
            <td>0.1%</td>
          </tr>
          <tr>
            <td>{window.intl.get("开仓/平仓：杠杆倍数≥100 <200")}</td>
            <td>0.09%</td>
          </tr>
          <tr>
            <td>{window.intl.get("开仓/平仓：杠杆倍数≥200 <300")}</td>
            <td>0.08%</td>
          </tr>
          <tr>
            <td>{window.intl.get("开仓/平仓：杠杆倍数≥300 <400")}</td>
            <td>0.07%</td>
          </tr>
          <tr>
            <td>{window.intl.get("开仓/平仓：杠杆倍数≥400 ≤500")}</td>
            <td>0.06%</td>
          </tr>
          <tr>
            <td className="first">{window.intl.get("股票交易")}</td>
            <td>{window.intl.get("买/卖")}</td>
            <td>1.5%</td>
          </tr>
          <tr>
            <td rowSpan={3} className="first">
              {window.intl.get("小游戏/期权")}
            </td>
            <td>{window.intl.get("疯狂竞拍")}</td>
            <td>10%</td>
          </tr>
          <tr>
            <td>{window.intl.get("猜涨跌")}</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>{window.intl.get("幸运哈希")}</td>
            <td>5%</td>
          </tr>
          <tr>
            <td rowSpan={4} className="first">
              {window.intl.get("汇兑")}
            </td>
            <td>{window.intl.get("数字货币->法币")}</td>
            <td>0</td>
          </tr>
          <tr>
            <td>{window.intl.get("法币->数字货币")}</td>
            <td>2.5%</td>
          </tr>
          <tr>
            <td>{window.intl.get("数字货币->数字货币")}</td>
            <td>0.09%</td>
          </tr>
          <tr>
            <td>{window.intl.get("法币->法币")}</td>
            <td>0.3%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
