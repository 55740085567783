import React from "react";
import "./problem-detail.scss";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
export default function () {
  const { articleId } = useParams();
  const problemMap = {
    title: {
      1: window.intl.get("美股交易时间"),
      2: window.intl.get("美股熔断机制"),
      3: window.intl.get("港股交易时间"),
      4: window.intl.get("港股市场波动调节机制"),
      5: window.intl.get("高送转(拆分股)机制"),
    },
    content: {
      1: window.intl.get(
        "（1）常规交易时间：美国东部时间：周一至周五 9:30至16:00，午间不休市。美国夏令时，北京时间(21:30至次日4:00)；美国冬令时，北京时间(22:30至次日5:00)。<br />补充说明：夏令时为每年3月的第二个星期日至11月的第一个星期日，冬令时为每年11月的第一个星期日至次年3月的第二个星期日。<br />（2）盘前盘后交易时间：在正常交易时段外，美股也允许盘前和盘后交易。目前证券App已支持美股盘前盘后交易。<br />证券盘前交易时间：美国东部时间4:00至9:30。美国夏令时，北京时间16:00至21:30；美国冬令时，北京时间17:00至22:30。<br />证券盘后交易时间：美国东部时间16:00至20:00。美国夏令时，北京时间4:00至8:00；美国冬令时，北京时间5:00至9:00。"
      ),
      2: window.intl.get(
        "熔断机制 (Circuit Breaker) ，也叫自动停盘机制，是指当股指波幅达到规定的熔断点时，交易所为控制风险采取的暂停交易措施。<br />（1）大盘熔断；美股实行三级熔断机制，以标普500指数为例，当指数下跌5%时，将暂时停盘15分钟；当股指下跌10%时，将会停盘1小时；当股指出现20%的暴跌时，将会关闭股市1天。<br />（2）个股熔断；根据美国证交会 (SEC) 的最新规定，熔断机制除针对股市大盘的熔断机制外，还设有“限制价格波动上下限”的机制。如果某只个股的交易价格在5分钟内涨跌幅超过10%，则需暂停交易。如果该个股交易价格在15秒钟内仍未回到规定的“价格波动区间”内，将暂停交易5分钟。对于标普500指数和罗素1000指数成分股以及430只交易所交易产品中价格超过3美元的个股，SEC规定的“价格波动区间”为涨跌幅5%，其他交易价格在3美元以下的流动性较弱的个股“价格波动区间”放宽至10%。"
      ),
      3: window.intl.get(
        "港股在周一到周五进行交易，周六周日和公众假日休市。交易时间如下：(香港時間)<br />（1）正常交易时段：9:30至12:00；13:00至16:00。<br />（2）早盘竞价时段：09:00至09:20；收市竞价交易： 16:00至16:10。<br />港股交易單位：<br />买卖港股的最小单位是一手。和A股不同，港股中每手所含的股票数量根据股票价格来决定，1手可能是100股、500股、1000股和2000股不等，如目前的港股中腾讯控股为每手100股，金山软件则是每手1000股。"
      ),
      4: window.intl.get(
        "当股票或期货合约的价格达到触发价格范围内，港股市场波动调节机制(简称VCM)会被触发，启动5分钟的冷静期，令交易限于指定价格限制范围内进行，正常交易将于5分钟后重启。<br />（1）触发价格范围：证券市场：参考价变动幅度超过±10%；衍生产品市场：参考价变动幅度超过±5%；参考价：5分钟前最后一次交易。<br />（2）市场范围：恒指及恒生国企指数成分股(现有81支)及相关指数期货合约(现有8支)。<br />（3）适用时段：只适用于持续交易时段，不包括早市及午市前15分钟、午市最后15分钟。<br />（4）交易价格：5分钟冷静期内，交易价格仅能在触发价格限制范围内，即参考价的±10%/±5%(根据不同市场区分)。"
      ),
      5: window.intl.get(
        "高送转也称为拆合股，意思就是将股票进行切割，股数增多，股价降低，但总价值不变。拆股有利于扩大投资者基础，吸引更多投资者参与，增加交易量和流动性。例如，腾讯控股曾经在2014年5月15日由1股拆分为5股。 <br />合股是拆股的相反过程，即由多股合并为1股，在合股过程中，股东持有股票的总价值不变，但是持仓数量会减少。 <br />对用户来说 总价值是不变的 只是数量增减有所变化"
      ),
    },
  };
  return (
    <div className="page-problem-detail">
      <div className="article-title">{problemMap.title[articleId]}</div>
      {/* <div className="article-date">{articleInfo.update_time}</div> */}
      <div
        className="article-content"
        dangerouslySetInnerHTML={{ __html: problemMap.content[articleId] }}
      ></div>
    </div>
  );
}
