import React, { useState } from "react";
import "./user-agreement.scss";

export default function () {
  return (
    <div className="user-agreement">
      <div className="article-title">《{window.intl.get("用户协议")}》</div>
      <div className="article-content">
        {window.intl.get("尊敬的用户：")}
        <br />
        {window.intl.get(
          "当你通过直接或间接参与平台推出的移动客户端跟单服务即视为您同意和遵守本服务协议条款，包括本网站对本协议随时所做的任何修改，因此请您仔细阅读以下跟单服务条款规则。"
        )}
        <br />
        <br />
        {window.intl.get("协议内容")}
        <br />
        {window.intl.get("一、客户资格")}
        <br />
        {window.intl.get(
          "1、客户应当为符合本协议相关交易法律法规要求的合格投资者。"
        )}
        <br />
        {window.intl.get(
          "2、客户已年满18周岁，具有完全民事权利能力及民事行为能力，具有足够的知识与经验可以理解将要交易的产品的性质与风险。"
        )}
        <br />
        {window.intl.get(
          "3、您是您在账户中添加的资金的合法所有人，保证资金或数字资产来源的合法性，不得借助平台做违法行为。"
        )}
        <br />
        {window.intl.get(
          "4、您提供的任何加密货币提款地址都是您自己的，并且您对该地址拥有完全控制权。"
        )}
        <br />
        <br />
        {window.intl.get("二、交易服务")}
        <br />
        {window.intl.get(
          "您应自行谨慎判断相关比特币等虚拟货币/信息的真实性、合法性和有效性，并自行承担此产生的责任与损失。除非法律法规明确要求，否则，平台没有义务对所有用户的信息数据、比特币等拟货币信息、交易行为以及与交易有关的其它事项进行事先审查。"
        )}
        <br />
        <br />
        {window.intl.get("三、纠纷及法律义务")}
        <br />
        {window.intl.get(
          "1、您在平台使用一键跟单服务进行交易过程中与其他用户发生交易纠纷时，一旦您或其它用户任一方或共同提交平台要求调解处理时，平台有权根据单方判断做出调处决定，您了解并同意接受平台对于交易纠纷的判断和调处决定。"
        )}
        <br />
        {window.intl.get(
          "2、您了解并同意平台有权应政府部门（包括司法及行政部门）的要求向其提供您向平台提供的用户信息交易记录等必要信息。如您涉嫌侵犯他人知识产权等合法权益行为时，平台有权在初步判断涉嫌侵权、法行为存在的情况下，向权利人、监管或法律部门提供您必要的身份信息。"
        )}
        <br />
        <br />
        {window.intl.get("四、免责申明")}
        <br />
        {window.intl.get(
          "1、您知情并同意平台不对因下述任一情况而导致的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿（无论我们是否已被告知该等损害赔偿的可能性），平台有合理的理由认为特定会员及具体交易事项可能存在重大违法或违约情形。平台有合理的理由认为用户在平台的行为涉嫌违法或不当。通过平台服务购买或获取任何数据、信息或进行交易等行为或替代行为生的费用及损失。您对平台一键跟单服务的误解。任何非因平台一键跟单服务的原因而引起的与平台跟服务有关的其它损失。您直接或间接参与跟单交易导致的任何部分或全部亏损。"
        )}
        <br />
        {window.intl.get(
          "2、一键跟单交易功能相关的风险，包括但不限于自动交易操作，即您的账户在没有您手动干预的情况下可以开始和结束交易。"
        )}
        <br />
        {window.intl.get(
          "3、一键跟单交易的延时性可能产生高额成本，一键跟单交易可能出现失败的情况。"
        )}
        <br />
        {window.intl.get(
          "4、投资管理服务由您自行选择，您自行决定跟单特定交易者以及/或遵从特定交易策略。在作出此等决时，您已经考虑了包括财务规划在内的自身整体财务状况，并且了解使用一键跟单交易功能具有极高的机性，您可能招致的损失比用以跟单此交易者的金额还要大得多。"
        )}
        <br />
        {window.intl.get(
          "5、我们所提供的一键跟单交易功能仅供参考。如果您根据我们网站上提供的资料或通过一键跟单交易功获取的资料作出投资决定，您应自行承担风险。"
        )}
        <br />
        {window.intl.get(
          "6、您应自主研判再作出投资决定。您应根据自身投资目标和个人及财务状况自行独立判断投资、策略、任何其他产品及服务是否符合您自身需要。"
        )}
        <br />
        {window.intl.get(
          "7、因使用跟单交易功能自动执行操作招致损失，您应自主全权承担责任。"
        )}
        <br />
        {window.intl.get(
          "8、网站或客户端上的任何信息旨在为公众提供交易者和追随者的交易动态和信息服务。本网站不提供任形式的投资建议，也绝不以任何形式暗示提供此类信息或功能。您应对通过此平台或一键跟单交易功能集的信息进行独立研究并自主作出投资决定。"
        )}
        <br />
        {window.intl.get("9、未尽事宜平台保留法律范围内的最终解释权。")}
      </div>
    </div>
  );
}
