import React, { useState } from "react";
import Stock from "./stock/stock.jsx";
import Digital from "./digital/digital.jsx";
import "./digital-currency.scss";
// const Problem = React.lazy(() => import("./problem/problem.jsx"));


export default function () {
  const tabList = [
    {
      value: 1,
      label: window.intl.get('股票')
    },
    {
      value: 2,
      label: window.intl.get('数字货币')
    }
  ];
  const [tabIndex, setTabIndex] = useState(() => {
    const currTab = localStorage.getItem('business-compliance-tab-pos') || 1;
    return currTab;
  });

  return (
    <div className="page-digital-currency">
      {/* <div className="business-header">
          <div className="herder-title">
            <div className="title">
            </div>
          </div>
      </div> */}
      <Digital />
    </div>
  )
}