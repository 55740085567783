import React from "react";
import "./step.scss";
import { timestampToDate, timestamp } from "@/utils/date-format.js";
export default function (props) {
  const { productInfo, pre, middle, next } = props;
  const {
    start_time,
    stop_time,
    end_time,
    earning_start_time,
    earning_stop_time,
    status,
    schedule_type,
    quant_end_earning_time,
  } = productInfo;
  //schedule_type  1募集开始 2募集结束 3开始挖矿 4算力到期
  let active = 1;
  if (schedule_type == 1) {
    active = 1;
  } else if (schedule_type == 2) {
    active = 2;
  } else if (schedule_type == 3) {
    active = 3;
  } else if (schedule_type == 4) {
    active = 4;
  } else {
    active = 0;
  }
  const now_time = (new Date().getTime() / 1000).toFixed();
  let rate1 = (now_time - start_time) / (end_time - start_time);
  rate1 = rate1 > 1 ? 1 : rate1;
  let rate2 = (now_time - end_time) / (earning_start_time - end_time);
  rate2 = rate2 > 1 ? 1 : rate2;
  let rate3 =
    (now_time - earning_start_time) / (earning_stop_time - earning_start_time);
  rate3 = rate3 > 1 ? 1 : rate3;

  return (
    <div className="component-step-chia">
      <div className="step-graph">
        <i
          className={`step-circle step-circle1 ${
            active == 1 || active == 2 || active == 3 || active == 4
              ? "active"
              : ""
          }`}
        ></i>
        <i
          className={`step-circle step-circle2 ${
            active == 2 || active == 3 || active == 4 ? "active" : ""
          }`}
        ></i>
        <i
          className={`step-circle step-circle3 ${
            active == 3 || active == 4 ? "active" : ""
          }`}
        ></i>
        <i
          className={`step-circle step-circle4 ${active == 4 ? "active" : ""}`}
        ></i>
        <div
          className={`step-line step-line1 ${
            active == 2 || active == 3 || active == 4 ? "active" : ""
          }`}
        >
          <i style={{ width: `${rate1 * 100}%` }}></i>
        </div>
        <div
          className={`step-line step-line2 ${
            active == 3 || active == 4 ? "active" : ""
          }`}
        >
          <i style={{ width: `${rate2 * 100}%` }}></i>
        </div>
        <div className={`step-line step-line3 ${active == 4 ? "active" : ""}`}>
          <i style={{ width: `${rate3 * 100}%` }}></i>
        </div>
      </div>
      <div className="step-text-box">
        <div className="step-block step-block1">
          <span className="step-text">
            {pre || window.intl.get("申购开始")}
          </span>
          <span className="step-time">{timestampToDate(start_time)}</span>
        </div>
        <div className="step-block step-block2">
          <span className="step-text">
            {middle || window.intl.get("申购结束")}
          </span>
          <span className="step-time">{timestampToDate(end_time)}</span>
        </div>
        <div className="step-block step-block3">
          <span className="step-text">
            {next || window.intl.get("开始挖矿")}
          </span>
          <span className="step-time">
            {timestampToDate(earning_start_time)}
          </span>
        </div>
        <div className="step-block step-block4">
          <span className="step-text">
            {next || window.intl.get("算力到期")}
          </span>
          <span className="step-time">
            {timestamp(earning_stop_time, "yyyy.MM.dd")}
          </span>
        </div>
      </div>
    </div>
  );
}
