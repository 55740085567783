import React from "react";
import "./rule.scss";
export default function () {
  // const lang = window.intl.options.currentLocale;
  const isS8v = window.location.host.toUpperCase().indexOf("8V") != -1;
  return (
    <div className="page-component-rule">
      <div className="sec sec1">
        <div className="common-component-btn">
          {window.intl.get("产品特点")}
        </div>
        <div className="sub-title">{window.intl.get("低门槛")}</div>
        <div className="sub-content">
          {window.intl.get(
            "无需学习专业名词，快速上手，极速开仓；交易多个标的，均以USDT结算"
          )}
        </div>

        <div className="sub-title">{window.intl.get("公平公正")}</div>
        <div className="sub-content">
          {window.intl.get(
            "交易标的价格来自知名交易所加权合成，避免因价格偏离或被操控而产生损失"
          )}
        </div>

        <div className="sub-title">{window.intl.get("低成本")}</div>
        <div className="sub-content">
          {window.intl.get(
            "50 USDT即可开仓，无借币利息、无资金费率、无持仓过夜费等其他任何费用"
          )}
        </div>

        <div className="sub-title">{window.intl.get("高盈利")}</div>
        <div className="sub-content">
          {window.intl.get(
            "支持买涨买跌，双向交易，涨跌均可盈利；最高500倍杠杆，盈利放大500倍"
          )}
        </div>

        {/* <ul className="box-info">
          <li>
            <span>{window.intl.get('交易时段')}</span>
            <span>7*24H</span>
          </li>
          <li>
            <span>{window.intl.get('最小开仓本金')}</span>
            <span>1USDT</span>
          </li>
          <li>
            <span>{window.intl.get('最大杠杆')}</span>
            <span>100x</span>
          </li>
          <li>
            <span>{window.intl.get('结算币种')}</span>
            <span>USDT</span>
          </li>
        </ul> */}
      </div>
      <div className="sec sec3">
        <div className="common-component-btn">
          {window.intl.get("交易规则")}
        </div>
        {/* <div className="placeholder"></div> */}
        <div className={["placeholder", isS8v ? "s8v" : null].join(" ")}></div>
      </div>
      <div className="sec sec2">
        <div className="common-component-btn">
          {window.intl.get("手续费表")}
        </div>
        {/* <div className="placeholder"></div> */}
        <div className={["placeholder", isS8v ? "s8v" : null].join(" ")}></div>
        <p className="fee">
          {window.intl.get(
            "开仓手续费：开仓本金*杠杆倍数*开仓手续费率，计入持仓盈亏"
          )}
          <br />
          {window.intl.get(
            "平仓手续费：开仓本金*杠杆倍数*平仓手续费率，计入平仓盈亏"
          )}
          <br />
          {/* {window.intl.get('平仓线：当某笔持仓的本金亏损至仅剩余10%时，系统自动强平')} */}
        </p>
      </div>

      <div className="sec sec4">
        <div className="title">{window.intl.get("买涨示例")}</div>
        <div className="sub-title">{window.intl.get("低价买入 高价卖出")}</div>
        <div className="placeholder"></div>
      </div>
      <div className="sec sec5">
        <div className="title">{window.intl.get("买跌示例")}</div>
        <div className="sub-title">{window.intl.get("高价卖出 低价买入")}</div>
        <div className="placeholder"></div>
      </div>
      <div className="sec sec6">
        <div className="common-component-btn">
          {window.intl.get("自动减仓")}
        </div>
        <div className="sub-content">
          {window.intl.get(
            "当风险保障基金无法接管穿仓用户的仓位时，才会发生对手方平仓。平台会尽可能避免这种情况的发生，例如通过Fill and Kill的方式，尽量减少对手方平仓所带来的冲击影响。由于数字货币市场的高波动性，以及超级杠杆的高杠杆，这种情况还是有可能会发生。为了提供最佳的用户体验，我们尽最大努力去降低这种情况发生的可能。"
          )}
        </div>
        <div className="sub-content">
          {window.intl.get(
            "当发生对手方平仓时，我们会立即向受影响的用户发出通知。此时，用户的仓位将以破产价格进行平仓。当对手方平仓完成后，用户可以随时重新开仓。"
          )}
        </div>
        <div className="sub-content">
          {window.intl.get("需注意的是，破产价格可能不在市场成交价格范围内;")}
        </div>
        <div className="sub-content">
          {window.intl.get(
            "用户在优先级排序的位置是基于用户的盈利与杠杆倍数来计算的。通常盈利越多，杠杆倍数越高的交易者将首先被平仓。具体来说，用户将根据他们的保证金比率和未实现盈亏作为抵押品的百分比来进行排名。这种排名被称为“LeveragePnl”，计算方式为：未实现盈亏/保证金*保证金比率。具体公式，详见末端。"
          )}
        </div>
        <div className="sub-content">
          {window.intl.get(
            "在这种计算下，用户会根据杠杆收益进行排序。当发生对手方平仓时，用户将收到平仓金额和平仓价格的通知。用户在被自动减仓后能够立即重新进入。"
          )}
        </div>
        <ul>
          <li>
            <i></i>
            {window.intl.get(
              "盈亏比率= Max（0，未实现盈亏）/ Max（1，账户余额）"
            )}
          </li>
          <li>
            <i></i>
            {window.intl.get("如果（账户余额+未实现盈亏）≤0，则保证金率=0")}
          </li>
          <li>
            <i></i>
            {window.intl.get(
              "如果（账户余额+未实现盈亏）＞0，则保证金率=维持保证金/（账户余额+未实现盈亏)"
            )}
          </li>
          <li>
            <i></i>
            {window.intl.get("杠杆收益=保证金率*盈亏百分比")}
          </li>
          <li>
            <i></i>
            {window.intl.get(
              "杠杆收益排序区间 = 排名(用户杠杆收益) / 总用户数量"
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
