import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./chart.scss";
import { timestampToDay } from "@/utils/date-format.js";
import { useRef } from "react";
export default function (props) {
  let { rateList } = props;
  const [pointDate, setPointDate] = useState("");
  const [pointRate, setPointRate] = useState("");
  const chartRef = useRef();
  const xList = rateList.reduce((accumulate, curr) => {
    return [...accumulate, curr.date];
  }, []);
  const yList = rateList.reduce((accumulate, curr) => {
    return [...accumulate, +curr.rate];
  }, []);
  useEffect(() => {
    const x = xList[xList.length - 1];
    const y = yList[yList.length - 1];
    const temp = timestampToDay(x);
    setPointDate(temp);
    setPointRate(`${(y * 100).toFixed(2)}`);
  }, [rateList]);
  Highcharts.setOptions({
    lang: {
      rangeSelectorZoom: "",
    },
  });
  const options = {
    colors: ["#f04a5a"],
    title: {
      enabled: false,
      align: "left",
      text: "",
    },
    chart: {
      type: "areaspline",
      plotBorderWidth: 0,
      margin: [20, 0, 20, 50],
      height: "200px",
      animation: false,
      events: {
        // load: function() {
        //   setTimeout(() => {
        //     var points = chartRef.current.series[0].points;
        //     chartRef.current.tooltip.refresh(points[points.length- 1]);
        //   })
        // }
      },
    },
    xAxis: {
      categories: xList,
      tickWidth: 0,
      labels: {
        xList,
        style: {
          fontSize: "8px",
        },
      },
      gridLineWidth: 0,
    },
    yAxis: {
      // type: 'logarithmic',
      title: {
        enabled: false,
      },
      labels: {
        formatter: function (val) {
          return (val.value * 100).toFixed(2) + "%";
        },
        style: {
          fontSize: "8px",
        },
      },
      gridLineWidth: 1,
      gridLineColor: "#eee",
    },
    series: [
      {
        // data: [0.2, 0.21, 0.23, 0.22, 0.25, 0.27, 0.28, 0.3,0.29, 0.23, 0.4, 0.1, 0.1, 0.2, 0.4, 0.2, 0.2, 0.21, 0.23, 0.22, 0.25, 0.27, 0.28, 0.3,0.29, 0.23, 0.4, 0.1, 0.1, 0.2, 0.4, 0.2 ],
        data: yList,
        fillOpacity: 0.1,
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "rgba(255, 74, 120, 0.31)"],
            [1, "rgba(255, 134, 165, 0.09)"],
          ],
        },
        marker: {
          enabled: true,
        },
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      style: {
        color: "#fff",
        fontSize: "10px",
      },
      formatter: function (val) {
        const temp = timestampToDay(this.x);
        setPointDate(temp);
        const y = `${(this.y * 100).toFixed(2)}`;
        setPointRate(y);
        const result = `
          ${y}%<br />
          ${this.x}
        `;
        return result;
      },
      shadow: false,
      borderWidth: 0,
      borderRadius: 8,
      backgroundColor: "#f04a5a",
      padding: 4,
      shadow: false,
    },
  };
  const afterChartCreated = (c) => {
    chartRef.current = c;
  };
  return (
    <div>
      {rateList.length ? (
        <div className="component-chart">
          <div className="title">{window.intl.get("每日利率")}</div>
          <div className="chart-title">
            <div>
              <span className="pre">{window.intl.get("日期")}</span>
              <span>{xList[[xList.length - 1]]}</span>
            </div>
            <div>
              <span className="pre">{window.intl.get("年化利率")}</span>
              <span>
                {pointRate > 0 ? (
                  <i className="positive">{` ${pointRate}%`}</i>
                ) : (
                  <i className="negative">{` ${pointRate}%`}</i>
                )}
              </span>
            </div>
          </div>
          <HighchartsReact
            className="highchart-container"
            highcharts={Highcharts}
            options={options}
            callback={afterChartCreated}
          ></HighchartsReact>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
