import React from "react";
import "./privacyAgreement.scss";
export default function () {
  return (
    <div className="privacy-agreement">
      <div className="title">隐私协议</div>
      <div className="content">
        老虎交易所及相关移动应用（以下简称“平台”），并基于该平台为用户提供数字货币之间的交易及相关服务。
      </div>
      <div className="content">
        平台尊重并保护所有使用平台网络服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，平台会按照本隐私权政策的规定使用和披露您的个人信息。您在同意平台注册服务协议之时，即视为您已经同意本隐私协议全部内容。本隐私权协议属于平台网络服务使用协议不可分割的一部分。请注意平台会不时地检查并更新隐私条款，希望您定期查阅本协议内容以确保了解最新版本的隐私政策。
      </div>
      <div className="second-title">第一条 适用范围</div>
      <div className="content">
        A）在您注册平台账号时，您根据平台要求提供的个人信息；
        <br />
        B）在您使用平台网络服务，或访问平台平台网页时，平台自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；
        <br />
        C）平台通过合法途径从商业伙伴处取得的用户个人数据
        <br />
        您了解并同意，以下信息不适用本隐私权政策：
        <br />
        A）您在使用平台提供的搜索服务时输入的关键字信息；
        <br />
        B）平台收集到的您在平台发布的有关信息数据，包括但不限于论坛发布的帖子；
        <br />
        C）违反法律规定或违反平台规则行为及平台已对您采取的措施。
      </div>
      <div className="second-title">第二条 信息使用</div>
      <div className="content">
        A）为服务用户的目的，平台可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与平台合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。
        <br />
        B）用户提供给平台的信息，平台会进行收集、整理和妥善处置。如平台与合作伙伴公司共同推出的服务，用户使用时，平台可能必须与合作公司共享用户信息。平台将督促合作公司参照本政策的规定保护用户信息。
      </div>
      <div className="second-title">第三条 信息披露</div>
      <div className="content">
        在如下情况下，平台将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
        <br />
        A）经您事先同意，向第三方披露；
        <br />
        B）为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
        <br />
        C）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
        <br />
        D）如您出现违反有关法律、法规或者平台服务协议或相关规则的情况，需要向第三方披露；
        <br />
        E）如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
        <br />
        F）其它平台根据法律、法规或者网站政策认为合适的披露。
        <br />
      </div>
      <div className="second-title">第四条 信息存储和交换</div>
      <div className="content">
        平台收集的有关您的信息和资料将保存在平台及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或平台收集信息和资料所在地的境外并在境外被访问、存储和展示。
      </div>
      <div className="second-title">第五条 Cookie的使用</div>
      <div className="content">
        A）在您未拒绝接受cookies的情况下，平台会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的平台服务或功能。平台使用cookies可为您提供更加周到的个性化服务，包括推广服务。
        <br />
        B）您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的平台网络服务或功能。
        <br />
        C）通过平台所设cookies所取得的有关信息，将适用本政策。
      </div>

      <div className="second-title">第六条 信息安全</div>
      <div className="content">
        A）平台账号均有安全保护功能，请妥善保管您的用户名及密码信息。平台将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
        <br />
        B）在使用平台网络服务进行网上交易时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是平台用户名及密码发生泄露，请您立即联络平台客服，以便平台采取相应措施。
      </div>
      <div className="second-title">第七条 免责</div>
      <div className="content">
        下列情况时本网站亦毋需承担任何责任：
        <br />
        A）由于您将用户密码告知他人或与他人共享注册账户，由此导致的任何个人资料泄露。
        <br />
        B）任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营之不可抗力而造成的个人资料泄露、丢失、被盗用或被篡改等。
        <br />
        C）由于与本网站链接的其它网站所造成之个人资料泄露及由此而导致的任何法律争议和后果。
      </div>
      <div className="second-title">第八条 外部链接</div>
      <div className="content">
        平台包含一些外部网站的链接。平台对这些网站的隐私管理和行为不承担责任。当跳转到外部网站时，用户请阅读该网站的隐私政策。这里提供的隐私条例仅供平台收集管理用户信息所用
      </div>
      <div className="second-title">第九条 与网站联系</div>
      <div className="content">
        如果你有与隐私保护，政策实施或网站操作有关的问题，请将意见或建议发送至我们的邮箱:
        support@tigerexchange.com 。
      </div>
    </div>
  );
}
