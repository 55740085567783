export default class Socket {
    constructor(option) {
        this.option = option;
        this.init();
    }
    getInstance() {
        return this.socket;
    }
    sub(data, option = {}) {
        // callback 返回的参数 1.正常 2.断线 3.ws链接异常
        if (!window.navigator.onLine) {
            option.callback && option.callback(2);
            return;
        }
        if (this.socket.readyState == this.socket.OPEN) {
            if (typeof data == "object") {
                this.socket.send(JSON.stringify(data));
            } else {
                this.socket.send(data);
            }
            option.callback && option.callback(1);
        } else {
            option.callback && option.callback(3);
        }
    }
    pingpong() {
        this.pingpongTimer = setInterval(() => {
            if (!window.navigator.onLine) {
                this.option.offLineCallback && this.option.offLineCallback();
            }
            if (this.socket.readyState == 1) {
                this.socket.sub("ping");
            } else {
                clearInterval(this.pingpongTimer);
            }
        }, 6 * 1000);
    }
    init() {
        this.socket = new WebSocket(this.option.url);
        this.socket.onmessage = this.onmessage.bind(this);
        this.socket.onopen = this.onopen.bind(this);
        this.socket.onerror = this.onerror.bind(this);
        this.socket.onclose = this.onclose.bind(this);
        this.socket.sub = this.sub.bind(this);
    }
    onmessage(res) {
        if (res.data == "pong") {
            return;
        }
        const data = JSON.parse(res.data);
        this.option.callback && this.option.callback(data);
    }
    onopen() {
        this.socket.sub(this.option.subData)
        this.pingpong();
    }
    onerror() {
        console.info("onerror");
    }
    onclose() {
        console.info("onclose");
        setTimeout(() => {
            this.option.reConnect && this.option.reConnect();
        }, 5 * 1000);
    }
}