import React from "react";
import "./kline.scss";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { useRef } from "react";
import Lottie from 'lottie-react-web'
import loadingJson from "@/assets/img/common/loading_white.json"
import Socket from "@/assets/js/socket.js";
import querystring from "@/utils/querystring";
import { apiHistoryData, apiSymbolInfo } from "@/model/coin-detail/"

const subMap = {};
function resolveSymbol(pair) {
  if (pair) {
    pair = pair.toLowerCase();
  }
  return pair.replace(/\//, "_");
}

function resolveKlineData2(body) {
  const { data, params } = body;
  const { c, h, l, o, t, v } = data;
  const {p: symbol, r: resolution} = params;
  
  if (t && t.length) {
    if (t.length > 10) {
      return;
    }
    const subMapItem = subMap[`${symbol}_${resolution}`];

    if (!subMapItem) {
      console.log("接收到上个订阅的数据直接返回", symbol, resolution);
      return;
    }
    const { lastBar, listener } = subMapItem;
    
    
    for (let i = 0; i < c.length; i++) {
      let moreTime = historyProvider.timeToSec[resolution];
      if (resolution == 'M') {
        moreTime = moreTime * 28;
      }
      
      const item = {};
      item.close = Number(c[i]);
      item.high = Number(h[i]);
      item.low = Number(l[i]);
      item.open = Number(o[i]);
      item.volume = Number(v[i]);
      item.time = Number(t[i] * 1000) + moreTime;
      const langBarTime = lastBar.time;
      if (langBarTime && item.time >= langBarTime) {
        subMap[`${symbol}_${resolution}`].lastBar = Object.assign({}, item);
        listener(item);
      }
    }
  }
};

const historyProvider = {
  getDaysInMonth(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = date.getMonth();
    const temp = new Date(year, month, 0);
    return temp.getDate();
  },
  history: {},
  resolveResolutions: {
    "1": "1min",
    "5": "5min",
    "30": "30min",
    "60": "60min",
    "240": "4hour",
    D: "1day",
    W: "1week",
    M: "1mon"
  },
  timeToSec: {
    "1": 0,
    "5": 0,
    "15": 0,
    "30": 0,
    "60": 0,
    "240": 0,
    "D": 0,
    "W": 7 * 24 * 60 * 60 * 1000,
    "M": 24 * 60 * 60 * 1000
  },
  resolveResolutionFun(chartResolution) {
    return this.resolveResolutions[chartResolution];
  },
  // getBars执行的次数
  loadTime: 0,
  getBars(
    symbolInfo,
    chartResolution,
    from,
    to,
    onHistoryCallback,
    firstDataRequest
  ) {
    this.loadTime++;
    const symbol = resolveSymbol(symbolInfo.name);
    const req = {
      symbol,
      resolution: chartResolution,
      from,
      to
    };
    const me = this;
    function resolveHistoryData(rs) {
      const { c, h, l, o, t, v } = rs;
        
        const newBars = [];
        const len = c.length
        if (c && len > 0) {
          
          
          for (let i = 0; i < len; i++) {
            let moreTime = me.timeToSec[chartResolution];
            if (chartResolution == 'M') {
              moreTime = moreTime * 28;
            }

            const entry = {};
            entry.close = c[i];
            entry.high = h[i];
            entry.low = l[i];
            entry.open = o[i];
            entry.volume = v[i];
            entry.time = t[i] * 1000 + moreTime;
            newBars.push(entry);
          }
          if (firstDataRequest) {
            const lastBar = Object.assign({}, newBars[newBars.length - 1]);
            me.history[symbolInfo.name] = lastBar;
          }
          onHistoryCallback(newBars, { noData: false });
        } else {
          onHistoryCallback([], { noData: true });
        }
    }
    if (this.loadTime == 1) {
      resolveHistoryData(window.PageData.firstHistoryData);
    } else {
      apiHistoryData(req).then(rs => {
        resolveHistoryData(rs);
      });
    }

  }
};


const stream = {
  loadTime: 0,
  subScribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID) {
    this.loadTime++;
    if (this.loadTime != 1) {
      const req = {
        action:"Topic.sub",
        data:{
          type:"kline",
          symbol: symbolInfo.name,
          resolution: resolution
        }
      }
      window.ws.sub(req)
    }

    let lastBar = {};
    if (historyProvider.history[symbolInfo.name]) {
      lastBar = historyProvider.history[symbolInfo.name];
    }
    subMap[subscribeUID] = {
      lastBar,
      listener: onRealtimeCallback,
      resolution: resolution,
      name: symbolInfo.name
    };
  },
  unsubScribeBars(subscribeUID) {
    const req = {
      action:"Topic.unsub",
      data:{
        type:"kline",
        symbol: subMap[subscribeUID].name,
        resolution: subMap[subscribeUID].resolution
      }
    }
    window.ws.sub(req)
    delete subMap[subscribeUID];
  }
};

export default function () {
  const [visible, setVisible] = useState(false);
  let [widget, setWidget] = useState(false);
  const [interval, setInterval] = useState("D");
  const [chartType, setChartType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { symbol } = useParams();
  const [intervalText, setIntervalText] = useState(window.intl.get("更多"));
  const [catagory, setCatagory] = useState(0);
  

  const moreMap = {
    "0": window.intl.get("更多"),
    "1": window.intl.get("更多"),
    "5": window.intl.get("5分钟"),
    "15": window.intl.get("更多"),
    "60": window.intl.get("更多"),
    "240": window.intl.get("更多"),
    "D": window.intl.get("更多"),
    "30": window.intl.get("30分钟"),
    "W": window.intl.get("1周"),
    "M": window.intl.get("1月"),
  }

  function initWs() {
    const { REACT_APP_WS_API } = process.env;
    const option = {
      // url: "wss://ws.cgzgd4ch.com/?m=9fLgPlLqWxZRtRX1+wWlURD4xN9AeP6rQVjK1BYzLKc=&k=5DU2l0xCh9KdmETK2JcK",
      url: `${REACT_APP_WS_API}/?m=9fLgPlLqWxZRtRX1+wWlURD4xN9AeP6rQVjK1BYzLKc=&k=5DU2l0xCh9KdmETK2JcK`,
      // url: "ws://18.176.94.92:9009/?m=9fLgPlLqWxZRtRX1+wWlURD4xN9AeP6rQVjK1BYzLKc=&k=5DU2l0xCh9KdmETK2JcK",
      subData: {
        action:"Topic.sub",
        data:{
          type:"depth,orderbook,kline",
          symbol,
          resolution: 'D'
        }
      },
      callback
    }
    window.ws = new Socket(option).getInstance();
    function callback (data) {
      const {
        action
      } = data;
      if (action == "Pushdata.kline") {
        resolveKlineData2(data);
      }
    }
  }

  const initHistoryData = () => {
    const req = {
      symbol,
      resolution: interval,
      from: 0,
      to: 0,
    }
    return apiHistoryData(req).then(rs => {
      window.PageData.firstHistoryData = rs;
    })
  }
  const initSymbolInfo = () => {
    const req = {
      symbol,
    }
    return apiSymbolInfo(req).then(rs => {
      window.PageData.pricescale = rs.pricescale;
      window.PageData.coinFrom = rs.coin_from;
      window.PageData.coinTo = rs.coin_to;
    })
  }
  
  useEffect(() => {
    initWs();
    Promise.all([
      initHistoryData(),
      initSymbolInfo(),
    ]).then(() => {
      initChart();
    })
  }, [])
  const initChart = () => {
    const langMap = {
      zh_CN: "zh",
      zh_HK: "zh_TW",
      en: "en",
      kr: "ko",
      jp: "ja",
      ru: "ru"
    };
    const locale = langMap[window.intl.options.currentLocale] || 'en';
    const { REACT_APP_COIN_API } = process.env
    const dataFeedUrl = `${REACT_APP_COIN_API}/SecretOtc/TradeCoin/getKline`;
    const backgroundColor = "#fff";
    const lineColor = "#fff";
    const textColor = "#9fa2aa";
    const gapLineColor = "#eee";
    const w = new window.TradingView.widget({
      debug: false,
      symbol,
      interval,
      theme: "light",
      toolbar_bg: 'transparent',
      container_id: "tv_chart_container",
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed(`${REACT_APP_COIN_API}/Web/Kline`, 2000),
      datafeed: {
        onReady: cb => {
          const config = {
            supported_resolutions: ["1", "5", "15", "30", "60", "240", "D", "W", "M"]
          };
          setTimeout(() => cb(config), 0);
        },
        resolveSymbol: (symbolName, onSymbolResolveCallback) => {
          const symbol_stub = {
            // 交易对名称
            name: symbolName,
            ticker: symbolName,
            discription: "",
            exchange: "Topcreditbank",
            has_intraday: true,
            minmov: 1,
            minmove2: 0,
            pricescale: window.PageData.pricescale,
            session: "24x7",
            timezone: "Asia/Shanghai",
            has_weekly_and_monthly: true,
            // 无数据显示空的k柱
            has_empty_bars: true
          };
          setTimeout(() => {
            onSymbolResolveCallback(symbol_stub);
          }, 0);
        },
        getBars(
          symbolInfo,
          resulution,
          from,
          to,
          onHistoryCallback,
          firstDataRequest
        ) {
          historyProvider.getBars(
            symbolInfo,
            resulution,
            from,
            to,
            onHistoryCallback,
            firstDataRequest
          );
        },
        subscribeBars(
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscribeUID,
          onResetCacheNeededCallback
        ) {
          stream.subScribeBars(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscribeUID,
            onResetCacheNeededCallback
          );
        },
        unsubscribeBars(subscribeUID) {
          stream.unsubScribeBars(subscribeUID);
        },
      },
      // library_path: `https://help.topcreditbank.com/3rd/tradingview/charting_library/`,
      library_path: `${process.env.PUBLIC_URL}/3rd/tradingview/charting_library/`,
      // library_path: `/3rd/tradingview/charting_library/`,
      locale,
      autosize: true,
      timezone: "Asia/Shanghai",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_screenshot",
        "header_symbol_search",
        "header_undo_redo",
        "header_compare",
        "timeframes_toolbar",
        "header_chart_type",
        // 头部分辨率
        "header_resolutions",
        "header_widget",
        // kline与成交量是否重合
        'volume_force_overlay',
        "left_toolbar",
        // 移动端禁用属性
        "property_pages",
        "show_chart_property_page",
        // 禁用右键菜单
        "context_menus",
      ],
      overrides: {
        "volumePaneSize": "small",
        "paneProperties.topMargin": 15,
        "paneProperties.bottomMargin": 0,
        // 背景颜色
        "paneProperties.background": backgroundColor,
        // // 水平分割线
        "paneProperties.vertGridProperties.color": gapLineColor,
        "paneProperties.vertGridProperties.style": 0,
        // // 竖直分割线
        "paneProperties.horzGridProperties.color": gapLineColor,
        "paneProperties.horzGridProperties.style": 0,
        // // 蜡烛线涨颜色
        "mainSeriesProperties.candleStyle.wickUpColor": "#24B391",
        "mainSeriesProperties.candleStyle.upColor": "#24B391",
        "mainSeriesProperties.candleStyle.borderUpColor": "#24B391",
        // // 蜡烛线跌颜色
        "mainSeriesProperties.candleStyle.wickDownColor": "#DA3D68",
        "mainSeriesProperties.candleStyle.downColor": "#DA3D68",
        "mainSeriesProperties.candleStyle.borderDownColor": "#DA3D68",
        // 横纵坐标的字体大小
        "scalesProperties.fontSize": 12,
        // 横纵坐标线条的颜色
        "scalesProperties.lineColor": lineColor,
        // kline上面字体显示的颜色
        "scalesProperties.textColor": textColor,
        // 默认隐藏左上角标题，可手动打开
        "paneProperties.legendProperties.showLegend": false,
        // 展示开盘价，最高价，最低价，收盘价
        "paneProperties.legendProperties.showSeriesOHLC": true
      },
      studies_overrides: {
        "volume.volume.color.0": "#DA3D68",
        "volume.volume.color.1": "#24B391",
        "volume.show ma": true,
        "volume.volume ma.plottype": "line",
        "volume.ma length": 5,
      },
      customFormatters: {
        timeFormatter: {
          format(date) {
            const _format_str = "%h:%m";
            return _format_str
              .replace(
                "%h",
                date.getUTCHours() < 10
                  ? `0${date.getUTCHours()}`
                  : date.getUTCHours(),
                2
              )
              .replace(
                "%m",
                date.getUTCMinutes() < 10
                  ? `0${date.getUTCMinutes()}`
                  : date.getUTCMinutes(),
                2
              );
          }
        },
        dateFormatter: {
          format(date) {
            return `${date.getUTCFullYear()}-${
              date.getUTCMonth() + 1 < 10
                ? `0${date.getUTCMonth() + 1}`
                : date.getUTCMonth() + 1
            }-${
              date.getUTCDate() < 10
                ? `0${date.getUTCDate()}`
                : date.getUTCDate()
            }`;
          }
        }
      },
      custom_css_url: "trading_view3.css",
    });
    w.onChartReady(() => {
      // 添加ma指标
      w.chart()
        .createStudy("Moving Average", false, false, [5], null, {
          "Plot.color": "#642d92"
        });
      w.chart()
        .createStudy("Moving Average", false, false, [10], null, {
          "Plot.color": "#5278a3"
        });
      w.chart()
        .createStudy("Moving Average", false, false, [30], null, {
          "Plot.color": "#238031"
        });
      w.chart()
        .createStudy("Moving Average", false, false, [60], null, {
          "Plot.color": "#850058"
        });
      setIsLoading(false);
    });
    setWidget(w);
  }
  
  const onResolution = useCallback((resolution) => {
    setCatagory(0);
    // 分辨率相同，kline类型相同不切换
    if (interval == resolution && chartType == 1) {
      return;
    }
    // 分辨率相同切为1，kline类型不同
    if (interval == 1 && resolution == 1) {
      widget.chart().setChartType(1);
      setChartType(1)
    } else {
      widget.chart().setResolution(resolution, () => {
        widget.chart().setChartType(1);
        setChartType(1)
      });
    }
    setVisible(false);
    setInterval(resolution);
    setIntervalText(moreMap[resolution]);
  })
  const onIndex = useCallback(() => {
    setVisible(false)
    widget.chart().executeActionById("insertIndicator");
  })
  const onMore =useCallback(() => {
    setVisible(!visible);
  })
  const onTime =useCallback(() => {
    setIntervalText(moreMap['0']);
    setVisible(false);
    setCatagory(0);
    if (interval == 1) {
      widget.chart().setChartType(3);
      setChartType(3)
      setInterval("1");
    } else {
      widget.chart().setResolution("1", () => {
        widget.chart().setChartType(3);
        setChartType(3)
        setInterval("1");
      })
    }
  })
  const onFull = useCallback(() => {
    const ele = window.$("#tv_chart_container iframe")[0];
    setFullScreen(ele);
  })
  const setFullScreen = useCallback((Ele) => {
    if (document.documentElement.requestFullscreen) {
      Ele.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      Ele.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      Ele.webkitRequestFullscreen();
    } else {
      Ele.msRequestFullscreen();
    }
  })
  return (
    <div className="page-kline">
      <div className="kline-edit-wrap">
        <ul className="kline-show-bar">
          <li className={catagory==0&&interval=='1'&&chartType==3?'active':''} onClick={onTime}>{window.intl.get('分时')}</li>
          <li className={catagory==0&&interval=='1'&&chartType==1?'active': ''} onClick={() => {onResolution("1")}}>{window.intl.get("1分钟")}</li>
          <li className={catagory==0&&interval=='15'?'active': ''} onClick={() => {onResolution("15")}}>{window.intl.get("15分钟")}</li>
          <li className={catagory==0&&interval=='60'?'active': ''} onClick={() => {onResolution("60")}}>{window.intl.get("1小时")}</li>
          <li className={catagory==0&&interval=='240'?'active': ''} onClick={() => {onResolution("240")}}>{window.intl.get("4小时")}</li>
          <li className={catagory==0&&interval=='D'?'active': ''} onClick={() => {onResolution("D")}}>{window.intl.get("1天")}</li>
          <li className={`kline-more ${visible?'open': ''} ${catagory==0&&(interval=='5'||interval=='30'||interval=='W'||interval=='M')?'active':''}`} onClick={onMore}>{intervalText}</li>
          {/* <li className={`show-depth ${visible?'open': ''} ${catagory==1?'active':''}`} onClick={() => {onShowDepth()}}>{window.intl.get("深度")}</li> */}
          {/* <li onClick={onIndex}>指标</li> */}
        </ul>
        {/* <div className="full-screen" onClick={onFull}></div> */}
        {
          visible ?
          <ul className="sub-resolution">
            {/* <li className={`kline-resolution ${interval=='1'&&chartType==3?'active': ''}`} onClick={onTime}>分时</li>
            <li className={`kline-resolution ${interval=='1'&&chartType==1?'active': ''}`} onClick={() => {onResolution("1")}}>1分钟</li> */}
            <li className={`kline-resolution ${catagory==0&&interval=='5'?'active': ''}`} onClick={() => {onResolution("5")}}>{window.intl.get("5分钟")}</li>
            <li className={`kline-resolution ${catagory==0&&interval=='30'?'active': ''}`} onClick={() => {onResolution("30")}}>{window.intl.get("30分钟")}</li>
            <li className={`kline-resolution ${catagory==0&&interval=='W'?'active': ''}`} onClick={() => {onResolution("W")}}>{window.intl.get("1周")}</li>
            <li className={`kline-resolution ${catagory==0&&interval=='M'?'active': ''}`} onClick={() => {onResolution("M")}}>{window.intl.get("1月")}</li>
          </ul>:""
        }
      </div>
      <div className="klinw-wrap">
        <div className="kline-mask"></div>
        <div id="tv_chart_container"></div>    
        <div className="kline-y-mask"></div>
        <div
          id="kline-loading"
          className={isLoading ? "is-loading" : ""}
        >
          <div className="kline-circular">
            <Lottie
              options={{
                animationData: loadingJson,
              }}
            ></Lottie>
          </div>
        </div>
      </div>
    </div>
  )
}